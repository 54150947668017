import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, Button, ButtonSize } from "components/buttons/button/button";
import { CollectionUtils } from "../../utilities/collection-utils";
import { EmptyText } from "../empty-text/empty-text";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventRecord } from "models/view-models/events/event-record";
import { Icon } from "components/icons/icon";
import { IconSizes } from "../icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { Language } from "models/enumerations/languages/language";
import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "moment/locale/ar";
import "moment/locale/es";
import "./calendar-month-view.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalendarEvent {
    dates: moment.Moment[];
    details?: string;
    notifications?: NotificationRecord[];
    id: number;
    name: string;
    status: PublishStatus;
    type: InstructorLedTrainingType;
}

export interface CalendarMonthViewProps {
    cssClassName?: string;
    events: EventRecord[];
    notifications?: NotificationRecord[];
    getEventDisplayName?: (event: EventRecord) => string;
    onEditEventClick?: (eventId: number) => void;
    onNextMonthClick: () => void;
    onPreviousMonthClick: () => void;
    onViewEventClick?: (eventId: number) => void;
    viewEventButtonText?: string;
    tabIndex?: number;
}

interface CalendarDay {
    Events: CalendarEvent[];
    InPersonTrainings: CalendarEvent[];
    LiveVirtualTrainings: CalendarEvent[];
    HasCancelledEvents: boolean;
    HasNewNotifications: boolean;
    HasChangedNotifications: boolean;
}

interface CalendarEventTypeMapItem {
    abbreviation: string;
    icon: Icons;
    name: TranslatedCopy;
    styleClassName: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum CalendarEventStatusStyle {
    Canceled = "-status-error",
    Changed = "-status-warning",
    InPerson = "-style-dark",
    LiveVirtual = "-style-light",
    New = "-status-success",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "calendar-month-view";
const FOUR_WEEK_CLASS_NAME: string = "-four-week";
const FIVE_WEEK_CLASS_NAME: string = "-five-week";
const SIX_WEEK_CLASS_NAME: string = "-six-week";
const SELECTED_EVENT_CLASS_NAME = "calendar-month-view__selected__event";
const EventTypeMap = new Map<InstructorLedTrainingType, CalendarEventTypeMapItem>([
    [
        InstructorLedTrainingType.InPerson,
        {
            abbreviation: "IPT",
            icon: Icons.Calendar,
            name: "inPersonTraining",
            styleClassName: CalendarEventStatusStyle.InPerson,
        },
    ],
    [
        InstructorLedTrainingType.LiveVirtual,
        {
            abbreviation: "LVT",
            icon: Icons.LiveVirtualEvent,
            name: "liveVirtualTraining",
            styleClassName: CalendarEventStatusStyle.LiveVirtual,
        },
    ],
]);

const NotificationTypeMap = new Map<NotificationType, CalendarEventStatusStyle>([
    [NotificationType.EventInstructorAssignment, CalendarEventStatusStyle.New],
    [NotificationType.IltContractCreation, CalendarEventStatusStyle.New],
    [NotificationType.IltEnrollmentCreation, CalendarEventStatusStyle.New],
    [NotificationType.EventChanged, CalendarEventStatusStyle.Changed],
    [NotificationType.EventCancelled, CalendarEventStatusStyle.Canceled],
]);

// -------------------------------------------------------------------------------------------------
// #endregion Functions
// -------------------------------------------------------------------------------------------------

const GetCalendarEventTypeMapItemFromEvent = (
    calendarEvent: CalendarEvent
): CalendarEventTypeMapItem => {
    const calendarEventTypeMapItem = EventTypeMap.get(calendarEvent.type);

    if (calendarEventTypeMapItem == null) {
        throw new Error("Unable to find calendar event type map item");
    }

    return {
        ...calendarEventTypeMapItem!,
        styleClassName: GetEventStyleClassName(calendarEvent),
    };
};

const GetEventStyleClassName = (calendarEvent: CalendarEvent): string => {
    if (calendarEvent.status === PublishStatus.Canceled) {
        return CalendarEventStatusStyle.Canceled;
    }

    if (
        calendarEvent.notifications?.some(
            (n) =>
                n.eventId === calendarEvent.id &&
                NotificationTypeMap.get(n.notificationType) === CalendarEventStatusStyle.New
        )
    ) {
        return CalendarEventStatusStyle.New;
    }

    if (
        calendarEvent.notifications?.some(
            (n) =>
                n.eventId === calendarEvent.id &&
                NotificationTypeMap.get(n.notificationType) === CalendarEventStatusStyle.Changed
        )
    ) {
        return CalendarEventStatusStyle.Changed;
    }

    return GetInstructorLedTrainingTypeColor(calendarEvent.type);
};

const GetInstructorLedTrainingTypeColor = (type?: InstructorLedTrainingType): string => {
    switch (type) {
        case InstructorLedTrainingType.InPerson:
            return CalendarEventStatusStyle.InPerson;
        case InstructorLedTrainingType.LiveVirtual:
            return CalendarEventStatusStyle.LiveVirtual;
        default:
            return CalendarEventStatusStyle.LiveVirtual;
    }
};

// endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GetCalendarDay = (date: moment.Moment, events: CalendarEvent[]): CalendarDay => {
    const calendarDayEvents = events.filter((e) => e.dates.some((d) => d.isSame(date, "day")));
    const groupedEvents = CollectionUtils.groupBy(calendarDayEvents, "type");

    // Type Groupings
    const inPersonTrainings = groupedEvents.get(InstructorLedTrainingType.InPerson);
    const liveVirtualTrainings = groupedEvents.get(InstructorLedTrainingType.LiveVirtual);

    return {
        Events: calendarDayEvents,
        InPersonTrainings: inPersonTrainings ?? [],
        LiveVirtualTrainings: liveVirtualTrainings ?? [],
        HasCancelledEvents: calendarDayEvents.some((e) => e.status === PublishStatus.Canceled),
        HasNewNotifications: calendarDayEvents.some((e) =>
            e.notifications?.some(
                (n) =>
                    n.eventId === e.id &&
                    NotificationTypeMap.get(n.notificationType) === CalendarEventStatusStyle.New
            )
        ),
        HasChangedNotifications: calendarDayEvents.some((e) =>
            e.notifications?.some(
                (n) =>
                    n.eventId === e.id &&
                    NotificationTypeMap.get(n.notificationType) === CalendarEventStatusStyle.Changed
            )
        ),
    };
};

const RenderCalendarWeeks = (
    month: moment.Moment,
    selectedDate: moment.Moment,
    events: CalendarEvent[],
    selectDate: (newDate: moment.Moment) => void
) => {
    const { record: globalState } = useGlobalState();
    const preferredLanguage = globalState.currentIdentity?.user?.preferredLanguage;
    const daysToSubtract = preferredLanguage === Language.English ? 1 : 2;
    let calendar = [];

    const startDate = month.clone().startOf("month").startOf("week");
    const endDate = month.clone().endOf("month");

    const day = startDate.clone().subtract(daysToSubtract, "day");

    while (day.isBefore(endDate, "day")) {
        calendar.push(
            Array(7)
                .fill(0)
                .map(() => day.add(1, "day").clone())
        );
    }

    const weekClassName = useMemo(() => {
        switch (calendar.length) {
            case 4:
                return FOUR_WEEK_CLASS_NAME;
            case 5:
                return FIVE_WEEK_CLASS_NAME;
            case 6:
                return SIX_WEEK_CLASS_NAME;
            default:
                return FIVE_WEEK_CLASS_NAME;
        }
    }, [calendar.length]);

    if (calendar.length > 0) {
        return calendar.map((week, weekIndex) => {
            return (
                <div
                    key={`week-${weekIndex}`}
                    className={`${CSS_CLASS_NAME}__calendar__weeks__week ${weekClassName}`}>
                    {week.map((dayInWeek, dayIndex) => {
                        const isInMonth = month.isSame(dayInWeek, "month");
                        const isSelectedDate = dayInWeek.isSame(selectedDate, "day");
                        const selectedClass = isSelectedDate ? " -selected" : "";
                        const calendarDay = GetCalendarDay(dayInWeek, events);

                        const inPersonMapItem = EventTypeMap.get(
                            InstructorLedTrainingType.InPerson
                        );
                        const liveVirtualMapItem = EventTypeMap.get(
                            InstructorLedTrainingType.LiveVirtual
                        );

                        return (
                            <button
                                key={`week-${weekIndex}-day-${dayIndex}`}
                                type="button"
                                className={`${CSS_CLASS_NAME}__calendar__weeks__week__day${selectedClass}`}
                                onClick={() => selectDate(dayInWeek)}>
                                <div
                                    className={`${CSS_CLASS_NAME}__calendar__weeks__week__day__header`}>
                                    <Paragraph
                                        style={
                                            isInMonth
                                                ? ParagraphStyle.Default
                                                : ParagraphStyle.Faded
                                        }>
                                        {dayInWeek.format("DD")}
                                    </Paragraph>
                                    <div
                                        className={`${CSS_CLASS_NAME}__calendar__weeks__week__day__header__notifications`}>
                                        {calendarDay.HasCancelledEvents && (
                                            <span className={"-cancelled"}></span>
                                        )}
                                        {calendarDay.HasNewNotifications && (
                                            <span className={"-new"}></span>
                                        )}
                                        {calendarDay.HasChangedNotifications && (
                                            <span className={"-changed"}></span>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`${CSS_CLASS_NAME}__calendar__weeks__week__day__events`}>
                                    {calendarDay.InPersonTrainings.length > 0 && (
                                        <div
                                            className={`${CSS_CLASS_NAME}__calendar__event ${inPersonMapItem?.styleClassName}`}>
                                            <Icon
                                                type={inPersonMapItem?.icon ?? Icons.Calendar}
                                                size={IconSizes.Base}
                                            />
                                            <div>
                                                <span>{calendarDay.InPersonTrainings.length}</span>
                                                <span>{inPersonMapItem?.abbreviation}</span>
                                            </div>
                                        </div>
                                    )}
                                    {calendarDay.LiveVirtualTrainings.length > 0 && (
                                        <div
                                            className={`${CSS_CLASS_NAME}__calendar__event ${liveVirtualMapItem?.styleClassName}`}>
                                            <Icon
                                                type={liveVirtualMapItem?.icon ?? Icons.Calendar}
                                                size={IconSizes.Base}
                                            />
                                            <div>
                                                <span>
                                                    {calendarDay.LiveVirtualTrainings.length}
                                                </span>
                                                <span>{liveVirtualMapItem?.abbreviation}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </button>
                        );
                    })}
                </div>
            );
        });
    }
};

const RenderEvent = (
    event: CalendarEvent,
    viewEventButtonText: string,
    onEditEventClick?: (eventId: number) => void,
    onViewEventClick?: (eventId: number) => void
) => {
    const eventTypeMapItem = GetCalendarEventTypeMapItemFromEvent(event);
    if (eventTypeMapItem == null) {
        return;
    }

    const cssClassNames = [SELECTED_EVENT_CLASS_NAME, eventTypeMapItem.styleClassName];

    return (
        <div key={`calendar-event-${event.id}`} className={cssClassNames.join(" ")}>
            <div className={`${SELECTED_EVENT_CLASS_NAME}__header`}>
                <Icon type={eventTypeMapItem.icon} size={IconSizes.Medium} />
                <Paragraph style={ParagraphStyle.Label}>{t(eventTypeMapItem.name)}</Paragraph>
            </div>
            <Paragraph cssClassName={`${SELECTED_EVENT_CLASS_NAME}__body`}>{event.name}</Paragraph>
            <div className={`${SELECTED_EVENT_CLASS_NAME}__actions`}>
                {onViewEventClick != null && (
                    <Button
                        onClick={() => onViewEventClick(event.id)}
                        size={ButtonSize.Small}
                        style={ButtonStyle.Primary}
                        text={viewEventButtonText}
                    />
                )}
                {onEditEventClick != null && (
                    <Button
                        onClick={() => onEditEventClick(event.id)}
                        size={ButtonSize.Small}
                        style={ButtonStyle.Primary}
                        text={t("editEvent")}
                    />
                )}
            </div>
        </div>
    );
};

const EventRecordToCalendarEvent = (
    event: EventRecord,
    notifications?: NotificationRecord[],
    getEventDisplayName?: (event: EventRecord) => string
): CalendarEvent | null => {
    if (event?.id == null || event.instructorLedTrainingType == null) {
        return null;
    }

    const eventDays: EventDayRecord[] = event.eventDays ?? [];
    const eventDates: moment.Moment[] = eventDays.map(
        (eventDay: EventDayRecord): moment.Moment => moment(eventDay.eventDate())
    );

    return {
        dates: eventDates,
        details: event.additionalDetails,
        id: event.id,
        name: getEventDisplayName?.(event) ?? event.name,
        notifications: notifications?.filter((n) => n.eventId === event.id),
        status: event.status,
        type: event.instructorLedTrainingType,
    };
};

const CalendarMonthView: React.FC<CalendarMonthViewProps> = (
    props: CalendarMonthViewProps
): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];
    const className: string = classNames.join(" ");

    const [currentMonth, setCurrentMonth] = useState<moment.Moment>(moment());
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
    const [selectedCalendarDay, setSelectedCalendarDay] = useState<CalendarDay>();
    const events = useMemo(
        (): CalendarEvent[] =>
            props.events
                .map((e) =>
                    EventRecordToCalendarEvent(e, props.notifications, props.getEventDisplayName)
                )
                .filter((event: CalendarEvent | null): event is CalendarEvent => event != null),
        [props.getEventDisplayName, props.events, props.notifications]
    );

    // We should run this any time events or notifications change to ensure
    // the calendar and selected date panel is displaying the most up to date information.
    useEffect(() => {
        selectDate(selectedDate);
    }, [events]);

    const handleNextMonthClick = () => {
        setCurrentMonth(currentMonth.add(1, "M").clone());

        props.onNextMonthClick();
    };

    const handlePreviousMonthClick = () => {
        setCurrentMonth(currentMonth.subtract(1, "M").clone());

        props.onPreviousMonthClick();
    };

    const selectDate = (newDate: moment.Moment) => {
        setSelectedDate(newDate);
        setSelectedCalendarDay(GetCalendarDay(newDate, events));
    };

    const weekClassName = useMemo(() => {
        let weeks = [];

        const startDate = currentMonth.clone().startOf("month").startOf("week");
        const endDate = currentMonth.clone().endOf("month");

        const day = startDate.clone().subtract(1, "day");

        while (day.isBefore(endDate, "day")) {
            weeks.push(
                Array(7)
                    .fill(0)
                    .map(() => day.add(1, "day").clone())
            );
        }

        switch (weeks.length) {
            case 4:
                return FOUR_WEEK_CLASS_NAME;
            case 5:
                return FIVE_WEEK_CLASS_NAME;
            case 6:
                return SIX_WEEK_CLASS_NAME;
            default:
                return FIVE_WEEK_CLASS_NAME;
        }
    }, [currentMonth]);

    return (
        <div className={`${className} ${weekClassName}`} tabIndex={props.tabIndex}>
            <div className={`${CSS_CLASS_NAME}__calendar ${weekClassName}`}>
                <div className={`${CSS_CLASS_NAME}__calendar__header ${weekClassName}`}>
                    <ButtonIcon
                        ariaLabelledBy={t("previousMonth")}
                        buttonStyle={ButtonStyle.TertiaryAlt}
                        iconSize={IconSizes.Medium}
                        iconType={Icons.ArrowCircleLeft}
                        onClick={handlePreviousMonthClick}
                    />
                    <Paragraph>{currentMonth.format("MMMM YYYY")}</Paragraph>
                    <ButtonIcon
                        ariaLabelledBy={t("nextMonth")}
                        buttonStyle={ButtonStyle.TertiaryAlt}
                        iconSize={IconSizes.Medium}
                        iconType={Icons.ArrowCircleRight}
                        onClick={handleNextMonthClick}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__calendar__header__days ${weekClassName}`}>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("sundayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("mondayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("tuesdayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("wednesdayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("thursdayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("fridayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("saturdayAbbreviated").toLocaleUpperCase()}
                        </Paragraph>
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__calendar__weeks ${weekClassName}`}>
                    {RenderCalendarWeeks(currentMonth, selectedDate, events, selectDate)}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__selected`}>
                <div className={`${CSS_CLASS_NAME}__selected__content`}>
                    <div className={`${CSS_CLASS_NAME}__selected__header`}>
                        <Paragraph>{selectedDate.format("dddd, MMMM D, YYYY")}</Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__selected__body`}>
                        {selectedCalendarDay != null && selectedCalendarDay.Events.length > 0 && (
                            <div className={`${CSS_CLASS_NAME}__selected__body__events`}>
                                {selectedCalendarDay.Events.map((calendarDay, eventIndex) => {
                                    return RenderEvent(
                                        calendarDay,
                                        props.viewEventButtonText ?? t("viewEvent"),
                                        props.onEditEventClick,
                                        props.onViewEventClick
                                    );
                                })}
                            </div>
                        )}

                        {(selectedCalendarDay == null ||
                            selectedCalendarDay.Events.length === 0) && (
                            <EmptyText>
                                <span>
                                    {t(
                                        "selectADayThatContainsAnInstructorLedTrainingToViewMoreInformation"
                                    )}
                                </span>
                            </EmptyText>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CalendarMonthView };

// #endregion Exports
