import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { BadgeStyle } from "components/badges/badge/badge";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ProviderApplicationStatus {
    Submitted = 0,
    Declined = 1,
    MoreInfoNeeded = 2,
    UnderReview = 3,
    CreditCheckInProgress = 4,
    ContractInProgress = 5,
    Completed = 6,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ProviderApplicationStatusDisplayNames: Map<ProviderApplicationStatus, TranslatedCopy> = {
    [ProviderApplicationStatus.Submitted]: "submitted",
    [ProviderApplicationStatus.Declined]: "declined",
    [ProviderApplicationStatus.MoreInfoNeeded]: "moreInfoNeeded",
    [ProviderApplicationStatus.UnderReview]: "underReview",
    [ProviderApplicationStatus.CreditCheckInProgress]: "creditCheckInProgress",
    [ProviderApplicationStatus.ContractInProgress]: "contractInProgress",
    [ProviderApplicationStatus.Completed]: "completed",
};

const ProviderApplicationStatusBadgeStyleMap: Map<ProviderApplicationStatus, BadgeStyle> = {
    [ProviderApplicationStatus.Submitted]: BadgeStyle.Warning,
    [ProviderApplicationStatus.Declined]: BadgeStyle.Error,
    [ProviderApplicationStatus.MoreInfoNeeded]: BadgeStyle.Warning,
    [ProviderApplicationStatus.UnderReview]: BadgeStyle.Neutral,
    [ProviderApplicationStatus.CreditCheckInProgress]: BadgeStyle.Neutral,
    [ProviderApplicationStatus.ContractInProgress]: BadgeStyle.Neutral,
    [ProviderApplicationStatus.Completed]: BadgeStyle.Success,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    ProviderApplicationStatus,
    ProviderApplicationStatusDisplayNames,
    ProviderApplicationStatusBadgeStyleMap,
};

// #endregion Exports
