import React, { useEffect, useMemo, useState } from "react";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardGridColumns,
    RadioCardStyle,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import "./report-by-the-numbers.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsByTheNumbersProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-by-the-numbers";

const REPORT_TYPES: RadioCard[] = [
    {
        icon: Icons.Reporting,
        text: "totalTrainingsConducted",
        value: "1",
    },
    {
        icon: Icons.Reporting,
        text: "totalEnrollmentsTotalLearnersParticipated",
        value: "2",
    },
    {
        icon: Icons.Reporting,
        text: "totalNumberOfLearnersAwardedCeus",
        value: "3",
    },
    {
        icon: Icons.Reporting,
        text: "totalNumberOfCeusAwarded",
        value: "4",
    },
];

const ORGANIZED_BY: RadioCard[] = [
    {
        icon: Icons.Reporting,
        text: "trainingType",
        value: "1",
    },
    {
        icon: Icons.Reporting,
        text: "salesType",
        value: "2",
    },
    {
        icon: Icons.Reporting,
        text: "topic",
        value: "3",
    },
];

const DISPLAY_OPTIONS: RadioCard[] = [
    {
        icon: Icons.Reporting,
        text: "summary",
        value: "1",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportByTheNumbers: React.FC<ReportsByTheNumbersProps> = ({
    onChange: onChangeReportCriteria,
}): JSX.Element => {
    const [reportType, setReportType] = useState<string>();
    const [organizedBy, setOrganizedBy] = useState<string>();
    const [displayType, setDisplayType] = useState<string>("1");
    const [dateRangeStart, setDateRangeStart] = useState<Date | null>(null);
    const [dateRangeEnd, setDateRangeEnd] = useState<Date | null>(null);

    const isValid = useMemo(() => {
        if (
            reportType == null ||
            organizedBy == null ||
            dateRangeStart == null ||
            dateRangeEnd == null ||
            displayType == null
        ) {
            return false;
        }
        return true;
    }, [dateRangeEnd, dateRangeStart, displayType, organizedBy, reportType]);

    const onReportTypeChange = (value: string) => {
        setReportType(value);
    };

    const onDateRangeChange = (date?: Array<Date | null>) => {
        if (!date) {
            return;
        }

        setDateRangeStart(date[0]);
        setDateRangeEnd(date[1]);
    };

    useEffect(() => {
        onChangeReportCriteria({
            reportType,
            organizedBy,
            displayType,
            dateRangeStart,
            dateRangeEnd,
            isValid: isValid,
        });
    }, [
        dateRangeEnd,
        dateRangeStart,
        displayType,
        isValid,
        onChangeReportCriteria,
        organizedBy,
        reportType,
    ]);

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <h2>{t("trainingStatistics")}</h2>
                            <div className="report-section">
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("iWantToRunAByTheNumbersReportFor")}
                                </Paragraph>
                                <FormRadioCardList
                                    id="reportType"
                                    formFieldName="reportType"
                                    label={t("reportType")}
                                    labelScreenReaderOnly={true}
                                    iconSize={IconSizes.Base}
                                    onChange={onReportTypeChange}
                                    radioCards={REPORT_TYPES}
                                    required={true}
                                    style={RadioCardStyle.Horizontal}
                                    value={reportType}
                                />
                            </div>

                            {reportType != null && (
                                <>
                                    <div className="report-section">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("organizedBy")}
                                        </Paragraph>
                                        <FormRadioCardList
                                            columns={RadioCardGridColumns.Three}
                                            id="organizedBy"
                                            formFieldName="organizedBy"
                                            label={t("organizedBy")}
                                            labelScreenReaderOnly={true}
                                            iconSize={IconSizes.Base}
                                            onChange={(value: string) => setOrganizedBy(value)}
                                            radioCards={ORGANIZED_BY}
                                            required={true}
                                            style={RadioCardStyle.Horizontal}
                                            value={organizedBy}
                                        />
                                    </div>
                                    <div className="report-section half">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("duringThisTimePeriod")}
                                        </Paragraph>
                                        <FormCalendarDateRangePicker
                                            endDate={dateRangeEnd}
                                            formFieldName="dateRange"
                                            handleEventDateRangeChange={onDateRangeChange}
                                            label={t("dateRange")}
                                            startDate={dateRangeStart}
                                        />
                                    </div>
                                    <div className="report-section">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("thatIsDisplayedAsA")}
                                        </Paragraph>
                                        <FormRadioCardList
                                            id="displayType"
                                            formFieldName="displayType"
                                            label={t("displayType")}
                                            labelScreenReaderOnly={true}
                                            iconSize={IconSizes.Base}
                                            onChange={(value: string) => setDisplayType(value)}
                                            radioCards={DISPLAY_OPTIONS}
                                            required={true}
                                            style={RadioCardStyle.Horizontal}
                                            value={displayType}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportByTheNumbers };

// #endregion Exports
