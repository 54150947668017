import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { Permission } from "utilities/enumerations/authorization/permission";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const AenProviderAdminAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,

    [AccessControlKeys.AENProductCatalogPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventChangeLogPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventContactPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventInstructorPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventLocationPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventProductPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventRegistrationPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventSchedulePage]: Permission.Allow,
    [AccessControlKeys.AdminEventListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsDownloadsPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsSetupPage]: Permission.Allow,
    [AccessControlKeys.ENInstructorDirectoryPage]: Permission.Allow,
    [AccessControlKeys.ENProductCatalogPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAssessmentPage]: Permission.Allow,
    [AccessControlKeys.ManageEventAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceDayPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceSummaryPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventContentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEnrollmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEvaluationsPage]: Permission.Allow,
    [AccessControlKeys.ProviderDashboardPage]: Permission.Allow,
    [AccessControlKeys.ProviderENHOrganizationPage]: Permission.Allow,
    [AccessControlKeys.ProviderENHResourcesPage]: Permission.Allow,

    // Actions
    [AccessControlKeys.CanDownloadCertificates]: Permission.Allow,
    [AccessControlKeys.CanDownloadEnrollmentReport]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenProviderAdminAuthorizationRules };

// #endregion Exports
