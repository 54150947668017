import ActionLinkCardList from "components/action-card/action-card-list/action-link-card-list";
import React, { useMemo } from "react";
import { ActionLinkCardProps } from "components/action-card/action-link-card";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { InstructorApplicationStatusBar } from "components/instructors/instructor-application-status-section/instructor-application-status-bar/instructor-application-status-bar";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useInstructorApplicationEnrollments } from "utilities/hooks/models/enrollments/use-instructor-application-enrollments";
import { useUserInstructorApplication } from "utilities/hooks/models/instructors/use-user-instructor-application";
import "./instructor-pending-dashboard.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorPendingDashboardProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-pending-dashboard";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorPendingDashboard: React.FC<InstructorPendingDashboardProps> = () => {
    const { record: globalState } = useGlobalState();

    const userId = useMemo(
        () => globalState?.currentIdentity?.user?.id,
        [globalState?.currentIdentity?.user?.id]
    );

    const { userInstructorApplication, isLoading } = useUserInstructorApplication({
        onError: (error: Error) => {
            ToastManager.error(t(error.message as TranslatedCopy));
        },
    });

    const { instructorApplicationEnrollments } = useInstructorApplicationEnrollments({
        instructorApplicationId: userInstructorApplication?.id ?? 0,
    });

    const requiredTrainings: ActionLinkCardProps[] = useMemo(
        () =>
            instructorApplicationEnrollments.map((instructorApplicationEnrollment) => {
                return {
                    description: instructorApplicationEnrollment.enrollment?.product?.name ?? "",
                    icon: Icons.Products,
                    topics: instructorApplicationEnrollment.enrollment?.product?.topic
                        ? [instructorApplicationEnrollment.enrollment?.product?.topic]
                        : null,
                    trainingButtonLocked: false,
                    trainingButtonPath: RouteUtils.localizePath(
                        RouteUtils.replacePathParams(
                            sitemap.instructor.aenInstructorAssessment.details,
                            {
                                productId: instructorApplicationEnrollment.enrollment?.productId,
                                enrollmentId: instructorApplicationEnrollment.enrollment?.id,
                            }
                        )
                    ),
                    trainingButtonText:
                        instructorApplicationEnrollment.enrollment?.registrations?.some(
                            (r) => r.userId === userId
                        )
                            ? "resumeTraining"
                            : "viewTraining",
                    trainingType: TrainingType.InstructorAssessment,
                    type:
                        instructorApplicationEnrollment.enrollment?.product?.getTrainingTypeWithSpaces() ??
                        "",
                    status: instructorApplicationEnrollment.status,
                } as ActionLinkCardProps;
            }),
        [instructorApplicationEnrollments, userId]
    );

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                {t("myApplicationStatus")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <InstructorApplicationStatusBar
                    isLoading={isLoading}
                    userInstructorApplication={userInstructorApplication}
                />
            </div>

            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                {t("requiredAssessment")}
            </Heading>

            <div className={`${CSS_CLASS_NAME}__section`}>
                <ActionLinkCardList links={requiredTrainings} />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorPendingDashboard };

// #endregion Exports
