import { Record as ImmutableRecord } from "immutable";
import { RecordUtils, StringUtils } from "andculturecode-javascript-core";
import { SystemSettings } from "models/interfaces/settings/system-settings";
import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";

const defaultValues: SystemSettings = RecordUtils.defaultValuesFactory<SystemSettings>({
    communityBaseUrl: undefined,
    ecommerceBaseUrl: undefined,
    featureFlags: undefined,
    linkBaseUrl: undefined,
    maintenanceMode: undefined,
});

export class SystemSettingsRecord extends ImmutableRecord(defaultValues) implements SystemSettings {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SystemSettings) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor
    // #region Public Methods

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SystemSettings>} values
     */
    public with(values: Partial<SystemSettings>): SystemSettingsRecord {
        return new SystemSettingsRecord(Object.assign(this.toJS(), values));
    }
    public getNfpaUrlV2(path: NfpaUrlPaths) {
        const baseUrl = this.ecommerceBaseUrl;

        if (StringUtils.isEmpty(baseUrl) || StringUtils.isEmpty(path)) return "";

        return `${baseUrl}/${path}`;
    }
    // #endregion Public Methods
}
