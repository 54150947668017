import React from "react";
import { Badge } from "components/badges/badge/badge";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { useUser } from "utilities/contexts/use-user-context";
import { DateUtils } from "utilities/date-utils";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { RouteUtils } from "utilities/route-utils";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { SyncUserDataBanner } from "molecules/banners/sync-user-data-banner";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { StringUtils } from "utilities/string-utils";
import "./user-basic-info-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserBasicInfoPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-basic-info-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserBasicInfoPage: React.FC<UserBasicInfoPageProps> = validatePageAccess(
    AccessControlKeys.UserBasicInfoPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { record: user } = useUser();
    const displayPreferredLanguage = () => {
        if (user.invitePending()) {
            return <Badge text={t("invitePending")} />;
        } else {
            if (user.preferredLanguage == null) {
                return "--";
            } else {
                return t(LanguageDisplayNames[user.preferredLanguage!]);
            }
        }
    };

    const handleUserDataSync = () => {
        window.location.href = RouteUtils.localizePath(
            RouteUtils.getUrl(sitemap.admin.userManagement.users.info.default, {
                id: user.id,
            })
        );
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("basicInformation")}
                        </Heading>
                        <div className={`${CSS_CLASS_NAME}__information`}>
                            <div className="email">
                                <FormFieldReadonly label={t("email")}>
                                    {user?.email ?? "--"}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("dateCreated")}>
                                    {user?.createdOn == null
                                        ? "--"
                                        : `${DateUtils.formatDateCustom(
                                              user?.createdOn?.toString(),
                                              t("shortDateFormat")
                                          )}`}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("preferredLanguage")}>
                                    {displayPreferredLanguage()}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("lmsID")}>
                                    {user?.id ?? "--"}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("nfpaId")}>
                                    {user.invitePending() ? (
                                        <Badge text={t("invitePending")} />
                                    ) : (
                                        StringUtils.valueOrDefault(user.externalIdentifier)
                                    )}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("nfpaWebId")}>
                                    {StringUtils.valueOrDefault(user.nfpaWebId?.toString())}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("associatedOrganization")}>
                                    {user.organization?.name ?? "--"}
                                </FormFieldReadonly>
                                {user.organizationId != null && (
                                    <Button
                                        linkPath={RouteUtils.replacePathParams(
                                            sitemap.admin.userManagement.contracts.info,
                                            {
                                                id: user.organizationId,
                                            }
                                        )}
                                        size={ButtonSize.Small}
                                        style={ButtonStyle.Primary}
                                        text={t("viewOrganization")}
                                        type={ButtonType.Link}
                                    />
                                )}
                            </div>
                            <div>
                                <FormFieldReadonly label={t("associatedEnProvider")}>
                                    {user.provider?.name ?? "--"}
                                </FormFieldReadonly>
                                {user.providerId != null && (
                                    <Button
                                        linkPath={RouteUtils.replacePathParams(
                                            sitemap.admin.userManagement.enProviders.info,
                                            {
                                                id: user.providerId,
                                            }
                                        )}
                                        size={ButtonSize.Small}
                                        style={ButtonStyle.Primary}
                                        text={t("viewProvider")}
                                        type={ButtonType.Link}
                                    />
                                )}
                            </div>
                            <div>
                                <FormFieldReadonly
                                    label={t("optionalAenProviderAssociationForInstructor")}>
                                    {user.instructorProfile?.provider?.name ?? "--"}
                                </FormFieldReadonly>
                                {user.instructorProfile?.providerId != null && (
                                    <Button
                                        linkPath={RouteUtils.replacePathParams(
                                            sitemap.admin.userManagement.enProviders.info,
                                            {
                                                id: user.instructorProfile?.providerId,
                                            }
                                        )}
                                        size={ButtonSize.Small}
                                        style={ButtonStyle.Primary}
                                        text={t("viewProvider")}
                                        type={ButtonType.Link}
                                    />
                                )}
                            </div>
                        </div>
                        {user.nfpaWebId ? (
                            <SyncUserDataBanner
                                userId={user.id}
                                lastSync={user.updatedOn}
                                onUserDataSync={handleUserDataSync}
                                bannerText={t("notSeeingChangesReflectedAbove")}
                            />
                        ) : null}
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserBasicInfoPage };

// #endregion Exports
