import React, { useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import {
    PatchProductVersionServiceData,
    PatchProductVersionServiceParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { PatchRoutes } from "utilities/services/products/product-version-service";
import { ProductActivationAchievementsSection } from "components/products/product-activation-modal/product-activation-achievements-section/product-activation-achievements-section";
import { ProductActivationContentSection } from "components/products/product-activation-modal/product-activation-content-section/product-activation-content-section";
import { ProductActivationCoursesSection } from "components/products/product-activation-modal/product-activation-courses-section/product-activation-courses-section";
import { ProductActivationEvaluationSection } from "components/products/product-activation-modal/product-activation-evaluation-section/product-activation-evaluation-section";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { SelectOption } from "components/form/inputs/select/select";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./activate-new-version-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ActivateNewVersionModalProps {
    open: boolean;
    product: ProductRecord;
    productVersion: ProductVersionRecord;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setProductVersion: React.Dispatch<React.SetStateAction<ProductVersionRecord | undefined>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "activate-new-version-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ActivateNewVersionModal: React.FC<ActivateNewVersionModalProps> = ({
    open,
    product,
    productVersion,
    setOpen,
    setProductVersion,
    setRefresh,
}): JSX.Element => {
    const [applyToInProgressLearners, setApplyToInProgressLearners] = useState<
        boolean | undefined
    >();
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");
    const { activate: productVersionPatch } = ProductVersionService.useActivate(); //useActivate???

    const handleApplyToLearnersChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        if (event.target.value === "true") {
            setApplyToInProgressLearners(true);
        } else {
            setApplyToInProgressLearners(false);
        }
    };

    const assignmentSelectOptions: SelectOption[] = [
        { text: "Apply", value: "true" },
        { text: "Do Not Apply", value: "false" },
    ];

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => {
                setOpen(false);
                setApplyToInProgressLearners(undefined);
                setChangeLogDescription("");
            },
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("activateVersion"),
            disabled:
                product.type === TrainingType.InstructorLedTraining ||
                product.type === TrainingType.InstructorAssessment
                    ? StringUtils.isEmpty(changeLogDescription)
                    : StringUtils.isEmpty(changeLogDescription) ||
                      applyToInProgressLearners == null,
            onClick: () => handleActivatingProductVersion(),
            style: ButtonStyle.Primary,
        },
    ];

    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    const handleActivatingProductVersion = () => {
        setOpen(false);
        activateProductVersion(productVersion.id!);
    };

    const activateProductVersion = async (id: number): Promise<boolean> => {
        if (!id) {
            return false;
        }

        if (product.type === TrainingType.InstructorLedTraining) {
            setApplyToInProgressLearners(false);
        }

        try {
            const patchCourseVersionServiceParams: PatchProductVersionServiceParams = {
                id: id,
                patchRoute: PatchRoutes.Activate,
            };

            const activateProductVersion: PatchProductVersionServiceData = {
                applyToInProgressLearners: applyToInProgressLearners!,
                changeLogDescription: changeLogDescription,
            };

            const patchCourseVersionResponse = await productVersionPatch(
                patchCourseVersionServiceParams,
                activateProductVersion
            );

            const patchCourseResult = patchCourseVersionResponse?.result;

            if (patchCourseResult?.resultObject == null || patchCourseResult.hasErrors()) {
                ToastManager.error(t("activatingProductVersionFailed"));
            }

            ToastManager.success(t("productVersionIsNowActive"));

            setProductVersion(productVersion.with({ status: ActiveStatus.Active }));
            setRefresh(true);
        } catch {
            ToastManager.error(t("failedToActivateProductVersion"));
            return false;
        }
        return true;
    };

    const onModalClose = (): void => {
        setOpen(false);
        setApplyToInProgressLearners(undefined);
        setChangeLogDescription("");
    };

    return (
        <>
            <Modal
                cssClassName={CSS_CLASS_NAME}
                isOpen={open}
                onModalClose={onModalClose}
                title={t("activateNewVersion")}
                actions={modalActionArray}
                modalStyle={""}>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <Heading
                        cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                        priority={HeadingPriority.H5}>
                        {t("youAreActivatingProductVersionVersionId", {
                            productVersionVersionId: productVersion.versionId,
                        })}
                    </Heading>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__modal-heading-description`}
                        size={ParagraphSize.XLarge}
                        style={ParagraphStyle.Light}>
                        {t(
                            "takeAMomentToReviewYourNewVersionAndPopulateTheChangeLogBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney"
                        )}
                    </Paragraph>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__change-log`}
                        size={ParagraphSize.XLarge}>
                        {t("changeLog")}
                    </Paragraph>

                    {product.type === TrainingType.InstructorAssessment && (
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "allCurrentlyEnrolledInstructorsWillRemainOnTheCurrentVersionToMigrateThemToTheNewVersionManuallyReassignThisTraining"
                            )}
                        />
                    )}

                    {(product.type === TrainingType.OnlineLearning ||
                        product.type === TrainingType.TrainTheTrainer) && (
                        <>
                            <NotificationBanner
                                notificationId={0}
                                onClose={() => {}}
                                style={NotificationType.Default}
                                message={t(
                                    "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersion"
                                )}
                            />
                            <div className={`${CSS_CLASS_NAME}__assignment`}>
                                {t(
                                    "howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressForTheCoursesThatHaveBeenEdited"
                                )}
                                <FormSelect
                                    ariaLabelledBy={t("selectAssignment")}
                                    cssClassName={`${CSS_CLASS_NAME}__select-assignment`}
                                    formFieldName={t("selectAssignment")}
                                    id="selectAssignment"
                                    label=""
                                    onChange={handleApplyToLearnersChange}
                                    placeholder={t("selectAssignment")}
                                    options={assignmentSelectOptions}
                                    value={applyToInProgressLearners?.toString()}
                                />
                            </div>
                        </>
                    )}
                    {product.type === TrainingType.InstructorLedTraining && (
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatusNewlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion"
                            )}
                        />
                    )}
                    <FormTextArea
                        ariaLabelledBy={t("describeYourChanges")}
                        cssClassName={`${CSS_CLASS_NAME}__text-area`}
                        formFieldName={t("describeYourChanges")}
                        id={t("describeYourChanges")}
                        label={t("describeYourChanges")}
                        maxLength={1000}
                        onChange={handleChangeLogChange}
                        placeholder={t("describeYourChanges")}
                        required={true}
                        rows={6}
                        value={changeLogDescription}
                    />
                    <ProductActivationCoursesSection
                        product={product}
                        productVersion={productVersion}
                    />

                    <ProductActivationContentSection
                        product={product}
                        productContents={productVersion.productContents}
                        productVersion={productVersion}
                    />
                    {(product.type === TrainingType.OnlineLearning ||
                        product.type === TrainingType.InstructorLedTraining) && (
                        <>
                            <ProductActivationAchievementsSection
                                product={product}
                                productVersion={productVersion}
                            />
                            <ProductActivationEvaluationSection product={product} />
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ActivateNewVersionModal };

// #endregion Exports
