// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

enum InstructorApplicationProfileStatus {
    ApplicationSubmitted = 0,
    UnderReview = 1,
    PendingAssessment = 2,
    FinalReview = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const InstructorApplicationStatusBarTitle: Map<InstructorApplicationProfileStatus, TranslatedCopy> =
    {
        [InstructorApplicationProfileStatus.ApplicationSubmitted]: "applicationSubmitted",
        [InstructorApplicationProfileStatus.UnderReview]: "underReview",
        [InstructorApplicationProfileStatus.PendingAssessment]: "pendingAssessment",
        [InstructorApplicationProfileStatus.FinalReview]: "finalReviewInProgress",
    };

const InstructorApplicationStatusBarDescription: Map<
    InstructorApplicationProfileStatus,
    TranslatedCopy
> = {
    [InstructorApplicationProfileStatus.ApplicationSubmitted]: "applicationSubmittedDescription",
    [InstructorApplicationProfileStatus.UnderReview]: "underReviewDescription",
    [InstructorApplicationProfileStatus.PendingAssessment]: "pendingAssessmentDescription",
    [InstructorApplicationProfileStatus.FinalReview]: "finalReviewInProgressDescription",
};
// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    InstructorApplicationProfileStatus,
    InstructorApplicationStatusBarTitle,
    InstructorApplicationStatusBarDescription,
};

// #endregion Exports
