import AenApplicationDetailPageFormField from "./aen-application-detail-page-form-field/aen-application-detail-page-form-field";
import AenApplicationDetailPageSection from "./aen-application-detail-page-section/aen-application-detail-page-section";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AenApplicationAddLogModal } from "components/aen-application/aen-application-logs/aen-application-add-file-modal/aen-application-add-activity-log-modal";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import { useAenApplicationActivityLog } from "utilities/hooks/models/aen-applications/use-aen-application-logs";
import { useInstructorApplication } from "utilities/contexts/use-instructor-application-context";
import { useState } from "react";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

const CSS_CLASS_NAME = "aen-application-instructor-detail-page";

const AenInstructorApplicationDetailPage = validatePageAccess(
    AccessControlKeys.AdminAenInstructorApplications
)((): JSX.Element => {
    const { record: instructorApplication } = useInstructorApplication();
    const { addActivityLog } = useAenApplicationActivityLog({
        application: instructorApplication,
        type: AenApplicationType.Instructor,
    });

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <HeaderActions
                    title={t("applicationDetails")}
                    headingPriority={HeadingPriority.H2}
                    headingSize={HeadingSize.XSmall}>
                    <ButtonIcon
                        onClick={() => {
                            setShowModal(true);
                        }}
                        buttonStyle={ButtonStyle.Primary}
                        text={t("addNotes")}
                        iconType={Icons.Plus}
                    />
                </HeaderActions>
            </div>

            <AenApplicationDetailPageSection headingText={t("basicInformation")}>
                <AenApplicationDetailPageFormField
                    label={t("name")}
                    value={`${instructorApplication?.firstName} ${instructorApplication?.lastName}`}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("email")}
                    value={instructorApplication?.email}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("country")}
                    value={instructorApplication?.country}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("address")}
                    value={
                        <div>
                            {instructorApplication?.streetAddress}
                            <br />
                            {instructorApplication?.city}, {instructorApplication?.stateProvince}{" "}
                            {instructorApplication?.zipcode}
                        </div>
                    }
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("phone")}
                    value={instructorApplication?.phone}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("linkedInProfile")}
                    value={instructorApplication?.linkedInProfile}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("currentNfpaMember")}
                    value={instructorApplication?.isCurrentNfpaMember}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("nfpaTechnicalCommitteeMember")}
                    value={instructorApplication?.isTechnicalCommitteeMember}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesWhichOnes")}
                    value={instructorApplication?.whichTechnicalCommittees}
                />
                <AenApplicationDetailPageFormField
                    label={t("areYouCurrentlyAnApprovedNfpaInstructor")}
                    value={instructorApplication?.isCurrentApprovedNfpaInstructor}
                />
                <AenApplicationDetailPageFormField
                    label={t("haveYouPreviouslyBeenAnApprovedNfpaInstructor")}
                    value={instructorApplication?.isPreviouslyApprovedNfpaInstructor}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesWhichCourses")}
                    value={instructorApplication?.whichCoursesPreviouslyApprovedAsInstructor}
                />
                <AenApplicationDetailPageFormField
                    label={t("whyDoYouWantToBecomeAnNfpaInstructor")}
                    value={instructorApplication?.whyWantToBecomeNfpaInstructor}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText="Teaching Modalities">
                <AenApplicationDetailPageFormField
                    label={t("areYouInterestedInDeliveringInPersonClassroomTraining")}
                    value={instructorApplication?.interestedInInPerson}
                />
                <AenApplicationDetailPageFormField
                    label={t("areYouInterestedInDeliveringVirtualTraining")}
                    value={instructorApplication?.interestedInVirtual}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText="Teaching Experience">
                <AenApplicationDetailPageFormField
                    label={t("doYouHaveExperienceDeliveringTrainingBasedOnNfpaCodesAndStandards")}
                    value={instructorApplication?.hasExperienceTrainingBasedOnNfpaCodes}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesPleaseBrieflyDescribeYourExperienceEGTopicsAudienceClassSize")}
                    value={instructorApplication?.describeExperienceTrainingBasedOnNfpaCodes}
                />
                <AenApplicationDetailPageFormField
                    label={t("doYouHaveExperienceDeliveringVirtualTraining")}
                    value={instructorApplication?.hasExperienceVirtualTraining}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesPleaseBrieflyDescribeYourExperienceAndSkillComfortLevelDoingSo")}
                    value={instructorApplication?.describeExperienceVirtualTraining}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("travelPreferences")}>
                <AenApplicationDetailPageFormField
                    label={t("areYouWillingAbleToTravelToDeliverInPersonTraining")}
                    value={instructorApplication?.isWillingToTravel}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesToWhere")}
                    value={instructorApplication?.whereWillingToTravel}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("interestedTopicAreas")}>
                <AenApplicationDetailPageFormField
                    label={t("whichTopicAreasAreYouInterestedInTeaching")}
                    value={instructorApplication?.topicAreasInterestedInTeaching}
                    otherValue={instructorApplication?.topicAreasInterestedInTeachingOther}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("qualifications")}>
                <AenApplicationDetailPageFormField
                    label={t("educationDegrees")}
                    value={instructorApplication?.educationQualifications}
                />
                <AenApplicationDetailPageFormField
                    label={t("certificationsLicenses")}
                    value={instructorApplication?.certificationQualifications}
                />
                <AenApplicationDetailPageFormField
                    label={t("listRelevantPositionsPositionRoleDates")}
                    value={instructorApplication?.workExperienceQualifications}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("languages")}>
                <AenApplicationDetailPageFormField
                    label={t("english")}
                    value={instructorApplication?.englishProficiency}
                />
                <AenApplicationDetailPageFormField
                    label={t("spanish")}
                    value={instructorApplication?.spanishProficiency}
                />
                <AenApplicationDetailPageFormField
                    label={t("portuguese")}
                    value={instructorApplication?.portugueseProficiency}
                />
                <AenApplicationDetailPageFormField
                    label={t("arabic")}
                    value={instructorApplication?.arabicProficiency}
                />
                <AenApplicationDetailPageFormField
                    label={t("otherLanguage")}
                    value={instructorApplication?.otherProficiency}
                    otherValue={instructorApplication?.otherLanguage}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("references")}>
                <AenApplicationDetailPageFormField
                    label={t(
                        "pleaseProvideContactInformationForAtLeastTwoProfessionalReferencesWithKnowledgeOfYourSubjectMatterExpertiseAndOrTeachingAbility"
                    )}
                    value={instructorApplication?.references}
                />
                <AenApplicationDetailPageFormField
                    label={t("video")}
                    value={
                        instructorApplication?.uploadVideo ? (
                            <Anchor path={instructorApplication?.uploadVideo}>
                                {instructorApplication?.uploadVideo}
                            </Anchor>
                        ) : (
                            ""
                        )
                    }
                />
            </AenApplicationDetailPageSection>
            <AenApplicationAddLogModal
                handleSave={addActivityLog}
                open={showModal}
                setOpen={setShowModal}
            />
        </>
    );
});

export { AenInstructorApplicationDetailPage };
