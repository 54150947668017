import { AddGroupUserModalUserList } from "./add-group-user-modal-user-list/add-group-user-modal-user-list";
import { NumberedTab } from "components/tabs/tabs/numbered-tab/numbered-tab";
import { SelectedGroupUserModalUserList } from "./selected-group-user-modal-user-list/selected-group-user-modal-user-list";
import { Tab } from "components/tabs/tabs/tab/tab";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { ToastManager } from "utilities/toast/toast-manager";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { UserIndexQueryParams, UserService } from "utilities/services/users/user-service";
import { UserRecord } from "models/view-models/users/user-record";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageAddGroupUsersProps {
    groupId: number;
    handleAddUserToQueue: (user: UserRecord) => void;
    setUsersAvailable: React.Dispatch<React.SetStateAction<UserRecord[]>>;
    handleRemoveUserFromQueue: (user: UserRecord) => void;
    users: UserRecord[];
    usersToAdd: UserRecord[];
}
export interface AddGroupUserModalUser {
    user: UserRecord;
    addedToQueue: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "search";
const DEFAULT_USER_TAKE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

// Convert a given user list to an AddGroupUserModalGroupUser using the queuedGroupUser array to determine
// whether each user in the users array has already been added to the queue of users to add.
const convertUserToAddGroupUserModalUser = (
    users: UserRecord[],
    queuedUsers: UserRecord[]
): AddGroupUserModalUser[] => {
    return users.map(
        (u): AddGroupUserModalUser => ({
            user: u,
            addedToQueue: queuedUsers?.some((queuedUser) => queuedUser.id === u.id),
        })
    );
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageAddGroupUsers: React.FC<ManageAddGroupUsersProps> = ({
    groupId,
    users,
    usersToAdd,
    handleAddUserToQueue,
    setUsersAvailable,
    handleRemoveUserFromQueue,
}) => {
    const { list: listUsers } = UserService.useList();
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [allowPaging, setAllowPaging] = useState(false);

    const filterUsers = useCallback(
        async (searchText: string) => {
            setLoading(true);
            try {
                const listUsersQueryParams: UserIndexQueryParams = {
                    includeRoles: false,
                    excludeUsersInGroup: true,
                    groupId: groupId,
                    searchText: searchText,
                    skip: skip * DEFAULT_USER_TAKE,
                    take: DEFAULT_USER_TAKE,
                };

                const listFilteredUsersResponse = await listUsers(listUsersQueryParams);
                const listFilteredUsersResults = listFilteredUsersResponse?.results;
                const users = listFilteredUsersResponse?.resultObjects;

                if (
                    users == null ||
                    listFilteredUsersResults == null ||
                    listFilteredUsersResults.hasErrors()
                ) {
                    throw new Error();
                }

                if (skip === 0) {
                    setUsersAvailable([]);
                }

                setUsersAvailable((prev) => [...prev, ...users]);
                if (users.length > 0) {
                    setAllowPaging(true);
                }
            } catch (e) {
                ToastManager.error(t("thereWasAnIssueLoadingUsers"));
                setUsersAvailable([]);
            }
            setLoading(false);
        },
        [groupId, setUsersAvailable, listUsers, skip]
    );

    useEffect(() => {
        filterUsers("");
    }, [filterUsers, skip]);

    const onIncrementSkip = useCallback(() => {
        setSkip((prev: number) => prev + 1);
    }, []);

    const onResetSkip = useCallback(() => {
        setSkip(0);
    }, []);

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <AddGroupUserModalUserList
                    addGroupUserModalUsers={convertUserToAddGroupUserModalUser(users, usersToAdd)}
                    debounceTime={DEBOUNCE_TIME}
                    filterUsers={filterUsers}
                    handleAdd={handleAddUserToQueue}
                    handleRemove={handleRemoveUserFromQueue}
                    loading={loading}
                    searchTextPlaceholder={t(SEARCH_INPUT_PLACEHOLDER)}
                    onResetSkip={onResetSkip}
                    onIncrementSkip={onIncrementSkip}
                    allowPaging={allowPaging}
                />
            ),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("users"),
            },
        },
        {
            contents: (
                <SelectedGroupUserModalUserList
                    selectedGroupUserModalUsers={convertUserToAddGroupUserModalUser(
                        usersToAdd,
                        usersToAdd
                    )}
                    handleAdd={handleAddUserToQueue}
                    handleRemove={handleRemoveUserFromQueue}
                />
            ),
            tabComponent: NumberedTab,
            tabDisplayDetails: {
                hideBadge: usersToAdd.length <= 0,
                tabName: t("selected"),
                value: usersToAdd.length,
            },
        },
    ];

    return <TabbedContainer tabs={tabs} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageAddGroupUsers };

// #endregion Exports
