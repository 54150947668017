import { Button } from "components/buttons/button/button";
import { Card } from "components/card/card";
import catalog from "assets/images/Catalog.png";
import "./event-physical-content-display.scss";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PhysicalContentDisplayProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-physical-content-display";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PhysicalContentDisplay: React.FC<PhysicalContentDisplayProps> = (
    props: PhysicalContentDisplayProps
): JSX.Element => {
    const catalogLink = "https://catalog.nfpa.org/Codes-and-Standards-C3322.aspx";

    const handleCatalogButton = () => {
        window.open(catalogLink);
    };
    return (
        <div className={`${CSS_CLASS_NAME}__content-section`}>
            <Card cssClassName={`${CSS_CLASS_NAME}__material-card`}>
                <h3 className={`${CSS_CLASS_NAME}__title`}>{t("physicalMaterials")}</h3>
                <p className={`${CSS_CLASS_NAME}__message`}>{/*COPY WILL GO HERE*/}</p>
                <Button
                    cssClassName={`${CSS_CLASS_NAME}__catalog-button`}
                    text={t("viewCatalog")}
                    onClick={handleCatalogButton}
                />
                <div className={`${CSS_CLASS_NAME}__image-wrapper`}>
                    <img
                        src={catalog}
                        className={`${CSS_CLASS_NAME}__image`}
                        alt={t("nationalFireProtectionAssociationCatalog")}
                    />
                </div>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PhysicalContentDisplay };

// #endregion Exports
