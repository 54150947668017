import React, { useCallback, useEffect, useState } from "react";
import { AddBulkEnrollmentModal } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-modal";
import { AddEnrollmentModal } from "components/enrollments/add-enrollment-modal/add-enrollment-modal";
import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { CreateBulkEnrollmentDtoRecord } from "models/view-models/enrollments/create-bulk-enrollment-params-record";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { EnrollmentHeader } from "components/enrollments/enrollment-header/enrollment-header";
import { EnrollmentSummaryList } from "components/enrollments/enrollment-list/enrollment-summary-list";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentService,
    WithdrawEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { ProductEnrollmentSummaryRecord } from "models/view-models/enrollments/product-enrollment-summary-record";
import { ResultRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import {
    ListProductEnrollmentSummariesPathParams,
    ListProductEnrollmentSummariesQueryParams,
    ProductEnrollmentSummariesService,
} from "utilities/services/enrollments/product-enrollment-summaries-service";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnrollmentManagerProps {
    productId: number;
    eventId: number;
    eventProductVersionId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnrollmentManager: React.FC<EnrollmentManagerProps> = (props: EnrollmentManagerProps) => {
    const [enrollmentSummaries, setEnrollmentSummaries] = useState<
        ProductEnrollmentSummaryRecord[]
    >([]);
    const [showBulkEnrollmentModal, setShowBulkEnrollmentModal] = useState(false);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
    const { create: enrollmentCreate } = EnrollmentService.useCreate();
    const { create: bulkEnrollmentCreate } = EnrollmentService.useBulkCreate();
    const { list: listProductEnrollmentSummaryApi } = ProductEnrollmentSummariesService.useList();
    const { patch: withdrawEnrollmentApi } = EnrollmentService.useWithdrawEnrollment();

    const saveEnrollment = async (
        createEnrollmentDto?: CreateEnrollmentDtoRecord
    ): Promise<boolean> => {
        try {
            const productId = props.productId;
            const eventId = props.eventId;

            if (productId === undefined || createEnrollmentDto === undefined) {
                throw new Error(t("creatingAnEnrollmentFailed"));
            }

            createEnrollmentDto = createEnrollmentDto.with({ productId: productId });

            if (eventId !== undefined && eventId > 0) {
                createEnrollmentDto = createEnrollmentDto.with({
                    eventId: eventId,
                    eventProductVersionId: props.eventProductVersionId,
                });
            }

            const createEnrollmentResponse = await enrollmentCreate(createEnrollmentDto);
            const createEnrollmentResult = createEnrollmentResponse?.result;

            if (
                createEnrollmentResult?.resultObject == null ||
                createEnrollmentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemCreatingANewEnrollment"));
            }

            // Retrieve the data.
            fetchData();

            return true;
        } catch (error) {
            if (error === "") {
                ToastManager.error(t("failedToCreateANewEnrollment"));
            } else {
                ToastManager.error(`${error}`);
            }
            return false;
        }
    };

    const saveBulkEnrollment = async (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ): Promise<ResultRecord<BulkEnrollmentSummaryRecord>> => {
        let result = new ResultRecord<BulkEnrollmentSummaryRecord>();

        if (props.productId === undefined || createBulkEnrollmentDto === undefined) {
            result.addError("", t("productIDAndFileAreNeeded"));
            return result;
        }

        createBulkEnrollmentDto = createBulkEnrollmentDto.with({ productId: props.productId });

        if (props.eventId !== undefined && props.eventId > 0) {
            createBulkEnrollmentDto = createBulkEnrollmentDto.with({ eventId: props.eventId });
        }

        try {
            const createBulkEnrollmentResponse = await bulkEnrollmentCreate(
                createBulkEnrollmentDto
            );
            const createBulkEnrollmentResult = createBulkEnrollmentResponse?.result;

            if (createBulkEnrollmentResult?.resultObject == null) {
                throw new Error();
            }

            result = createBulkEnrollmentResult;
        } catch (error) {
            try {
                result = error as ResultRecord<BulkEnrollmentSummaryRecord>;
            } catch (error: any) {
                result.addError("", error.toString());
                ToastManager.error(t("thereWasProblemSavingBulkEnrollments"));
            }
        }

        // Retrieve the data.
        fetchData();

        return result;
    };

    const fetchData = useCallback(async () => {
        try {
            if (props.productId < 1) {
                return;
            }

            const listEventEnrollmentSummariesPathParams: ListProductEnrollmentSummariesPathParams =
                {
                    productId: props.productId!,
                };
            const listEventEnrollmentSummariesQueryParams: ListProductEnrollmentSummariesQueryParams =
                {};
            const listResponse = await listProductEnrollmentSummaryApi(
                listEventEnrollmentSummariesPathParams,
                listEventEnrollmentSummariesQueryParams
            );

            if (
                listResponse?.resultObjects == null ||
                listResponse.results == null ||
                listResponse.results.hasErrors()
            ) {
                setEnrollmentSummaries([]);
                throw new Error();
            }

            setEnrollmentSummaries(listResponse.resultObjects);
        } catch {
            ToastManager.error(t("thereWasAProblemLoadingEnrollments"));
            return;
        }
    }, [listProductEnrollmentSummaryApi, props.productId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const withdrawEnrollment = async (enrollment: EnrollmentRecord): Promise<boolean> => {
        try {
            const pathParams: WithdrawEnrollmentPathParams = {
                id: enrollment.id!,
            };

            const withdrawEnrollmentResponse = await withdrawEnrollmentApi(pathParams);
            const withdrawResult = withdrawEnrollmentResponse?.result;
            if (withdrawResult?.resultObject == null || withdrawResult.hasErrors()) {
                throw new Error();
            }

            fetchData();
            ToastManager.success(t("withdrawEnrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueWithdrawingAnEnrollment"));
            return false;
        }
    };

    return (
        <>
            <EnrollmentHeader
                handleAddEnrollment={() => setShowEnrollmentModal(true)}
                handleBulkAddEnrollment={() => setShowBulkEnrollmentModal(true)}
                title={""}
            />
            <AddEnrollmentModal
                handleSaveEnrollment={saveEnrollment}
                open={showEnrollmentModal}
                setOpen={setShowEnrollmentModal}
            />
            <AddBulkEnrollmentModal
                handleSaveBulkEnrollment={saveBulkEnrollment}
                open={showBulkEnrollmentModal}
                setOpen={setShowBulkEnrollmentModal}
            />
            <EnrollmentSummaryList
                enrollmentSummaries={enrollmentSummaries}
                handleWithdrawEnrollment={withdrawEnrollment}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentManager };

// #endregion Exports
