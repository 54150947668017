import React from "react";
import { Button, ButtonSize } from "components/buttons/button/button";
import { FileUtils } from "utilities/files/file-utils";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { Avatar, AvatarAspect } from "components/avatar/avatar";
import { CollectionUtils } from "utilities/collection-utils";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import { Badge } from "components/badges/badge/badge";
import "./instructor-info.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorInfoProps {
    instructor: InstructorRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "instructor-info";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorInfo: React.FC<InstructorInfoProps> = ({ instructor }): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            {/* Top Panel */}
            <div className={`${CSS_CLASS_NAME}__details-with-image`}>
                <div className={`${CSS_CLASS_NAME}__image-container`}>
                    <Avatar
                        avatarFileId={instructor.instructorProfile?.avatarFileId}
                        avatarAspect={AvatarAspect.Square}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__details`}>
                    {instructor.hasFirstName() ? (
                        <h2 className={`${CSS_CLASS_NAME}__header`}>
                            {instructor.getFirstAndLastName()}
                        </h2>
                    ) : (
                        <Badge text={t("invitePending")} />
                    )}

                    {instructor.instructorProfile?.shareLocation && (
                        <div className={`${CSS_CLASS_NAME}__location`}>
                            <div>{instructor.getCityStateText()}</div>
                            {instructor.getCountryText()}
                        </div>
                    )}

                    <div className={`${CSS_CLASS_NAME}__download`}>
                        <a
                            href={
                                instructor.instructorProfile?.resumeFileId == null
                                    ? ""
                                    : FileUtils.fileUrl(instructor.instructorProfile?.resumeFileId)
                            }
                            rel="noreferrer"
                            target="_blank">
                            <Button
                                disabled={instructor.instructorProfile?.resumeFileId == null}
                                text={t("downloadResume")}
                                size={ButtonSize.Small}
                            />
                        </a>
                    </div>
                </div>
            </div>
            {/* Bottom Panel */}
            <div className={`${CSS_CLASS_NAME}__about-me`}>
                <dl>
                    <dt>{t("about")}</dt>
                    <dd>
                        {StringUtils.isEmpty(instructor.instructorProfile?.aboutMe)
                            ? "--"
                            : instructor.instructorProfile?.aboutMe}
                    </dd>
                </dl>
            </div>
            <div className={`${CSS_CLASS_NAME}__bottom-section`}>
                <div className={`${CSS_CLASS_NAME}__language`}>
                    <dl>
                        <dt>{t("language")}</dt>
                        <dd>
                            {CollectionUtils.isEmpty(
                                instructor.instructorProfile?.instructorLanguages
                            ) ? (
                                "--"
                            ) : (
                                <>
                                    {instructor.instructorProfile?.instructorLanguages
                                        ?.map((language) => {
                                            return language.getLanguageDisplayName();
                                        })
                                        .join(", ")}
                                </>
                            )}
                        </dd>
                    </dl>
                </div>
                <div className={`${CSS_CLASS_NAME}__teaching-types`}>
                    <dl>
                        <dt>{t("teachingMethods")}</dt>
                        <dd>
                            {CollectionUtils.isEmpty(
                                instructor.instructorProfile?.instructorLanguages
                            ) ? (
                                "--"
                            ) : (
                                <>
                                    {instructor.instructorProfile?.instructorTrainingTypes
                                        ?.map((trainingTypes) => {
                                            return trainingTypes.getTrainingTypeDisplayName();
                                        })
                                        .join(", ")}
                                </>
                            )}
                        </dd>
                    </dl>
                </div>
                <div className={`${CSS_CLASS_NAME}__travel`}>
                    <dl>
                        <dt>{t("travelPreferences")}</dt>
                        <dd>
                            {CollectionUtils.isEmpty(
                                instructor.instructorProfile?.instructorLanguages
                            ) ? (
                                "--"
                            ) : (
                                <>
                                    {instructor.instructorProfile?.instructorTravelTypes
                                        ?.map((travelType) => {
                                            return travelType.getTravelTypeDisplayName();
                                        })
                                        .join(", ")}
                                </>
                            )}
                        </dd>
                    </dl>
                </div>
                <div className={`${CSS_CLASS_NAME}__contact`}>
                    <dl>
                        <dt>{t("contactInfo")}</dt>
                        <dd>
                            <div>{instructor.email ?? "--"}</div>
                            {instructor.formatPhoneNumber()}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorInfo };

// #endregion Exports
