import React, { useCallback, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { HeaderBanner } from "components/header-banner/header-banner";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { NumberUtils } from "utilities/number-utils";
import { AENProductInfo } from "components/products/aen-product-card-selection/aen-product-info/aen-product-info";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { FormSelect } from "components/form/form-select/form-select";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { useProducts } from "utilities/hooks/models/products/use-products";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AENProductCardSelectionList } from "components/products/aen-product-card-selection/aen-product-card-selection-list/aen-product-card-selection-list";
import { ProductRecord } from "models/view-models/products/product-record";
import "./aen-product-catalog-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENProductCatalogPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-product-catalog-page";
const DEBOUNCE_TIME = 750;
const STATUS_FILTER = [ActiveStatus.Active];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENProductCatalogPage: React.FC<AENProductCatalogPageProps> = validatePageAccess(
    AccessControlKeys.AENProductCatalogPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.dashboard);

    const [searchText, setSearchText] = useState<string>("");
    const [debounchedSearchText, setDebounchedSearchText] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<number>();
    const [selectedTopic, setSelectedTopic] = useState<number>();

    const { products } = useProducts({
        activeStatusFilter: STATUS_FILTER,
        applySearch: true,
        includeAvailableForAENOnly: true,
        includeLatestVersion: true,
        language: selectedLanguage,
        searchProductNameText: debounchedSearchText ?? "",
        topic: selectedTopic,
    });

    const [selectedProduct, setSelectedProduct] = useState<ProductRecord>();

    const handleProductSelection = useCallback((newProduct?: ProductRecord) => {
        setSelectedProduct(newProduct);
    }, []);

    const handleSearchTriggered = useCallback((searchText: string): void => {
        setDebounchedSearchText(searchText);
        setSelectedProduct(undefined);
    }, []);

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedLanguage(NumberUtils.parseInt(event.target.value));
        setSelectedProduct(undefined);
    };

    const handleFilterByTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedTopic(NumberUtils.parseInt(event.target.value));
        setSelectedProduct(undefined);
    };

    const handleClearAll = () => {
        setSearchText("");
        setSelectedLanguage(undefined);
        setSelectedTopic(undefined);
        setSelectedProduct(undefined);
    };

    return (
        <div>
            <HeaderBanner title={t("aenProductCatalog")} />
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__sidebar`}>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__sidebar__filter__title`}
                        size={ParagraphSize.XLarge}
                        style={ParagraphStyle.Label}>
                        {t("filterResults")}
                    </Paragraph>
                    <div className={`${CSS_CLASS_NAME}__sidebar__filter__criteria`}>
                        <SearchTextInput
                            debounce={DEBOUNCE_TIME}
                            onSearchTextInputChange={handleSearchTextInputChange}
                            onSearchTriggered={handleSearchTriggered}
                            id={"instructorSearch"}
                            placeholder={t("typeToSearchByName")}
                            searchTextInputValue={searchText}
                        />
                        <FormSelect
                            ariaLabelledBy={t("filterByTopic")}
                            formFieldName={t("filterByTopic")}
                            id="productFilterByTopic"
                            label=""
                            placeholder={t("topic")}
                            onChange={handleFilterByTopicChange}
                            options={EnumUtils.numericEnumToSelectOptions(Topic, TopicDisplayNames)}
                            value={selectedTopic?.toString()}
                        />
                        <FormSelect
                            ariaLabelledBy={t("filterByLanguage")}
                            formFieldName={t("filterByLanguage")}
                            id="instructorFilterByLanguage"
                            label=""
                            placeholder={t("language")}
                            onChange={handleFilterByLanguageChange}
                            options={EnumUtils.numericEnumToSelectOptions(
                                Language,
                                LanguageDisplayNames
                            )}
                            value={selectedLanguage?.toString()}
                        />
                        <Button
                            onClick={handleClearAll}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Quaternary}
                            text={t("clearAllFilters")}
                        />
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__main`}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        {selectedProduct == null || !selectedProduct.availableForAEN ? (
                            <AENProductCardSelectionList
                                products={products}
                                onProductSelection={handleProductSelection}
                            />
                        ) : (
                            <AENProductInfo
                                product={selectedProduct}
                                onClose={() => handleProductSelection(undefined)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENProductCatalogPage };

// #endregion Exports
