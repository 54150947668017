import React, { useEffect, useMemo } from "react";
import { AboutThisTraining } from "components/training-summary/about-this-training/about-this-training";
import { AssessmentRow } from "components/training-summary/assessment-row/assessment-row";
import { Card, CardStyle } from "components/card/card";
import { ContactCardList } from "components/training-summary/contact-card-list/contact-card-list";
import { DateUtils } from "utilities/date-utils";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { EnrollmentScormPackageAssessmentStatus } from "models/enumerations/enrollments/enrollment-scorm-package-assessment-status";
import { EventScheduleCard } from "components/events/manage-event-details/event-schedule-card/event-schedule-card";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { Language } from "models/enumerations/languages/language";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { SubmittedEvaluation } from "components/evaluations/submitted-evaluation/submitted-evaluation";
import { SummaryHeader } from "components/training-summary/summary-header/summary-header";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useEvent } from "utilities/hooks/models/events/use-event";
import { useParams } from "react-router-dom";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import "./learner-training-summary-ilt-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTrainingSummaryILTPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-training-summary-ilt-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTrainingSummaryILTPage: React.FC<LearnerTrainingSummaryILTPageProps> =
    validatePageAccess(AccessControlKeys.LearnerTrainingSummaryILTPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { id: eventIdParam, enrollmentId: enrollmentIdParam } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeAttendance: true,
            includeCertificate: true,
            includeEvent: true,
            includeProduct: true,
            includeEvaluation: true,
            includeTrainingType: TrainingType.InstructorLedTraining,
        });

        const { event } = useEvent({
            eventId,
            includeProduct: true,
            includeEventSessions: true,
            includeInstructor: true,
            includeOrganization: true,
            includeProvider: true,
        });

        const { enrollmentScormPackageAssessments } = enrollment;

        const evaluationSubmitted = useMemo(
            () => enrollment.evaluation?.id != null && enrollment.evaluation.id > 0,
            [enrollment.evaluation?.id]
        );

        const badges = useMemo(() => {
            const badges: string[] = [];

            if (enrollment?.product?.type != null) {
                badges.push(t(TrainingTypeDisplayNames[enrollment.product.type]));
            }

            // ILT Training Type Badge
            if (enrollment?.event?.instructorLedTrainingType != null) {
                badges.push(
                    t(
                        InstructorLedTrainingTypeDisplayNames[
                            enrollment.event.instructorLedTrainingType
                        ]
                    )
                );
            }

            if (enrollment?.product?.language != null) {
                badges.push(Language[enrollment.product?.language]);
            }

            badges.push(t("ceusEventCEUs", { eventCEUs: event.getCeus() }));

            return badges;
        }, [
            enrollment.event?.instructorLedTrainingType,
            enrollment.product?.language,
            enrollment.product?.type,
            event,
        ]);

        const headerBadges = useMemo(() => {
            const badges: string[] = [];

            if (enrollment?.expirationDate != null) {
                badges.push(
                    t("trainingAccessExpDateUtilsFormatDatetrueenrollmentExpirationDateToString", {
                        DateUtilsFormatDatetrueenrollmentExpirationDateToString:
                            DateUtils.formatDate(true, enrollment.expirationDate.toString()),
                    })
                );
            }

            if (enrollment.certificate?.expirationDate != null) {
                badges.push(
                    t(
                        "certificateExpDateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString",
                        {
                            DateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString:
                                DateUtils.formatDate(
                                    true,
                                    enrollment.certificate.expirationDate.toString()
                                ),
                        }
                    )
                );
            }

            return badges;
        }, [enrollment.certificate?.expirationDate, enrollment.expirationDate]);

        const getTrainingSummaryAssessmentStatus = (
            assessment: EnrollmentScormPackageAssessmentRecord
        ) => {
            if (assessment.status === EnrollmentScormPackageAssessmentStatus.Complete) {
                return EnrollmentScormPackageAssessmentStatus.Complete;
            }

            if (assessment.registrationId == null) {
                return EnrollmentScormPackageAssessmentStatus.NotStarted;
            }

            return EnrollmentScormPackageAssessmentStatus.Attempted;
        };

        useEffect(function scrollToTop() {
            ScrollUtils.scrollToTop();
        }, []);

        return (
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <h1>{t("trainingSummaryILT")}</h1>
                    {event.organization?.id != null && (
                        <div className={`${CSS_CLASS_NAME}__contract-banner`}>
                            <NotificationBanner
                                icon={Icons.InformationOutline}
                                notificationId={0}
                                onClose={() => {}}
                                style={NotificationType.Default}
                                message={t(
                                    "youHaveBeenAddedOnBehalfOfEventOrganizationNameYourDataMayBeSharedWithThisOrganization",
                                    { eventOrganizationName: event.organization?.name }
                                )}
                            />
                        </div>
                    )}
                    <SummaryHeader
                        badges={headerBadges}
                        enrollmentStatus={enrollment.getStatus()}
                        icon={Icons.EventDetails}
                        title={t("aboutThisTraining")}
                    />
                    <AboutThisTraining
                        description={enrollment.product?.description ?? ""}
                        name={enrollment.product?.name ?? ""}
                        badges={badges}
                        topics={enrollment.product?.topic ? [enrollment.product.topic] : []}
                    />
                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Assignment} title={t("schedule")} />
                    <EventScheduleCard
                        attendances={enrollment.attendances}
                        event={event}
                        schedule={event.eventDays}
                        stacked={true}
                    />

                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Assessment} title={t("assessments")} />
                    <div>
                        {enrollmentScormPackageAssessments?.map((assessment) => (
                            <Card stacked={true} tight={true} key={assessment.id}>
                                <AssessmentRow
                                    name={
                                        assessment.scormPackageAssessment?.scormPackage?.name ?? ""
                                    }
                                    number={assessment.sortOrder}
                                    score={assessment.currentScore}
                                    status={getTrainingSummaryAssessmentStatus(assessment)}
                                    required={!assessment.optional}
                                />
                            </Card>
                        ))}
                    </div>

                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Evaluation} title={t("evaluation")} />
                    {!evaluationSubmitted && (
                        <Card style={CardStyle.Empty}>
                            <Icon type={Icons.EvaluationIncomplete} size={IconSizes.Large} />
                            <EmptyText spacing={EmptyTextSpacing.None}>
                                {t("theEvaluationWasNotSubmittedForThisTraining")}
                            </EmptyText>
                        </Card>
                    )}
                    {evaluationSubmitted && (
                        <SubmittedEvaluation evaluationId={enrollment.evaluation!.id!} />
                    )}

                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Phone} title={t("contact")} />
                    <ContactCardList
                        avatarFileId={event.instructor?.instructorProfile?.avatarFileId}
                        showHostCard={!event.hideContactInformation}
                        showInstructorCard={true}
                        instructorId={event.instructorId}
                        hostCompanyEmail={event.getHostEmailAddress()}
                        hostCompanyName={event.getHostSupportName()}
                        hostCompanyPhone={event.getHostPhone()}
                        instructorCompanyEmail={
                            event.instructor?.instructorProfile ? event.instructor.email : ""
                        }
                        instructorCompanyName={event.instructor?.getFirstAndLastName()}
                        instructorCompanyPhone={
                            event.instructor?.instructorProfile ? event.instructor.phoneNumber : ""
                        }
                    />
                </div>
            </SkipNavContent>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTrainingSummaryILTPage };

// #endregion Exports
