import { EventRecord } from "models/view-models/events/event-record";
import { StringUtils } from "utilities/string-utils";
import { RoleType } from "models/enumerations/users/role-type";
import { CollectionUtils } from "utilities/collection-utils";
import {
    DISPLAY_OPTIONS,
    REPORT_TYPES,
} from "components/reports/report-evaluation/report-evaluation-constants";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { GroupRecord } from "models/view-models/groups/group-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportEvaluationState {
    contracts: ContractRecord[];
    dateRangeEnd: Date | null;
    dateRangeStart: Date | null;
    displayType: string;
    events: EventRecord[];
    instructors: InstructorRecord[];
    organizations: OrganizationRecord[];
    groups: GroupRecord[];
    products: ProductRecord[];
    reportType: string;
}

type ReducerActions =
    | { type: "reportType"; reportType: string }
    | { type: "displayType"; displayType: string }
    | { type: "dateRange"; dateRangeStart: Date | null; dateRangeEnd: Date | null }
    | { type: "updateEvents"; events: EventRecord[] }
    | { type: "updateContracts"; contracts: ContractRecord[] }
    | { type: "updateGroups"; groups: GroupRecord[] }
    | { type: "updateOrganizations"; organizations: OrganizationRecord[] }
    | { type: "updateProducts"; products: ProductRecord[] }
    | { type: "updateInstructors"; instructors: InstructorRecord[] };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: ReportEvaluationState = {
    reportType: "",
    displayType: "",
    dateRangeStart: null,
    dateRangeEnd: null,
    events: [],
    instructors: [],
    organizations: [],
    products: [],
    contracts: [],
    groups: [],
};

const validator =
    (roleType?: RoleType | null) =>
    (state: ReportEvaluationState): boolean => {
        const {
            contracts,
            instructors,
            organizations,
            reportType,
            displayType,
            events,
            products,
            groups,
        } = state;

        if (!StringUtils.hasValue(displayType)) {
            return false;
        }

        if (
            CollectionUtils.hasValues(events) ||
            CollectionUtils.hasValues(contracts) ||
            CollectionUtils.hasValues(events) ||
            CollectionUtils.hasValues(products) ||
            CollectionUtils.hasValues(groups) ||
            CollectionUtils.hasValues(organizations) ||
            CollectionUtils.hasValues(instructors)
        ) {
            return true;
        }
        return false;
    };

const reducer = (state: ReportEvaluationState, action: ReducerActions): ReportEvaluationState => {
    switch (action.type) {
        case "reportType":
            const { reportType } = action;

            return {
                ...state,
                reportType,
                events: [],
                instructors: [],
                contracts: [],
                organizations: [],
                groups: [],
                dateRangeEnd: null,
                dateRangeStart: null,
                products: [],
            };

        case "displayType":
            const { displayType } = action;

            return { ...state, displayType };

        case "dateRange":
            const { dateRangeStart, dateRangeEnd } = action;

            return {
                ...state,
                dateRangeStart,
                dateRangeEnd,
            };

        case "updateEvents":
            const { events } = action;
            return { ...state, events };

        case "updateContracts":
            const { contracts } = action;
            return { ...state, contracts };

        case "updateGroups":
            const { groups } = action;
            return { ...state, groups };

        case "updateOrganizations":
            const { organizations } = action;
            return { ...state, organizations };

        case "updateInstructors":
            const { instructors } = action;
            return {
                ...state,
                instructors,
            };

        case "updateProducts":
            const { products } = action;
            return { ...state, products };

        default:
            throw new Error();
    }
};

const initializer =
    (roleType?: RoleType | null) =>
    (initialState: ReportEvaluationState): ReportEvaluationState => {
        if (roleType === RoleType.Instructor) {
            return {
                ...initialState,
                reportType: REPORT_TYPES.INSTRUCTOR_LED_TRAINING_EVENT,
                displayType: DISPLAY_OPTIONS.SUMMARY,
            };
        }

        if (roleType === RoleType.AenAdministrator) {
            return {
                ...initialState,
                reportType: REPORT_TYPES.INSTRUCTOR_LED_TRAINING_EVENT,
                displayType: DISPLAY_OPTIONS.SUMMARY,
            };
        }

        if (roleType === RoleType.ClientAdmin) {
            return {
                ...initialState,
                reportType: REPORT_TYPES.ORGANIZATION,
                displayType: DISPLAY_OPTIONS.SUMMARY,
            };
        }

        return initialState;
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validator };
export type { ReportEvaluationState };

// #endregion Exports
