import { RoleType } from "models/enumerations/users/role-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const REPORT_TYPES = {
    INSTRUCTOR_LED_TRAINING_EVENT: "1",
    EN_INSTRUCTOR: "2",
    ORGANIZATION: "3",
    // TODO: Uncomment when EN_PROVIDER is implemented
    // EN_PROVIDER: "4",
    ONLINE_LEARNING_TRAINING: "5",
} as const;

const REPORT_TYPES_DESCRIPTIONS = {
    [REPORT_TYPES.INSTRUCTOR_LED_TRAINING_EVENT]: "instructorLedTrainingEvent",
    [REPORT_TYPES.EN_INSTRUCTOR]: "enInstructor",
    [REPORT_TYPES.ORGANIZATION]: "organization",
    // TODO: Uncomment when EN_PROVIDER is implemented
    // [REPORT_TYPES.EN_PROVIDER]: "enProvider",
    [REPORT_TYPES.ONLINE_LEARNING_TRAINING]: "onlineLearningTraining",
} as const;

const DISPLAY_OPTIONS = {
    SUMMARY: "1",
    DETAILED_BREAKDOWN: "2",
    INSTRUCTOR_AVERAGE_RATING: "3",
};

const DISPLAY_OPTION_VALUES: Record<
    string,
    { text: TranslatedCopy; roles?: RoleType[]; excludeReportTypes?: string[]; disabled?: boolean }
> = {
    [DISPLAY_OPTIONS.SUMMARY]: { text: "summary" },
    [DISPLAY_OPTIONS.DETAILED_BREAKDOWN]: {
        text: "detailedBreakdown",
        roles: [RoleType.NfpaAdministrator],
    },
    [DISPLAY_OPTIONS.INSTRUCTOR_AVERAGE_RATING]: {
        disabled: true,
        text: "instructorAverageRating",
        excludeReportTypes: [REPORT_TYPES.ONLINE_LEARNING_TRAINING],
    },
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { DISPLAY_OPTIONS, DISPLAY_OPTION_VALUES, REPORT_TYPES, REPORT_TYPES_DESCRIPTIONS };

// #endregion Exports
