import React, { useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { HeaderBanner } from "components/header-banner/header-banner";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { CollectionUtils } from "andculturecode-javascript-core";
import { Pager } from "components/pager/pager";
import { useCatalogEnrollmentLogs } from "utilities/hooks/models/enrollments/use-catalog-enrollment-logs";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { DataTable } from "components/tables/data-table/data-table";
import { StringUtils } from "utilities/string-utils";
import { CatalogEnrollmentErrorTypeDisplayNames } from "models/enumerations/enrollments/catalog-enrollment-log";
import { DateUtils } from "utilities/date-utils";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import {
    PublishStatusDisplayNames,
    PublishStatusMap,
} from "models/enumerations/publish-status/publish-status";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import "./catalog-enrollment-log-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminCatalogEnrollmentLogPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "catalog-enrollment-log-page";
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminCatalogEnrollmentLogPage: React.FC<AdminCatalogEnrollmentLogPageProps> =
    validatePageAccess(AccessControlKeys.AdminCatalogEnrollmentLogPage)((): JSX.Element => {
        const [currentPage, setCurrentPage] = useState(1);

        const { catalogEnrollmentLogs: logs, rowCount: totalLogs } = useCatalogEnrollmentLogs({
            skip: (currentPage - 1) * ITEMS_PER_PAGE,
            take: ITEMS_PER_PAGE,
        });

        const onPageClick = (pageNumber: number) => {
            setCurrentPage(pageNumber);
        };

        return (
            <>
                <HeaderBanner title={t("catalogEnrollmentLog")}></HeaderBanner>
                <div className={`${CSS_CLASS_NAME}__window`}>
                    <div className={`${CSS_CLASS_NAME}__window__main`}>
                        <div className={`${CSS_CLASS_NAME}__window__main__logs`}>
                            <div className={`${CSS_CLASS_NAME}__window__main__logs__content`}>
                                <SkipNavContent>
                                    <DataTable cssClassName={CSS_CLASS_NAME}>
                                        <thead>
                                            <tr>
                                                <th className="account">{t("account")}</th>
                                                <th className="orderInfo">{t("orderInfo")}</th>
                                                <th className="erpId">{t("erpId")}</th>
                                                <th className="product">{t("product")}</th>
                                                <th className="event">{t("event")}</th>
                                                <th className="error">{t("error")}</th>
                                                <th className="action">
                                                    <span className="sr-only">{t("action")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {logs.map((log) => (
                                                <tr key={log.id}>
                                                    <td className="account">
                                                        <span>
                                                            <div
                                                                className={`${CSS_CLASS_NAME}__account-name`}>
                                                                {StringUtils.valueOrDefault(
                                                                    (
                                                                        log.firstName +
                                                                        " " +
                                                                        log.lastName
                                                                    ).trim()
                                                                )}
                                                            </div>
                                                            <div
                                                                className={`${CSS_CLASS_NAME}__account-email`}>
                                                                {StringUtils.valueOrDefault(
                                                                    log.userName
                                                                )}
                                                            </div>
                                                            <div>
                                                                {StringUtils.valueOrDefault(
                                                                    log.externalIdentifier
                                                                )}
                                                            </div>
                                                            <div>
                                                                {StringUtils.valueOrDefault(
                                                                    log.netSuiteUserId?.toString()
                                                                )}
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td className="order">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.orderNumber?.toString()
                                                            )}
                                                        </div>
                                                        <div>
                                                            {DateUtils.formatDateCustom(
                                                                log.createdOn?.toString(),
                                                                "MM/DD/yy hh:mm a"
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="erpId">
                                                        {StringUtils.valueOrDefault(log.erpId)}
                                                    </td>
                                                    <td className="product">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.productName
                                                            )}
                                                        </div>
                                                        <div>{log.productId}</div>
                                                        <div>
                                                            {log.productStatus && (
                                                                <EnumStatusBadge
                                                                    displayNames={
                                                                        ActiveStatusStatusDisplayNames
                                                                    }
                                                                    statusMap={ActiveStatusMap}
                                                                    value={log.productStatus}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="event">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.eventName
                                                            )}
                                                        </div>
                                                        <div> {log.trainingSessionId}</div>
                                                        <div>
                                                            {log.eventStatus && (
                                                                <EnumStatusBadge
                                                                    displayNames={
                                                                        PublishStatusDisplayNames
                                                                    }
                                                                    statusMap={PublishStatusMap}
                                                                    value={log.eventStatus}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="error">
                                                        <Tooltip
                                                            content={
                                                                <>
                                                                    {log.errorMessage ??
                                                                        t("notApplicableShort")}
                                                                </>
                                                            }
                                                            placement={TooltipPlacement.Top}>
                                                            <span>
                                                                {log.errorType
                                                                    ? t(
                                                                          CatalogEnrollmentErrorTypeDisplayNames[
                                                                              log.errorType
                                                                          ]
                                                                      )
                                                                    : "--"}
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </DataTable>
                                </SkipNavContent>
                            </div>
                        </div>
                        {CollectionUtils.hasValues(logs) && (
                            <div className={`${CSS_CLASS_NAME}__window__main__pager`}>
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={totalLogs / ITEMS_PER_PAGE}
                                    onPageClick={onPageClick}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={totalLogs}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminCatalogEnrollmentLogPage };

// #endregion Exports
