import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { AddGroupUserModalUser } from "components/user-management/groups/add-group-users-modal/manage-add-group-users";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { DataTable } from "components/tables/data-table/data-table";
import { useCallback, useEffect, useRef, useState } from "react";
import { Icons } from "components/icons/constants/icons";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";
import "./add-group-user-modal-user-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------
interface AddGroupUserModalUserListProps {
    addGroupUserModalUsers: AddGroupUserModalUser[];
    debounceTime: number;
    filterUsers: (searchText: string) => void;
    handleAdd: (user: UserRecord) => void;
    handleRemove: (user: UserRecord) => void;
    searchTextPlaceholder: string;
    onResetSkip: () => void;
    onIncrementSkip: () => void;
    loading: boolean;
    allowPaging: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "add-group-user-modal-user-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddGroupUserModalUserList: React.FC<AddGroupUserModalUserListProps> = ({
    addGroupUserModalUsers,
    debounceTime,
    filterUsers,
    handleAdd,
    handleRemove,
    searchTextPlaceholder,
    onResetSkip,
    onIncrementSkip,
    loading,
    allowPaging,
}) => {
    const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
    const [searchText, setSearchText] = useState<string>("");

    const handleSearchTextInputChange = (searchText: string): void => {
        onResetSkip();
        setSearchText(searchText);
    };

    const observerRef = useCallback(
        (node: Element | null) => {
            if (node == null) return;
            if (!allowPaging) {
                if (intersectionObserverRef.current != null) {
                    intersectionObserverRef.current.disconnect();
                }
            } else {
                intersectionObserverRef.current = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        if (entries.length > 1) return;
                        if (entries.some((e) => e.isIntersecting)) {
                            onIncrementSkip();
                        }
                    },
                    {
                        threshold: 1.0,
                    }
                );
                intersectionObserverRef.current.observe(node);
            }
        },
        [allowPaging, onIncrementSkip]
    );

    useEffect(() => {
        if (loading) {
            document.body.style.cursor = "wait";
        } else {
            document.body.style.cursor = "default";
        }
    }, [loading]);

    return (
        <>
            <SearchTextInput
                debounce={debounceTime}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={filterUsers}
                id="userSearch"
                placeholder={searchTextPlaceholder}
                searchTextInputValue={searchText}
            />
            {CollectionUtils.hasValues(addGroupUserModalUsers) && (
                <>
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th className="user-name">{t("user")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">{t("action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addGroupUserModalUsers.map((addGroupUserModalUser, idx) => (
                                <tr key={`${addGroupUserModalUser.user.id}-${idx}`}>
                                    <td className="user-name">
                                        <span>
                                            {addGroupUserModalUser.user.getFirstAndLastName()}
                                        </span>
                                        <span>
                                            <Tooltip
                                                content={addGroupUserModalUser.user.email ?? ""}
                                                placement={TooltipPlacement.Bottom}
                                                style={TooltipStyle.Secondary}>
                                                <div>
                                                    <Paragraph
                                                        cssClassName={`${CSS_CLASS_NAME}__user-email`}
                                                        size={ParagraphSize.Small}>
                                                        {addGroupUserModalUser.user.email}
                                                    </Paragraph>
                                                </div>
                                            </Tooltip>
                                        </span>
                                    </td>
                                    <td className="id">{addGroupUserModalUser.user.id}</td>
                                    <td className="action">
                                        {!addGroupUserModalUser.addedToQueue && (
                                            <ButtonIcon
                                                buttonSize={ButtonSize.Small}
                                                buttonStyle={ButtonStyle.Secondary}
                                                iconType={Icons.Plus}
                                                onClick={() =>
                                                    handleAdd(addGroupUserModalUser.user)
                                                }
                                                text={t("add")}
                                            />
                                        )}
                                        {addGroupUserModalUser.addedToQueue && (
                                            <ButtonIcon
                                                buttonSize={ButtonSize.Small}
                                                buttonStyle={ButtonStyle.Primary}
                                                iconType={Icons.Minus}
                                                onClick={() =>
                                                    handleRemove(addGroupUserModalUser.user)
                                                }
                                                text={t("remove")}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                    <span ref={observerRef} style={{ height: "20px" }}></span>
                </>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddGroupUserModalUserList };

// #endregion Exports
