import React from "react";
import { CollectionUtils } from "andculturecode-javascript-core";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { EnrollmentUserRecord } from "models/view-models/enrollments/enrollment-user-record";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import "./add-bulk-enrollment-preview.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddBulkEnrollmentPreviewProps {
    validEnrollmentUsers: EnrollmentUserRecord[];
    invalidEnrollmentUsers: EnrollmentUserRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constant
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-bulk-enrollment-preview";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentPreview: React.FC<AddBulkEnrollmentPreviewProps> = ({
    validEnrollmentUsers,
    invalidEnrollmentUsers,
}) => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div>
                <Heading size={HeadingSize.XSmall}>{t("validAttendees")}</Heading>
                {CollectionUtils.hasValues(validEnrollmentUsers) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="email">{t("email")}</th>
                                <th className="first-name">{t("firstName")}</th>
                                <th className="lastName">{t("lastName")}</th>
                            </tr>
                        </thead>
                        {validEnrollmentUsers.map((enrollmentUser, idx) => (
                            <tbody>
                                <tr key={`valid-${idx}`}>
                                    <td className="email">{enrollmentUser.email}</td>
                                    <td className="first-name">{enrollmentUser.firstName}</td>
                                    <td className="lastName">{enrollmentUser.lastName}</td>
                                </tr>
                            </tbody>
                        ))}
                    </DataTable>
                ) : (
                    <EmptyText>{t("noValidAttendees")}</EmptyText>
                )}
            </div>
            <div>
                <Heading size={HeadingSize.XSmall}>{t("invalidAttendees")}</Heading>
                {CollectionUtils.hasValues(invalidEnrollmentUsers) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="email">{t("email")}</th>
                                <th className="first-name">{t("firstName")}</th>
                                <th className="lastName">{t("lastName")}</th>
                                <th className="errors">{t("errors")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invalidEnrollmentUsers.map((enrollmentUser, idx) => (
                                <tr key={`invalid-${idx}`}>
                                    <td className="email">{enrollmentUser.email}</td>
                                    <td className="first-name">{enrollmentUser.firstName}</td>
                                    <td className="lastName">{enrollmentUser.lastName}</td>
                                    <td className="errors">
                                        {enrollmentUser.errors?.map((errorMessage) => (
                                            <div>{errorMessage}</div>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <EmptyText table>{t("noInvalidAttendees")}</EmptyText>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentPreview };

// #endregion Exports
