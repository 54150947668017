import React, { useMemo, useCallback } from "react";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { RegistrationLaunchButton } from "components/registrations/registration-launch-button/registration-launch-button";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useEnrollmentScormPackageAssessment } from "utilities/hooks/models/enrollments/use-enrollment-scorm-package-assessment";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./learner-assessment-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentCardProps {
    enrollmentId: number;
    eventId?: number;
    isLocked?: boolean;
    isRequired?: boolean;
    onLaunchButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
    onRegistrationChange?: (registration: RegistrationRecord) => void;
    registration?: RegistrationRecord;
    scormPackageAssessment: ScormPackageAssessmentRecord;
    showViewResults?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-assessment-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentCard: React.FC<LearnerAssessmentCardProps> = ({
    enrollmentId,
    eventId,
    isLocked,
    isRequired,
    onLaunchButtonClicked,
    onRegistrationChange,
    registration,
    scormPackageAssessment,
    showViewResults = true,
}): JSX.Element => {
    const navigate = useNavigate();

    const { enrollmentScormPackageAssessment } = useEnrollmentScormPackageAssessment({
        enrollmentId: enrollmentId,
        scormPackageAssessmentId: scormPackageAssessment.id,
    });

    const viewResults = useCallback(
        (scormPackageAssessment: ScormPackageAssessmentRecord) => {
            const path = RouteUtils.replacePathParams(
                sitemap.learner.training.event.assessments.results,
                {
                    id: eventId,
                    enrollmentId: enrollmentId,
                    assessmentId: scormPackageAssessment.id,
                    registrationId: registration?.id,
                }
            );
            navigate(path);
        },
        [navigate, enrollmentId, eventId, registration?.id]
    );

    const attemptExists = useMemo(
        () => registration?.attempts != null && registration?.attempts?.length > 0,
        [registration?.attempts]
    );

    const passingAttemptExists = useMemo(() => {
        return registration?.attempts?.some(
            (attempt) => attempt?.successStatus === AttemptSuccessStatus.Passed
        );
    }, [registration]);

    const statusBadge = useMemo(() => {
        if (registration == null || registration.id == null || registration.id <= 0) {
            return <Badge style={BadgeStyle.Default} text={t("notStarted")} />;
        }

        if (
            registration?.attempts?.some(
                (attempt) => attempt?.successStatus === AttemptSuccessStatus.Passed
            )
        ) {
            return <Badge style={BadgeStyle.Success} text={t("passed")} />;
        }

        if (!registration?.hasAvailableAttempts) {
            return <Badge style={BadgeStyle.Error} text={t("failed")} />;
        }

        return <Badge style={BadgeStyle.Warning} text={t("inProgress")} />;
    }, [registration]);

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__status`}>
                <div className={`${CSS_CLASS_NAME}__status-badge`}>{statusBadge}</div>
                <Badge
                    style={isRequired ? BadgeStyle.Neutral : BadgeStyle.Default}
                    text={isRequired ? t("required") : t("optional")}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {scormPackageAssessment.scormPackage?.name}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__assessment-buttons`}>
                    {
                        // if
                        attemptExists && showViewResults && (
                            <Button
                                onClick={() => viewResults(scormPackageAssessment)}
                                size={ButtonSize.Medium}
                                style={ButtonStyle.Primary}
                                text={t("viewResults")}
                            />
                        )
                    }
                    {
                        // if
                        !passingAttemptExists && (
                            <RegistrationLaunchButton
                                disabled={isLocked}
                                text={t("launchAssessment")}
                                enrollmentId={enrollmentId}
                                enrollmentScormPackageAssessmentId={
                                    enrollmentScormPackageAssessment?.id
                                }
                                registrationId={registration?.id}
                                scormPackageId={scormPackageAssessment.scormPackageId}
                                onRegistrationChange={onRegistrationChange}
                                onLaunchButtonClicked={onLaunchButtonClicked}
                            />
                        )
                    }
                </div>

                <Paragraph style={ParagraphStyle.Label}>
                    {t("attempts")}: {registration?.attempts?.length ?? 0}
                </Paragraph>
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentCard };

// #endregion Exports
