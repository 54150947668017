import { CatalogEnrollmentLogRecord } from "models/view-models/enrollments/catalog-enrollment-log-record";
import { CatalogEnrollmentLogService } from "utilities/services/enrollments/catalog-enrollment-log-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingCatalogEnrollmentLogs";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseCatalogEnrollmentLogsHook {
    error?: Error;
    isLoading: boolean;
    catalogEnrollmentLogs: CatalogEnrollmentLogRecord[];
    rowCount: number;
}

interface UseCatalogEnrollmentLogsHookOptions {
    onError?: (error: Error) => void;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useCatalogEnrollmentLogs({
    onError,
    skip,
    take,
}: UseCatalogEnrollmentLogsHookOptions): UseCatalogEnrollmentLogsHook {
    const { list } = CatalogEnrollmentLogService.useList();
    const [catalogEnrollmentLogs, setCatalogEnrollmentLogs] = useState<
        CatalogEnrollmentLogRecord[]
    >([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = useCallback(async () => {
        try {
            (async function getCatalogEnrollmentLogs() {
                setIsLoading(true);
                const catalogEnrollmentLogsResponse = await list({
                    skip: skip,
                    take: take,
                });
                setIsLoading(false);

                if (
                    catalogEnrollmentLogsResponse?.resultObjects == null ||
                    catalogEnrollmentLogsResponse?.results == null ||
                    catalogEnrollmentLogsResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setCatalogEnrollmentLogs(catalogEnrollmentLogsResponse.resultObjects);
                setRowCount(catalogEnrollmentLogsResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, setError, skip, take]);

    useEffect(() => {
        fetchData();
    }, [skip, take, fetchData]);

    return {
        error,
        isLoading,
        catalogEnrollmentLogs,
        rowCount,
    };
}

// #endregion Hook
