import React, { useEffect, useMemo } from "react";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardGridColumns,
    RadioCardStyle,
} from "../../form/form-radio-card-list/form-radio-card-list";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Paragraph, ParagraphSize } from "../../typography/paragraph/paragraph";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { EventRecord } from "models/view-models/events/event-record";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import { RoleType } from "models/enumerations/users/role-type";
import {
    DISPLAY_OPTIONS,
    DISPLAY_OPTION_VALUES,
    REPORT_TYPES,
    REPORT_TYPES_DESCRIPTIONS,
} from "components/reports/report-evaluation/report-evaluation-constants";
import {
    reducer,
    initialState,
    initializer,
    validator,
} from "components/reports/report-evaluation/report-evaluation-reducer";
import { EventsControllerIndexType } from "utilities/services/events/event-service";
import ReportContractSelection from "components/reports/components/report-contract-selection/report-contract-selection";
import ReportSection, {
    ReportSectionSizes,
} from "components/reports/components/report-section/report-section";
import EventSelection from "components/event-selection/event-selection";
import GroupSelection from "components/group-selection/group-selection";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import ReportOrganizationSelection from "components/reports/components/report-organization-selection/report-organization-selection";
import ReportInstructorSelection from "components/reports/components/report-instructor-selection/report-instructor-selection";
import ReportProductSelection from "components/reports/components/report-product-selection/report-product-selection";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./report-evaluation.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsEvaluationProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-evaluation";

const REPORT_TYPES_CARDS = Object.values(REPORT_TYPES).map(
    (key): RadioCard => ({
        icon: Icons.Reporting,
        text: REPORT_TYPES_DESCRIPTIONS[key],
        value: key,
    })
);

const DISPLAY_OPTIONS_CARDS = (reportType: string, role?: RoleType | null) =>
    Object.values(DISPLAY_OPTIONS)
        .map((key) => ({
            ...DISPLAY_OPTION_VALUES[key],
            value: `${key}`,
        }))
        .filter((option) => {
            if (option.roles == null || role == null || option.roles.length === 0) {
                return true;
            }

            return option.roles.includes(role);
        })
        .filter((option) => {
            if (option.excludeReportTypes == null || option.excludeReportTypes.length === 0) {
                return true;
            }

            return !option.excludeReportTypes.includes(reportType);
        })
        .map(
            (option): RadioCard => ({
                disabled: option.disabled,
                icon: Icons.Reporting,
                text: option.text,
                value: option.value,
            })
        );

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportEvaluation: React.FC<ReportsEvaluationProps> = ({
    onChange,
    roleType,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(roleType),
        validate: validator(roleType),
    });

    const {
        contracts,
        instructors,
        organizations,
        products,
        groups,
        isValid,
        reportType,
        displayType,
        dateRangeStart,
        dateRangeEnd,
        events,
    } = payload;

    const organizationIds = useMemo(
        () =>
            globalState.currentIdentity?.isCurrentlyInRole(RoleType.ClientAdmin)
                ? [globalState.currentIdentity?.user?.organizationId ?? 0]
                : organizations
                      .map((organization) => organization.id)
                      .filter((id): id is number => id != null),
        [globalState.currentIdentity, organizations]
    );

    const groupIds = useMemo(
        () => groups.map((group) => group.id).filter((id): id is number => id != null),
        [groups]
    );

    const contractIds = useMemo(
        () => contracts.map((contract) => contract.id).filter((id): id is number => id != null),
        [contracts]
    );

    const instructorIds = useMemo(
        () =>
            instructors.map((instructor) => instructor.id).filter((id): id is number => id != null),
        [instructors]
    );

    const eventIds = useMemo(
        () => events.map((event) => event.id).filter((id): id is number => id != null),
        [events]
    );

    const productIds = useMemo(
        () => products.map((product) => product.id).filter((id): id is number => id != null),
        [products]
    );

    const displayOptions = useMemo(
        () => DISPLAY_OPTIONS_CARDS(reportType, roleType),
        [reportType, roleType]
    );

    const handleReportTypeChange = (value: string) => {
        dispatch({ type: "reportType", reportType: value });
    };

    const handleDateRangeChange = (date?: Array<Date | null>) => {
        const [start, end] = date ?? [];
        dispatch({ type: "dateRange", dateRangeStart: start, dateRangeEnd: end });
    };

    const handleDisplayTypeChange = (value: string) => {
        dispatch({ type: "displayType", displayType: value });
    };

    const handleChangeEvents = (events: EventRecord[]) => {
        dispatch({ type: "updateEvents", events });
    };

    useEffect(() => {
        onChange({
            organizationIds,
            productIds,
            contractIds,
            groupIds,
            dateRangeEnd,
            dateRangeStart,
            displayType,
            instructorIds,
            eventIds,
            isValid,
            reportType,
        });
    }, [
        contractIds,
        groupIds,
        dateRangeEnd,
        dateRangeStart,
        displayType,
        eventIds,
        instructorIds,
        isValid,
        onChange,
        organizationIds,
        payload,
        productIds,
        reportType,
    ]);

    console.log("bill:");

    console.log("roleType:" + roleType);
    console.log("reportType:" + reportType);
    console.log("displayType:" + displayType);

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <h2>{t("evaluationReport")}</h2>
                            {/* INSTRUCTOR */}
                            {roleType === RoleType.NfpaAdministrator && (
                                <ReportInstructorSelection
                                    instructors={instructors}
                                    onInstructorsChanged={(instructors) =>
                                        dispatch({ type: "updateInstructors", instructors })
                                    }
                                />
                            )}
                            <EventSelection
                                events={events}
                                onEventsChanged={handleChangeEvents}
                                type={EventsControllerIndexType.EvaluationsReport}
                            />
                            {roleType === RoleType.NfpaAdministrator && (
                                <>
                                    <GroupSelection
                                        groups={groups}
                                        onGroupsChanged={(groups) =>
                                            dispatch({ type: "updateGroups", groups: groups })
                                        }
                                    />
                                    <ReportProductSelection
                                        products={products}
                                        trainingType={TrainingType.OnlineLearning}
                                        onProductsChanged={(products) =>
                                            dispatch({ type: "updateProducts", products })
                                        }
                                    />
                                </>
                            )}

                            {/* Organization Filter */}
                            {roleType === RoleType.NfpaAdministrator && (
                                <ReportOrganizationSelection
                                    organizations={organizations}
                                    filterByContractIds={contractIds}
                                    onOrganizationsChanged={(organizations) =>
                                        dispatch({ type: "updateOrganizations", organizations })
                                    }
                                />
                            )}

                            {/* Contract Filter */}
                            {(roleType === RoleType.ClientAdmin ||
                                roleType === RoleType.NfpaAdministrator) && (
                                <ReportContractSelection
                                    contracts={contracts}
                                    filterByOrganizationIds={organizationIds}
                                    onContractsChanged={(contracts) =>
                                        dispatch({ type: "updateContracts", contracts })
                                    }
                                    filterByOrgRequired={true}
                                />
                            )}

                            <ReportSection size={ReportSectionSizes.Half}>
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("duringThisTimePeriod")}
                                </Paragraph>
                                <FormCalendarDateRangePicker
                                    endDate={dateRangeEnd}
                                    formFieldName="dateRange"
                                    handleEventDateRangeChange={handleDateRangeChange}
                                    label={t("dateRange")}
                                    startDate={dateRangeStart}
                                />
                            </ReportSection>

                            <div className="report-section">
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("thatIsDisplayedAsA")}
                                </Paragraph>
                                <FormRadioCardList
                                    id="displayType"
                                    columns={RadioCardGridColumns.Three}
                                    formFieldName="displayType"
                                    label={t("displayType")}
                                    labelScreenReaderOnly={true}
                                    iconSize={IconSizes.Base}
                                    onChange={handleDisplayTypeChange}
                                    radioCards={displayOptions}
                                    required={true}
                                    style={RadioCardStyle.Horizontal}
                                    value={displayType}
                                />
                            </div>
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportEvaluation };

// #endregion Exports
