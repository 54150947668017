import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContentRecord } from "models/view-models/contents/content-record";
import { ContentService } from "utilities/services/contents/content-service";
import {
    CreateProductContentParams,
    ProductContentService,
} from "utilities/services/products/product-content-service";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentListManager } from "./product-content-list-manager/product-content-list-manager";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductCreateContentModal } from "./product-create-content-modal/product-create-content-modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersion } from "models/interfaces/products/product-version";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { RecordUpdater } from "utilities/contexts/use-record-context-factory";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./product-content-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductContentManagerProps {
    editMode?: boolean;
    inCreateVersionMode?: boolean;
    product: ProductRecord;
    productVersion: ProductVersionRecord;
    setDirty?: Dispatch<SetStateAction<boolean>>;
    setProductVersion: RecordUpdater<ProductVersion, ProductVersionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-content-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductContentManager: React.FC<ProductContentManagerProps> = ({
    editMode,
    inCreateVersionMode,
    product,
    productVersion,
    setDirty,
    setProductVersion,
}) => {
    const [showModal, setShowModal] = useState(false);
    const isInstructorLed = product.type === TrainingType.InstructorLedTraining;
    const { create: apiContentCreate } = ContentService.useCreate();
    const { create: apiProductContentCreate } = ProductContentService.useCreate();
    const { list: listProductContents } = ProductContentService.useList();
    const { update: updateProductVersion } = ProductVersionService.useUpdate();
    const [readOnly, setReadOnly] = useState(false);
    const deferSave = product.status === ActiveStatus.Active;

    useEffect(() => {
        if (
            product.status === ActiveStatus.Inactive ||
            product.status === ActiveStatus.Archived ||
            (product.status === ActiveStatus.Active && !editMode)
        ) {
            setReadOnly(true);
        }
        if (inCreateVersionMode || editMode) {
            setReadOnly(false);
        }
    }, [editMode, inCreateVersionMode, product.activatedOn, product.status]);

    const updateProductVersionContents = (productContents: ProductContentRecord[]) => {
        const updatedProductVersion = productVersion.with({ productContents: productContents });
        setProductVersion(updatedProductVersion);
    };

    const fetchProductContentData = useCallback(async () => {
        if (deferSave) {
            console.error("You should not fetch the Product Content when product is active.");
            return;
        }
        try {
            const listContentsResponse = await listProductContents({
                productId: product.id,
                productVersionId: productVersion.id,
                includeContent: true,
            });
            const listContentsResults = listContentsResponse?.results;
            const productContents = listContentsResponse?.resultObjects;

            if (
                productContents == null ||
                listContentsResults == null ||
                listContentsResults.hasErrors()
            ) {
                throw new Error();
            }
            productContents.sort((a, b) => a.sortOrder! - b.sortOrder!);
            setProductVersion(
                (previousProductVersion: ProductVersionRecord): ProductVersionRecord =>
                    previousProductVersion.with({ productContents: productContents })
            );
        } catch {
            alert(t("failedToRetrieveContents"));
        }
    }, [deferSave, listProductContents, product.id, productVersion.id, setProductVersion]);

    const onHasNoContentToggle = useCallback(async (): Promise<boolean> => {
        setDirty && setDirty(true);
        const updatedProductVersion = productVersion.with({
            hasNoContent: !productVersion.hasNoContent,
        });

        if (deferSave) {
            setProductVersion(updatedProductVersion);
        } else {
            try {
                const updateProductPathParms: UpdateProductVersionPathParams = {
                    id: product.id!,
                };

                const updateProductResponse = await updateProductVersion(
                    updatedProductVersion,
                    updateProductPathParms
                );
                const updateResult = updateProductResponse?.result;

                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }

                const updatedProductVersionRecord: ProductVersionRecord = productVersion.with({
                    hasNoContent: updateResult?.resultObject.hasNoContent,
                });

                setProductVersion(updatedProductVersionRecord);
            } catch {
                ToastManager.error(t("thereWasAnIssueSettingTheThisProductHasNoContentToggle"));
                return false;
            }
        }
        return true;
    }, [deferSave, product.id, productVersion, setDirty, setProductVersion, updateProductVersion]);

    const saveContentToDatabase = useCallback(
        async (
            content?: ContentRecord,
            productContent?: ProductContentRecord
        ): Promise<ContentRecord | undefined> => {
            if (
                productVersion.hasNoContent &&
                (!productVersion.productContents || productVersion.productContents.length === 0)
            ) {
                await onHasNoContentToggle();
            }

            try {
                if (product == null) {
                    throw new Error(t("thereWasAnIssueProductIDWasUndefined"));
                }

                const createContentResponse = await apiContentCreate(content);
                const createContentResult = createContentResponse?.result;

                if (createContentResult?.resultObject == null || createContentResult.hasErrors()) {
                    throw new Error(t("thereWasAProblemWithCreatingProductContent"));
                }

                return createContentResult?.resultObject;
            } catch (err) {
                if (err === "") {
                    ToastManager.error(t("thereWasAProblemWithProductContentManager"));
                } else {
                    ToastManager.error(`${err}`);
                }
                return undefined;
            }
        },
        [apiContentCreate, onHasNoContentToggle, product, productVersion]
    );

    const saveProductContentToDatabase = useCallback(
        async (
            productContent: ContentRecord,
            access: ProductContentAccess | undefined
        ): Promise<boolean> => {
            let createProductContentParams: CreateProductContentParams = {
                access: access!,
                contentId: productContent.id!,
                productId: product.id!,
                productVersionId: productVersion.id!,
                sortOrder: 1,
            };

            if (
                isInstructorLed &&
                access !== undefined &&
                access === ProductContentAccess.InstructorProvider &&
                CollectionUtils.isNotEmpty(productVersion.productContents)
            ) {
                const instructorProviderAccess = productVersion.productContents.filter(
                    (pc) => pc.access === ProductContentAccess.InstructorProvider
                );
                if (CollectionUtils.isNotEmpty(instructorProviderAccess)) {
                    const nextSortOrder =
                        Math.max(...instructorProviderAccess.map((pc) => pc.sortOrder!)) + 1;

                    createProductContentParams = {
                        access: access!,
                        contentId: productContent.id!,
                        productId: product.id!,
                        productVersionId: productVersion.id!,
                        sortOrder: nextSortOrder,
                    };
                }
            }

            if (
                isInstructorLed &&
                access !== undefined &&
                access === ProductContentAccess.Everyone &&
                CollectionUtils.isNotEmpty(productVersion.productContents)
            ) {
                const everyoneAccess = productVersion.productContents.filter(
                    (pc) => pc.access === ProductContentAccess.Everyone
                );

                if (CollectionUtils.isNotEmpty(everyoneAccess)) {
                    const nextSortOrder =
                        Math.max(...everyoneAccess.map((pc) => pc.sortOrder!)) + 1;

                    createProductContentParams = {
                        access: productContent?.access!,
                        contentId: productContent.id!,
                        productId: product.id!,
                        productVersionId: productVersion.id!,
                        sortOrder: nextSortOrder,
                    };
                }
            }

            if (!isInstructorLed && CollectionUtils.isNotEmpty(productVersion.productContents)) {
                const nextSortOrder =
                    Math.max(...productVersion.productContents.map((pc) => pc.sortOrder!)) + 1;

                createProductContentParams = {
                    access: productContent?.access!,
                    contentId: productContent.id!,
                    productId: product.id!,
                    productVersionId: productVersion.id!,
                    sortOrder: nextSortOrder,
                };
            }

            const createProductContentResponse = await apiProductContentCreate(
                createProductContentParams
            );
            const createProductContentResult = createProductContentResponse?.result;

            if (
                createProductContentResult?.resultObject == null ||
                createProductContentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemWithManagingContent"));
            }

            fetchProductContentData();
            return true;
        },
        [apiProductContentCreate, fetchProductContentData, isInstructorLed, product, productVersion]
    );

    const getMaxSortOrder = useCallback(
        (productContents: ProductContentRecord[], access: ProductContentAccess | undefined) => {
            if (productContents == null) return 1;
            if (isInstructorLed) {
                if (access === ProductContentAccess.InstructorProvider) {
                    return productContents?.filter(
                        (pc) => pc.access === ProductContentAccess.InstructorProvider
                    ).length;
                } else {
                    return productContents?.filter(
                        (pc) => pc.access === ProductContentAccess.Everyone
                    ).length;
                }
            } else {
                return productContents.length;
            }
        },
        [isInstructorLed]
    );

    const saveContent = useCallback(
        async (
            content?: ContentRecord,
            productContent?: ProductContentRecord
        ): Promise<boolean> => {
            const savedFile = content?.file;

            const productContentAccess = productContent?.access;

            await saveContentToDatabase(content).then((content) => {
                if (deferSave) {
                    setDirty && setDirty(true);
                    //Add the content to the productVersion:
                    if (content) {
                        const newProductContent: ProductContentRecord = new ProductContentRecord({
                            access: productContentAccess,
                            contentId: content.id,
                            content: content.with({ file: savedFile }),
                            productId: product.id,
                            productVersionId: productVersion.id,
                            sortOrder:
                                getMaxSortOrder(
                                    productVersion.productContents!,
                                    productContentAccess
                                ) + 1,
                        });
                        const updatedProductVersion = productVersion.with({
                            productContents: [
                                ...productVersion.productContents!,
                                newProductContent!,
                            ],
                        });

                        setProductVersion(updatedProductVersion);
                        return true;
                    }
                } else {
                    if (content == null) {
                        return false;
                    }
                    saveProductContentToDatabase(content, productContentAccess);
                }
            });
            return true;
        },
        [
            deferSave,
            getMaxSortOrder,
            product.id,
            productVersion,
            saveContentToDatabase,
            saveProductContentToDatabase,
            setDirty,
            setProductVersion,
        ]
    );

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                    {t("content")}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__header__actions`}>
                    <ToggleLabel
                        checked={productVersion.hasNoContent}
                        disabled={
                            (productVersion.productContents &&
                                productVersion.productContents.length > 0) ||
                            readOnly
                        }
                        id="no-content"
                        label={t("thisProductHasNoContent")}
                        onToggle={() => onHasNoContentToggle()}
                    />
                    <Button
                        disabled={productVersion.hasNoContent || readOnly}
                        onClick={() => setShowModal(true)}
                        style={ButtonStyle.Primary}
                        text={t("addContent")}
                    />
                </div>
            </div>

            {editMode && (
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t(
                            "changesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                        )}
                    />
                </div>
            )}
            <ProductCreateContentModal
                editing={false}
                open={showModal}
                product={product}
                setOpen={setShowModal}
                handleSaveContent={saveContent}
            />
            <div className={`${CSS_CLASS_NAME}__content`}>
                <ProductContentListManager
                    product={product}
                    productContents={productVersion.productContents}
                    fetchProductContents={fetchProductContentData}
                    onProductVersionContentsChange={updateProductVersionContents}
                    setDirty={setDirty}
                />
            </div>
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductContentManager };

// #endregion Exports
