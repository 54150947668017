import { AenInstructorApplicationDetailPage } from "pages/admin/aen-applications/detail-pages/aen-application-instructor-detail-page";
import { AenProviderApplicationDetailPage } from "pages/admin/aen-applications/detail-pages/aen-application-provider-detail-page";
import { AENApplicationsFilePage } from "pages/admin/aen-applications/aen-applications-file-page";
import { AENProductCatalogPage } from "pages/en-provider/aen-product-catalog-page/aen-product-catalog-page";
import { AdminAenResourcesListPage } from "pages/admin/aen-resources/aen-resources-providers-list-page/aen-resources-list-page";
import { AdminAnnouncementsListPage } from "./pages/admin/announcements/admin-announcements-list-page/admin-announcements-list-page";
import { AdminCatalogEnrollmentLogPage } from "pages/admin/catalog-enrollment-logs/catalog-enrollment-log-page/catalog-enrollment-log-page";
import { AdminCourseListPage } from "pages/admin/courses/admin-course-list-page/admin-course-list-page";
import { AdminDashboardPage } from "pages/admin/admin-dashboard-page/admin-dashboard-page";
import { AdminEditEventChangeLogPage } from "pages/admin/events/admin-edit-event/admin-edit-event-change-log-page/admin-edit-event-change-log-page";
import { AdminEditEventContactPage } from "pages/admin/events/admin-edit-event/admin-edit-event-contact-page/admin-edit-event-contact-page";
import { AdminEditEventDetailsPage } from "pages/admin/events/admin-edit-event/admin-edit-event-details-page/admin-edit-event-details-page";
import { AdminEditEventInstructorPage } from "pages/admin/events/admin-edit-event/admin-edit-event-instructor-page/admin-edit-event-instructor-page";
import { AdminEditEventLocationPage } from "pages/admin/events/admin-edit-event/admin-edit-event-location-page/admin-edit-event-location-page";
import { AdminEditEventProductPage } from "pages/admin/events/admin-edit-event/admin-edit-event-product-page/admin-edit-event-product-page";
import { AdminEditEventRegistrationPage } from "pages/admin/events/admin-edit-event/admin-edit-event-registration-page/admin-edit-event-registration-page";
import { AdminEditEventSchedulePage } from "pages/admin/events/admin-edit-event/admin-edit-event-schedule-page/admin-edit-event-schedule-page";
import { AdminEventEnrollmentListPage } from "pages/admin/enrollments/admin-event-enrollment-list-page/admin-event-enrollment-list-page";
import { AdminEventListPage } from "pages/admin/events/admin-events-list-page/admin-event-list-page";
import { AdminLayout } from "layouts/admin/admin-layout/admin-layout";
import { AdminProductEnrollmentPage } from "pages/admin/products/admin-product-enrollment-page/admin-product-enrollment-page";
import { AdminProductListPage } from "pages/admin/products/admin-product-list-page/admin-product-list-page";
import { AdminReportingLayout } from "layouts/admin/reporting-layout/reporting-layout";
import { AdminReportsDownloadsPage } from "./pages/admin/reports/admin-reports-downloads-page/admin-reports-downloads-page";
import { AdminReportsListPage } from "./pages/admin/reports/admin-reports-list-page/admin-reports-list-page";
import { AENApplicationsLogPage } from "pages/admin/aen-applications/log-pages/aen-applications-log-page";
import { AdminReportsSetupPage } from "./pages/admin/reports/admin-reports-setup-page/admin-reports-setup-page";
import { AdminSandboxPage } from "pages/admin/admin-sandbox-page/admin-sandbox-page";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { AenApplicationsInstructor } from "pages/admin/aen-applications/list-pages/aen-applications-instructor";
import { AenApplicationsProvider } from "pages/admin/aen-applications/list-pages/aen-applications-provider";
import { AenInstructorApplicationLayout } from "layouts/admin/aen-application-layout/aen-instructor-application-layout";
import { AenProviderApplicationLayout } from "layouts/admin/aen-application-layout/aen-provider-application-layout";
import { AssessmentPage } from "pages/admin/aen-applications/assessment-page/assessment-page";
import { AuthenticatedLayout } from "layouts/shared/authenticated/authenticated-layout";
import { AzureLoginPage } from "pages/public/azure-login/azure-login-page";
import { AzureLogoutPage } from "pages/public/azure-logout/azure-logout-page";
import { ContractOrganizationLayout } from "layouts/admin/contract-organization-layout/contract-organization-layout";
import { CreateCourseMaterialsPage } from "pages/admin/courses/create-course-materials-page/create-course-materials-page";
import { CreateAssessmentProductMaterialsPage } from "pages/admin/products/create-assessment-product-materials-page/create-assessment-product-materials-page";
import { CreateOnlineProductMaterialsPage } from "pages/admin/products/create-online-product-materials-page/create-online-product-materials-page";
import { EditAssessmentProductMaterialsPage } from "pages/admin/products/edit-assessment-product-materials-page/edit-assessment-product-materials-page";
import { EditCourseDetailsPage } from "pages/admin/courses/edit-course/edit-course-details-page/edit-course-details-page";
import { EditCourseLayout } from "layouts/admin/edit-course-layout/edit-course-layout";
import { EditCourseMaterialsPage } from "pages/admin/courses/edit-course/edit-course-materials-page/edit-course-materials-page";
import { EditCourseVersionListPage } from "pages/admin/courses/edit-course/edit-course-version-list-page/edit-course-version-list-page";
import { EditEventLayout } from "layouts/admin/edit-event-layout/edit-event-layout";
import { EditOnlineProductMaterialsPage } from "pages/admin/products/edit-online-product-materials-page/edit-online-product-materials-page";
import { EditProductVersionListPage } from "pages/admin/products/edit-product-version-list-page/edit-product-version-list-page";
import { EmailManagementLayout } from "layouts/admin/email-management-layout/email-management-layout";
import { ErrorLayout } from "layouts/errors/error-layout/error-layout";
import { EventScheduleLayout } from "layouts/admin/event-schedule-layout/event-schedule-layout";
import { GroupInfoPage } from "pages/admin/user-management/groups/group-info-page/group-info-page";
import { GroupManagementLayout } from "layouts/admin/group-manage-layout/group-management-layout";
import { GroupsAssociatedUsersPage } from "pages/admin/user-management/groups/groups-associated-users-page/groups-associated-users-page";
import { GroupsListPage } from "pages/admin/user-management/groups/groups-list-page/groups-list-page";
import { GroupsNewPage } from "pages/admin/user-management/groups/groups-new-page/groups-new-page";
import { HomePage } from "pages/public/home-page/home-page";
import { IconSampler } from "pages/admin/admin-sandbox-page/icon-sampler/icon-sampler";
import { InstructorDashboardPage } from "pages/instructor/instructor-dashboard-page/instructor-dashboard-page";
import { InstructorENHApprovedToTeachPage } from "./pages/instructor/instructor-education-network-hub/instructor-enh-approved-to-teach-page/instructor-enh-approved-to-teach-page";
import { InstructorENHLayout } from "./layouts/instructor/instructor-education-network-hub-layout/instructor-enh-layout";
import { InstructorENHProfilePage } from "./pages/instructor/instructor-education-network-hub/instructor-enh-profile-page/instructor-enh-profile-page";
import { InstructorENHRequiredTrainingsPage } from "./pages/instructor/instructor-education-network-hub/instructor-enh-required-trainings-page/instructor-enh-required-trainings-page";
import { InstructorENHResourcesPage } from "./pages/instructor/instructor-education-network-hub/instructor-enh-resources-page/instructor-enh-resources-page";
import { LearnerAENTrainingLayout } from "layouts/learner/learner-aen-training-layout/learner-aen-training-layout";
import { LearnerAssessmentPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-assessment-page/learner-assessment-page/learner-assessment-page";
import { LearnerDashboardPage } from "pages/learner/learner-dashboard-page/learner-dashboard-page";
import { LearnerInstructorAssessmentDetailsPage } from "pages/learner/learner-take-a-training/learner-instructor-assessment-page/learner-instructor-assessment-details-page";
import { LearnerLayout } from "layouts/learner/learner-layout/learner-layout";
import { LearnerLearningPage } from "pages/learner/learner-learning-page/learner-learning-page";
import { LearnerOnlineLearningAchievementsPage } from "pages/learner/learner-online-learning/learner-online-learning-achievements-page/learner-online-learning-achievements-page";
import { LearnerOnlineLearningAssessmentResultsPage } from "pages/learner/learner-online-learning/learner-take-a-training-assessments-page/assessment-results-page/learner-online-learning-assessment-results-page";
import { LearnerOnlineLearningContentPage } from "pages/learner/learner-online-learning/learner-online-learning-content-page/learner-online-learning-content-page";
import { LearnerOnlineLearningDetailsPage } from "pages/learner/learner-online-learning/learner-online-learning-details-page/learner-online-learning-details-page";
import { LearnerOnlineLearningEvaluationPage } from "pages/learner/learner-online-learning/learner-online-learning-evaluation-page/learner-online-learning-evaluation-page";
import { LearnerOnlineLearningUnitCourseAssessmentPage } from "pages/learner/learner-online-learning/learner-online-learning-unit-course-assessment-page/learner-online-learning-unit-course-assessment-page";
import { LearnerOnlineLearningUnitCoursePage } from "pages/learner/learner-online-learning/learner-online-learning-unit-course-page/learner-online-learning-unit-course-page";
import { LearnerOnlineLearningUnitPage } from "pages/learner/learner-online-learning/learner-online-learning-unit-page/learner-online-learning-unit-page";
import { LearnerTakeATrainingAchievementsPage } from "pages/learner/learner-take-a-training/learner-take-a-training-achievements-page/learner-take-a-training-achievements-page";
import { LearnerTakeATrainingAssessmentResultsPage } from "pages/learner/learner-take-a-training/learner-take-a-training-assessments-page/assessment-results-page/learner-take-a-training-assessment-results-page";
import { LearnerTakeATrainingAssessmentsPage } from "pages/learner/learner-take-a-training/learner-take-a-training-assessments-page/learner-take-a-training-assessments-page";
import { LearnerTakeATrainingContentPage } from "pages/learner/learner-take-a-training/learner-take-a-training-content-page/learner-take-a-training-content-page";
import { LearnerTakeATrainingDetailsPage } from "pages/learner/learner-take-a-training/learner-take-a-training-details-page/learner-take-a-training-details-page";
import { LearnerTakeATrainingEvaluationPage } from "pages/learner/learner-take-a-training/learner-take-a-training-evaluation-page/learner-take-a-training-evaluation-page";
import { LearnerTrainingLayout } from "layouts/learner/learner-training-layout/learner-training-layout";
import { LearnerTrainingSummaryILTPage } from "./pages/learner/learner-training-summary/learner-training-summary-ilt-page/learner-training-summary-ilt-page";
import { LearnerTrainingSummaryOLLPage } from "./pages/learner/learner-training-summary/learner-training-summary-oll-page/learner-training-summary-oll-page";
import { ManageEventAssessmentResultsPage } from "pages/admin/events/manage-live-virtual-event/manage-event-assessment-results-page/manage-event-assessment-results-page";
import { ManageEventEvaluationPreviewPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-evaluation-page/manage-event-evaluation-preview-page/manage-event-evaluation-preview-page";
import { ManageEventLayout } from "layouts/admin/manage-event/manage-event-layout";
import { ManageLiveVirtualEventAssessmentPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-assessment-page/manage-live-virtual-event-assessment-page";
import { ManageLiveVirtualEventAttendanceDayPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-attendance-day-page/manage-live-virtual-event-attendance-day-page";
import { ManageLiveVirtualEventAttendanceSummaryPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-attendance-summary-page/manage-live-virtual-event-attendance-summary-page";
import { ManageLiveVirtualEventContentPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-content-page/manage-live-virtual-event-content-page";
import { ManageLiveVirtualEventDetailsPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-details-page/manage-live-virtual-event-details-page";
import { ManageLiveVirtualEventEnrollmentPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-enrollment-page/manage-live-virtual-event-enrollment-page";
import { ManageLiveVirtualEventEvaluationsPage } from "pages/admin/events/manage-live-virtual-event/manage-live-virtual-event-evaluation-page/manage-live-virtual-event-evaluation-page";
import { MyCertificatesPage } from "pages/learner/certificates-page/my-certificates-page";
import { MyInProgressLearningAssignmentsPage } from "pages/learner/my-in-progress-learning-assignments-page";
import { MyPastLearningAssignmentsPage } from "pages/learner/my-past-learning-assignments-page";
import { MyTeachingAssignmentsPage } from "./pages/instructor/my-teaching-assignments-page";
import { NfpaSupportDashboardPage } from "pages/nfpa-support/nfpa-support-dashboard-page/nfpa-support-dashboard-page";
import { NoAccessPage } from "pages/errors/no-access-page/no-access-page";
import { NonAdminManageEventLayout } from "layouts/shared/non-admin-manage-event-layout/non-admin-manage-event-layout";
import { NotFoundPage } from "pages/errors/not-found-page/not-found-page";
import { OperatorDashboardPage } from "./pages/operators/operator-dashboard-page/operator-dashboard-page";
import { PlaceholderPage } from "pages/public/placeholder-page/placeholder-page";
import { ProductDetailsPage } from "pages/admin/products/product-details-page/product-details-page";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProfilePage } from "pages/public/profile/profile-page";
import { ProviderDashboardPage } from "./pages/en-provider/provider-dashboard-page/provider-dashboard-page";
import { ProviderENHLayout } from "./layouts/aen-provider/provider-education-network-hub-layout/provider-enh-layout";
import { ProviderENHOrganizationPage } from "./pages/en-provider/provider-education-network-hub/provider-enh-organization-page/provider-enh-organization-page";
import { ProviderENHResourcesPage } from "./pages/en-provider/provider-education-network-hub/provider-enh-resources-page/provider-enh-resources-page";
import { ProviderManagementLayout } from "layouts/admin/provider-manage-layout/provider-management-layout";
import { PublicLayout } from "layouts/public/public-layout/public-layout";
import { RouteMap } from "andculturecode-javascript-react";
import { RouteObject } from "react-router-dom";
import { SendAnEmailPage } from "pages/admin/email-management/send-an-email-page";
import { SupportPage } from "./pages/public/support/support-page";
import { ThirdPartyDashboardInstructorPage } from "./pages/third-party/third-party-dashboard-instructor-page/third-party-dashboard-instructor-page";
import { ThirdPartyDashboardOnlinePage } from "./pages/third-party/third-party-dashboard-online-page/third-party-dashboard-online-page";
import { ThirdPartyLayout } from "./layouts/third-party/third-party-layout/third-party-layout";
import { ThirdPartyOnlineLearningDetailsPage } from "pages/third-party/third-party-online-learning-details-page/third-party-online-learning-details-page";
import { ThirdPartyOnlineLearningEnrollmentPage } from "pages/third-party/third-party-online-learning-enrollment-page/third-party-online-learning-enrollment-page";
import { ThirdPartyTrainingLayout } from "layouts/third-party/third-party-training-layout/third-party-training-layout";
import { UserBasicInfoPage } from "./pages/admin/user-management/users/user-basic-info-page/user-basic-info-page";
import { UserContractsILLDetailPage } from "./pages/admin/user-management/contracts/user-contracts-ill-detail-page/user-contracts-ill-detail-page";
import { UserContractsILLListPage } from "./pages/admin/user-management/contracts/user-contracts-ill-list-page/user-contracts-ill-list-page";
import { UserContractsInfoPage } from "./pages/admin/user-management/contracts/user-contracts-info-page/user-contracts-info-page";
import { UserContractsListPage } from "./pages/admin/user-management/contracts/user-contracts-list-page/user-contracts-list-page";
import { UserContractsNewPage } from "./pages/admin/user-management/contracts/user-contracts-new-page/user-contracts-new-page";
import { UserContractsOLLDetailPage } from "./pages/admin/user-management/contracts/user-contracts-oll-detail-page/user-contracts-oll-detail-page";
import { UserContractsOLLListPage } from "./pages/admin/user-management/contracts/user-contracts-oll-list-page/user-contracts-oll-list-page";
import { UserContractsOLLNewPage } from "./pages/admin/user-management/contracts/user-contracts-oll-new-page/user-contracts-oll-new-page";
import { UserENInstructorPage } from "./pages/admin/user-management/users/user-en-instructor-page/user-en-instructor-page";
import { UserListPage } from "./pages/admin/user-management/users/user-list-page/user-list-page";
import { UserLoginPage } from "pages/public/user-login-page/user-login-page";
import { UserManagementLayout } from "layouts/admin/user-manage-layout/user-management-layout";
import { UserNewPage } from "./pages/admin/user-management/users/user-new-page/user-new-page";
import { UserProviderInfoPage } from "./pages/admin/user-management/providers/user-providers-info-page/user-providers-info-page";
import { UserProvidersAssociatedUsersPage } from "./pages/admin/user-management/providers/user-providers-associated-users-page/user-providers-associated-users-page";
import { UserProvidersListPage } from "./pages/admin/user-management/providers/user-providers-list-page/user-providers-list-page";
import { UserProvidersNewPage } from "./pages/admin/user-management/providers/user-providers-new-page/user-providers-new-page";
import { UserTrainingAssessmentPage } from "./pages/admin/user-management/users/user-training-assessment-page/user-training-assessment-page";
import { UserTrainingDetailPage } from "./pages/admin/user-management/users/user-training-detail-page/user-training-detail-page.";
import { UserTrainingsPage } from "./pages/admin/user-management/users/user-trainings-page/user-trainings-page";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Routes
// -------------------------------------------------------------------------------------------------

const routes: RouteMap = {};

const routeObjects: RouteObject[] = [
    {
        element: <AzureLoginPage />,
        // authRequired: false,
        // component: AzureLoginPage,
        // exact: true,
        path: sitemap.azureLogin,
        // routes: {},
    },
    {
        element: <AzureLogoutPage />,
        // authRequired: false,
        // component: AzureLoginPage,
        // exact: true,
        path: sitemap.azureLogout,
        // routes: {},
    },
    {
        /* Admin Dashboard - Home */
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.dashboard,
                element: <AdminDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.admin.dashboard,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.admin.catalogEnrollmentLog.list,
                element: <AdminCatalogEnrollmentLogPage />,
                handle: {
                    crumb: () => ({
                        name: t("catalogEnrollmentLog"),
                        path: sitemap.admin.catalogEnrollmentLog.list,
                    }),
                },
            },
            {
                path: sitemap.admin.course.list,
                element: <AdminCourseListPage />,
                handle: { crumb: () => ({ name: t("courses"), path: sitemap.admin.dashboard }) },
            },
            {
                path: sitemap.admin.product.list,
                element: <AdminProductListPage />,
                handle: {
                    crumb: () => ({ name: t("products"), path: sitemap.admin.product.list }),
                },
            },
            {
                path: sitemap.test.sandbox,
                element: <AdminSandboxPage />,
            },
            {
                path: sitemap.test.icons,
                element: <IconSampler />,
            },
        ],
    },
    {
        /* Announcements - List */
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.announcements.list,
                element: <AdminAnnouncementsListPage />,
                handle: {
                    crumb: () => ({
                        name: t("announcements"),
                        path: sitemap.admin.announcements.list,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.instructor.teachingAssignments,
                element: <MyTeachingAssignmentsPage />,
                handle: {
                    crumb: () => ({
                        name: t("myTeachingAssignments"),
                        path: sitemap.instructor.teachingAssignments,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.product.enrollments,
                element: <AdminProductEnrollmentPage />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                    }),
                },
            },
        ],
    },
    {
        /* Learner - My Learning */
        element: <LearnerLayout />,
        children: [
            {
                path: sitemap.learner.certificates,
                element: <MyCertificatesPage />,
                handle: {
                    crumb: () => ({
                        name: t("certificates"),
                        path: sitemap.learner.certificates,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.learner.learningAssignments.inProgress,
                element: <MyInProgressLearningAssignmentsPage />,
                handle: {
                    crumb: () => ({
                        name: t("myLearning"),
                        path: sitemap.learner.learningAssignments.inProgress,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.learner.learningAssignments.past,
                element: <MyPastLearningAssignmentsPage />,
                handle: {
                    crumb: () => ({
                        name: t("myLearning"),
                        path: sitemap.learner.learningAssignments.past,
                        homeSlice: false,
                    }),
                },
            },
            {
                handle: {
                    crumb: () => ({
                        name: t("assignments"),
                        path: sitemap.learner.learningAssignments.inProgress,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.learner.training.onlineLearning.summary,
                        element: <LearnerTrainingSummaryOLLPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.summary,
                                homeSlice: false,
                            }),
                        },
                    },
                ],
            },
            {
                handle: {
                    crumb: () => ({
                        name: t("assignments"),
                        path: sitemap.learner.learningAssignments.past,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.learner.training.event.summary,
                        element: <LearnerTrainingSummaryILTPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.summary,
                                homeSlice: false,
                            }),
                        },
                    },
                ],
            },

            /* Online Learning Training */
            {
                element: <LearnerTrainingLayout />,
                handle: {
                    crumb: () => ({
                        name: t("assignments"),
                        path: sitemap.learner.learningAssignments.inProgress,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.learner.training.onlineLearning.details,
                        element: <LearnerOnlineLearningDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.content,
                        element: <LearnerOnlineLearningContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.assessments.results,
                        element: <LearnerOnlineLearningAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.assessments.results,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.units.details,
                        element: <LearnerOnlineLearningUnitPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.units.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.units.courses.details,
                        element: <LearnerOnlineLearningUnitCoursePage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.units.courses.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.units.courses.assessments,
                        element: <LearnerOnlineLearningUnitCourseAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.units.courses
                                    .assessments,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.evaluation,
                        element: <LearnerOnlineLearningEvaluationPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.evaluation,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.onlineLearning.achievements,
                        element: <LearnerOnlineLearningAchievementsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.onlineLearning.achievements,
                            }),
                        },
                    },
                ],
            },
            /* Instructor-Led Training */
            {
                element: <LearnerTrainingLayout />,
                handle: {
                    crumb: () => ({
                        name: t("assignments"),
                        path: sitemap.learner.learningAssignments.inProgress,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.learner.training.event.details,
                        element: <LearnerTakeATrainingDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.event.content,
                        element: <LearnerTakeATrainingContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.event.assessments.list,
                        element: <LearnerTakeATrainingAssessmentsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.assessments.list,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.event.assessments.results,
                        element: <LearnerTakeATrainingAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.assessments.results,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.event.evaluation,
                        element: <LearnerTakeATrainingEvaluationPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.evaluation,
                            }),
                        },
                    },
                    {
                        path: sitemap.learner.training.event.achievements,
                        element: <LearnerTakeATrainingAchievementsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myLearning"),
                                path: sitemap.learner.training.event.achievements,
                            }),
                        },
                    },
                ],
            },
            /* AEN Pending Instructor  */
            {
                element: <LearnerAENTrainingLayout />,
                handle: {
                    crumb: () => ({
                        name: t("assessments"),
                        path: sitemap.instructor.dashboard,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.instructor.aenInstructorAssessment.details,
                        element: <LearnerInstructorAssessmentDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenInstructorAssessment.details,
                            }),
                        },
                    },
                ],
            },
            /* AEN Training  */
            {
                element: <LearnerAENTrainingLayout />,
                handle: {
                    crumb: () => ({
                        name: t("aenHub"),
                        path: sitemap.instructor.educationNetworkHub.requiredTrainings,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.instructor.aenTraining.details,
                        element: <LearnerOnlineLearningDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.aenTraining.content,
                        element: <LearnerOnlineLearningContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.aenTraining.assessments.results,
                        element: <LearnerOnlineLearningAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.assessments.results,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.aenTraining.units.details,
                        element: <LearnerOnlineLearningUnitPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.units.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.aenTraining.units.courses.details,
                        element: <LearnerOnlineLearningUnitCoursePage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.units.courses.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.aenTraining.units.courses.assessments,
                        element: <LearnerOnlineLearningUnitCourseAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.aenTraining.units.courses.assessments,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    {
        /* Admin Dashboard - Home Button */
        element: <AdminLayout />,
        children: [
            /* Courses - Edit */
            {
                element: <EditCourseLayout />,
                handle: {
                    crumb: () => ({
                        name: t("courses"),
                        path: sitemap.admin.course.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            EditCourseDetailsPage.name,
                            EditCourseVersionListPage.name,
                            EditCourseMaterialsPage.name,
                        ],
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.course.edit.details,
                        element: <EditCourseDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("course"),
                                path: sitemap.admin.course.edit.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.course.edit.versionList,
                        element: <EditCourseVersionListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("course"),
                                path: sitemap.admin.course.edit.versionList,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.course.edit.materials.version.create,
                        element: <CreateCourseMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("course"),
                                path: sitemap.admin.course.edit.materials.version.create,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.course.edit.materials.version.edit,
                        element: <EditCourseMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("course"),
                                path: sitemap.admin.course.edit.materials.version.edit,
                            }),
                        },
                    },
                ],
            },
            /* Instructor led Products Create */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditAssessmentProductMaterialsPage.name,
                            CreateAssessmentProductMaterialsPage.name,
                        ],
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.product.create.instructorLed.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.instructorLed.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.create.instructorLed.materials,
                        element: <CreateAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.instructorLed.details,
                            }),
                        },
                    },
                ],
            },
            /* Instructor led Products Edit */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditAssessmentProductMaterialsPage.name,
                            CreateAssessmentProductMaterialsPage.name,
                        ],
                    }),
                },

                children: [
                    {
                        path: sitemap.admin.product.edit.instructorLed.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.instructorLed.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.versionList,
                        element: <EditProductVersionListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.instructorLed.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.instructorLed.materials.version.create,
                        element: <CreateAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.instructorLed.materials.version.edit,
                        element: <EditAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                ],
            },
            /* Online Products Create */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditOnlineProductMaterialsPage.name,
                            CreateOnlineProductMaterialsPage.name,
                        ],
                    }),
                },

                children: [
                    {
                        path: sitemap.admin.product.edit.online.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.online.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.create.online.materials,
                        element: <CreateOnlineProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.online.details,
                            }),
                        },
                    },
                ],
            },
            /* Online Products Edit */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditOnlineProductMaterialsPage.name,
                            CreateOnlineProductMaterialsPage.name,
                        ],
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.product.edit.online.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.online.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.versionList,
                        element: <EditProductVersionListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.online.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.online.materials.version.create,
                        element: <CreateOnlineProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.online.materials.version.edit,
                        element: <EditOnlineProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                ],
            },

            /* Assessment Products Create */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditOnlineProductMaterialsPage.name,
                            CreateOnlineProductMaterialsPage.name,
                        ],
                    }),
                },

                children: [
                    {
                        path: sitemap.admin.product.edit.assessment.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.assessment.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.create.assessment.materials,
                        element: <CreateAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.assessment.details,
                            }),
                        },
                    },
                ],
            },
            /* Approval Products Edit */
            {
                element: <ProductLayout />,
                handle: {
                    crumb: () => ({
                        name: t("products"),
                        path: sitemap.admin.product.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [
                            ProductDetailsPage.name,
                            EditProductVersionListPage.name,
                            EditOnlineProductMaterialsPage.name,
                            CreateOnlineProductMaterialsPage.name,
                        ],
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.product.edit.online.details,
                        element: <ProductDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.online.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.versionList,
                        element: <EditProductVersionListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.assessment.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.assessment.materials.version.create,
                        element: <CreateAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.product.edit.assessment.materials.version.edit,
                        element: <EditAssessmentProductMaterialsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("products"),
                                path: sitemap.admin.product.edit.versionList,
                            }),
                        },
                    },
                ],
            },
            {
                path: sitemap.admin.aenResources.providers.list,
                element: <AdminAenResourcesListPage />,
                handle: {
                    crumb: () => ({
                        name: t("aenResources"),
                        path: sitemap.admin.aenResources.providers.list,
                    }),
                },
            },
            {
                path: sitemap.admin.aenResources.instructors.list,
                element: <AdminAenResourcesListPage />,
                handle: {
                    crumb: () => ({
                        name: t("aenResources"),
                        path: sitemap.admin.aenResources.instructors.list,
                    }),
                },
            },
        ],
    },
    {
        /* Enrollment - List */
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.enrollment.event,
                element: <AdminEventEnrollmentListPage />,
                handle: {
                    crumb: () => ({
                        name: t("enrollmentsForAEvent"),
                        path: sitemap.admin.enrollment.event,
                    }),
                },
            },
        ],
    },
    /* Email Management */
    {
        element: <AdminLayout />,
        children: [
            {
                element: <EmailManagementLayout />,
                children: [
                    {
                        path: sitemap.admin.communications.sendAnEmail,
                        element: <SendAnEmailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("emailManager"),
                                path: sitemap.admin.communications.sendAnEmail,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    /* User Management - User List without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.userManagement.users.list,
                element: <UserListPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.users.list,
                    }),
                },
            },
        ],
    },
    /* User Management - With Breadcrumb */
    {
        element: <AdminLayout />,
        handle: {
            crumb: () => ({
                name: t("users"),
                path: sitemap.admin.userManagement.users.list,
                homeSlice: true,
            }),
        },
        children: [
            {
                path: sitemap.admin.userManagement.users.new,
                element: <UserNewPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.users.new,
                    }),
                },
            },
            {
                element: <UserManagementLayout />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.users.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.userManagement.users.info.default,
                        element: <UserBasicInfoPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.users.info.default,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.users.trainings.default,
                        element: <UserTrainingsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.users.trainings.default,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.users.trainings.overview,
                        element: <UserTrainingDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.users.trainings.overview,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.users.trainings.assessment,
                        element: <UserTrainingAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.users.trainings.assessment,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.users.instructor.default,
                        element: <UserENInstructorPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.users.instructor.default,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    /* User Management - Providers List without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.userManagement.enProviders.list,
                element: <UserProvidersListPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.enProviders.list,
                    }),
                },
            },
        ],
    },

    /* AEN Provider Applications with Breadcrumbs */
    {
        element: <AdminLayout />,
        handle: {
            crumb: () => ({
                name: t("aenApplications"),
                path: sitemap.admin.aenApplications.providers.list,
                homeSlice: true,
            }),
        },
        children: [
            {
                element: <AenProviderApplicationLayout />,
                handle: {
                    crumb: () => ({
                        name: t("aenApplications"),
                        path: sitemap.admin.aenApplications.providers.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.aenApplications.providers.detail,
                        element: <AenProviderApplicationDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.providers.detail,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.providers.detail,
                        element: <AenProviderApplicationDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.providers.detail,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.providers.files,
                        element: (
                            <AENApplicationsFilePage
                                applicationType={AenApplicationType.Provider}
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.providers.files,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.providers.logs,
                        element: (
                            <AENApplicationsLogPage applicationType={AenApplicationType.Provider} />
                        ),
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.providers.detail,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* AEN Provider Applications without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.aenApplications.providers.list,
                element: <AenApplicationsProvider />,
                handle: {
                    crumb: () => ({
                        name: t("aenApplications"),
                        path: sitemap.admin.aenApplications.providers.list,
                    }),
                },
            },
        ],
    },

    /* AEN Instructor Applications with Breadcrumbs */
    {
        element: <AdminLayout />,
        handle: {
            crumb: () => ({
                name: t("aenApplications"),
                path: sitemap.admin.aenApplications.instructors.list,
                homeSlice: true,
            }),
        },
        children: [
            {
                element: <AenInstructorApplicationLayout />,
                handle: {
                    crumb: () => ({
                        name: t("aenApplications"),
                        path: sitemap.admin.aenApplications.instructors.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.aenApplications.instructors.detail,
                        element: <AenInstructorApplicationDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.instructors.detail,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.instructors.files,
                        element: (
                            <AENApplicationsFilePage
                                applicationType={AenApplicationType.Instructor}
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.instructors.files,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.instructors.assessments,
                        element: <AssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.instructors.assessments,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.aenApplications.instructors.logs,
                        element: (
                            <AENApplicationsLogPage
                                applicationType={AenApplicationType.Instructor}
                            />
                        ),
                        handle: {
                            crumb: () => ({
                                name: t("aenApplications"),
                                path: sitemap.admin.aenApplications.instructors.detail,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* AEN Provider Applications without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.aenApplications.instructors.list,
                element: <AenApplicationsInstructor />,
                handle: {
                    crumb: () => ({
                        name: t("aenApplications"),
                        path: sitemap.admin.aenApplications.instructors.list,
                    }),
                },
            },
        ],
    },

    /* User Management - Providers with Breadcrumb */
    {
        element: <AdminLayout />,
        handle: {
            crumb: () => ({
                name: t("enProviders"),
                path: sitemap.admin.userManagement.enProviders.list,
                homeSlice: true,
            }),
        },
        children: [
            {
                path: sitemap.admin.userManagement.enProviders.new,
                element: <UserProvidersNewPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.enProviders.new,
                    }),
                },
            },
            {
                element: <ProviderManagementLayout />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.users.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.userManagement.enProviders.info,
                        element: <UserProviderInfoPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.enProviders.info,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.enProviders.associatedUsers,
                        element: <UserProvidersAssociatedUsersPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.enProviders.associatedUsers,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.enProviders.new,
                        element: <UserProvidersNewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.enProviders.new,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* Group Management - Groups List without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.userManagement.groups.list,
                element: <GroupsListPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.groups.list,
                    }),
                },
            },
            {
                path: sitemap.admin.userManagement.groups.new,
                element: <GroupsNewPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.groups.new,
                    }),
                },
            },
        ],
    },

    /* Group Management - Group Details With Breadcrumb to List */
    {
        element: <AdminLayout />,
        children: [
            {
                element: <GroupManagementLayout />,
                handle: {
                    crumb: () => ({
                        name: t("groups"),
                        path: sitemap.admin.userManagement.groups.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.userManagement.groups.info,
                        element: <GroupInfoPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.groups.info,
                            }),
                        },
                    },

                    {
                        path: sitemap.admin.userManagement.groups.members,
                        element: <GroupsAssociatedUsersPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.groups.members,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* User Management - Contract Organizations List Without Breadcrumb */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.userManagement.contracts.list,
                element: <UserContractsListPage />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.admin.userManagement.contracts.list,
                    }),
                },
            },
        ],
    },

    /* User Management - Contract Organizations Details With Breadcrumb to List */
    {
        element: <AdminLayout />,
        children: [
            {
                element: <ContractOrganizationLayout />,
                handle: {
                    crumb: () => ({
                        name: t("contractOrganizations"),
                        path: sitemap.admin.userManagement.contracts.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.userManagement.contracts.info,
                        element: <UserContractsInfoPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.info,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.new,
                        element: <UserContractsNewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.new,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.onlineLearning.list,
                        element: <UserContractsOLLListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.onlineLearning.list,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.onlineLearning.new,
                        element: <UserContractsOLLNewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.onlineLearning.new,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.onlineLearning.detail,
                        element: <UserContractsOLLDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.onlineLearning.detail,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.instructorLed.list,
                        element: <UserContractsILLListPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.instructorLed.list,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.userManagement.contracts.instructorLed.detail,
                        element: <UserContractsILLDetailPage />,
                        handle: {
                            crumb: () => ({
                                name: t("userManagement"),
                                path: sitemap.admin.userManagement.contracts.instructorLed.detail,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    {
        /* Reports*/
        element: <AdminReportingLayout />,
        children: [
            {
                path: sitemap.admin.reports.setup,
                element: <AdminReportsSetupPage />,
                handle: {
                    crumb: () => ({
                        name: t("reports"),
                        path: sitemap.admin.reports.setup,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.admin.reports.list,
                element: <AdminReportsListPage />,
                handle: {
                    crumb: () => ({
                        name: t("reports"),
                        path: sitemap.admin.reports.list,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.admin.reports.downloads,
                element: <AdminReportsDownloadsPage />,
                handle: {
                    crumb: () => ({
                        name: t("reports"),
                        path: sitemap.admin.reports.downloads,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    {
        /* Events */
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.admin.event.list,
                element: <AdminEventListPage />,
                handle: {
                    crumb: () => ({
                        name: t("events"),
                        path: sitemap.admin.event.list,
                        homeSlice: false,
                    }),
                },
            },
            {
                element: <EditEventLayout />,
                handle: {
                    crumb: () => ({
                        name: t("events"),
                        path: sitemap.admin.event.list,
                        homeSlice: true,
                        requireComponentsToResolveBreadcrumbNavigation: [EditEventLayout.name],
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.event.edit.details,
                        element: <AdminEditEventDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.contact,
                        element: <AdminEditEventContactPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.contact,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.contact,
                        element: <AdminEditEventContactPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.contact,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.product,
                        element: <AdminEditEventProductPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.product,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.location,
                        element: <AdminEditEventLocationPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.location,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.registration,
                        element: <AdminEditEventRegistrationPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.registration,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.edit.instructor,
                        element: <AdminEditEventInstructorPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.edit.instructor,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.changeLog,
                        element: <AdminEditEventChangeLogPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.changeLog,
                            }),
                        },
                    },

                    {
                        element: <EventScheduleLayout />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.list,
                                homeSlice: true,
                            }),
                        },
                        children: [
                            {
                                path: sitemap.admin.event.edit.schedule,
                                element: <AdminEditEventSchedulePage />,
                                handle: {
                                    crumb: () => ({
                                        name: t("events"),
                                        path: sitemap.admin.event.edit.schedule,
                                    }),
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    /* Admin Event View */
    {
        element: <LearnerLayout />,
        children: [
            {
                element: <ManageEventLayout />,
                handle: {
                    crumb: () => ({
                        name: t("events"),
                        path: sitemap.admin.event.list,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.admin.event.manage.details,
                        element: <ManageLiveVirtualEventDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.content,
                        element: <ManageLiveVirtualEventContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.enrollment,
                        element: <ManageLiveVirtualEventEnrollmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.enrollment,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.attendance.summary,
                        element: <ManageLiveVirtualEventAttendanceSummaryPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.attendance.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.attendance.day,
                        element: <ManageLiveVirtualEventAttendanceDayPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.attendance.day,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.assessments.assessmentResults,
                        element: <ManageEventAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.assessments.assessmentResults,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.assessments.learnerAssessment,
                        element: <LearnerAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.assessments.learnerAssessment,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.assessments.assessments,
                        element: <ManageLiveVirtualEventAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.assessments.assessments,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.evaluations.summary,
                        element: <ManageLiveVirtualEventEvaluationsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.evaluations.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.admin.event.manage.evaluations.preview,
                        element: <ManageEventEvaluationPreviewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("events"),
                                path: sitemap.admin.event.manage.evaluations.preview,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    /* Instructor Event View */
    {
        element: <LearnerLayout />,
        children: [
            {
                element: <NonAdminManageEventLayout />,
                handle: {
                    crumb: () => ({
                        name: t("assignments"),
                        path: sitemap.instructor.teachingAssignments,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.instructor.event.details,
                        element: <ManageLiveVirtualEventDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.content,
                        element: <ManageLiveVirtualEventContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.enrollment,
                        element: <ManageLiveVirtualEventEnrollmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.enrollment,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.attendance.summary,
                        element: <ManageLiveVirtualEventAttendanceSummaryPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.attendance.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.attendance.day,
                        element: <ManageLiveVirtualEventAttendanceDayPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.attendance.day,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.assessments.assessmentResults,
                        element: <ManageEventAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.assessments.assessmentResults,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.assessments.learnerAssessment,
                        element: <LearnerAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.assessments.learnerAssessment,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.assessments.assessments,
                        element: <ManageLiveVirtualEventAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.assessments.assessments,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.evaluations.summary,
                        element: <ManageLiveVirtualEventEvaluationsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.evaluations.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.event.evaluations.preview,
                        element: <ManageEventEvaluationPreviewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("myTeachingAssignments"),
                                path: sitemap.instructor.event.evaluations.preview,
                            }),
                        },
                    },
                ],
            },
            {
                path: sitemap.instructor.dashboard,
                element: <InstructorDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.instructor.dashboard,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    {
        /* Learner Dashboard - Home Button */
        element: <LearnerLayout />,
        children: [
            {
                path: sitemap.learner.dashboard,
                element: <LearnerDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.learner.dashboard,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.learner.training.event.list,
                element: <LearnerLearningPage />,
                handle: {
                    crumb: () => ({
                        name: t("learning"),
                        path: sitemap.learner.training.event.list,
                        homeSlice: false,
                    }),
                },
            },
            {
                element: <InstructorENHLayout />,
                children: [
                    {
                        path: sitemap.instructor.educationNetworkHub.requiredTrainings,
                        element: <InstructorENHRequiredTrainingsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.educationNetworkHub.requiredTrainings,
                                homeSlice: false,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.educationNetworkHub.approvedToTeach,
                        element: <InstructorENHApprovedToTeachPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.educationNetworkHub.approvedToTeach,
                                homeSlice: false,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.educationNetworkHub.resources,
                        element: <InstructorENHResourcesPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.educationNetworkHub.resources,
                                homeSlice: false,
                            }),
                        },
                    },
                    {
                        path: sitemap.instructor.educationNetworkHub.profile,
                        element: <InstructorENHProfilePage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.instructor.educationNetworkHub.profile,
                                homeSlice: false,
                            }),
                        },
                    },
                ],
            },
        ],
    },
    /* Third Party */
    {
        element: <ThirdPartyLayout />,
        children: [
            {
                path: sitemap.thirdPartyProvider.dashboard.online,
                element: <ThirdPartyDashboardOnlinePage />,
                handle: {
                    crumb: () => ({
                        name: t("clientTrainingAdmin"),
                        path: sitemap.thirdPartyProvider.dashboard,
                    }),
                },
            },
            {
                path: sitemap.thirdPartyProvider.dashboard.instructor,
                element: <ThirdPartyDashboardInstructorPage />,
                handle: {
                    crumb: () => ({
                        name: t("clientTrainingAdmin"),
                        path: sitemap.thirdPartyProvider.dashboard.instructor,
                    }),
                },
            },
        ],
    },

    /* 3rd Party Provider Oll Training View */
    {
        element: <ThirdPartyLayout />,
        children: [
            {
                element: <ThirdPartyTrainingLayout />,
                handle: {
                    crumb: () => ({
                        name: t("onlineLearning"),
                        path: sitemap.thirdPartyProvider.dashboard.online,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.thirdPartyProvider.onlineLearning.details,
                        element: <ThirdPartyOnlineLearningDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.learner.training.onlineLearning.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.onlineLearning.enrollment,
                        element: <ThirdPartyOnlineLearningEnrollmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.onlineLearning.enrollment,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* 3rd Party Provider Event View */
    {
        element: <LearnerLayout />,
        children: [
            {
                element: <NonAdminManageEventLayout />,
                handle: {
                    crumb: () => ({
                        name: t("instructorLed"),
                        path: sitemap.thirdPartyProvider.dashboard.instructor,
                        homeSlice: true,
                    }),
                },
                children: [
                    {
                        path: sitemap.thirdPartyProvider.event.details,
                        element: <ManageLiveVirtualEventDetailsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.details,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.content,
                        element: <ManageLiveVirtualEventContentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.content,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.enrollment,
                        element: <ManageLiveVirtualEventEnrollmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.enrollment,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.attendance.summary,
                        element: <ManageLiveVirtualEventAttendanceSummaryPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.admin.event.manage.attendance.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.attendance.day,
                        element: <ManageLiveVirtualEventAttendanceDayPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.admin.event.manage.attendance.day,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.assessments.assessmentResults,
                        element: <ManageEventAssessmentResultsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.assessments
                                    .assessmentResults,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.assessments.learnerAssessment,
                        element: <LearnerAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.assessments
                                    .learnerAssessment,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.assessments.assessments,
                        element: <ManageLiveVirtualEventAssessmentPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.assessments.assessments,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.evaluations.summary,
                        element: <ManageLiveVirtualEventEvaluationsPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.evaluations.summary,
                            }),
                        },
                    },
                    {
                        path: sitemap.thirdPartyProvider.event.evaluations.preview,
                        element: <ManageEventEvaluationPreviewPage />,
                        handle: {
                            crumb: () => ({
                                name: t("trainingManager"),
                                path: sitemap.thirdPartyProvider.event.evaluations.preview,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* AEN Provider */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.aenProvider.dashboard,
                element: <ProviderDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.aenProvider.dashboard,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.aenProvider.productCatalog.list,
                element: <AENProductCatalogPage />,
                handle: {
                    crumb: () => ({
                        name: t("aenProductCatalog"),
                        path: sitemap.aenProvider.productCatalog.list,
                        homeSlice: false,
                    }),
                },
            },
            {
                element: <ProviderENHLayout />,
                children: [
                    {
                        path: sitemap.aenProvider.educationNetworkHub.organization,
                        element: <ProviderENHOrganizationPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.aenProvider.educationNetworkHub.organization,
                                homeSlice: false,
                            }),
                        },
                    },
                    {
                        path: sitemap.aenProvider.educationNetworkHub.resources,
                        element: <ProviderENHResourcesPage />,
                        handle: {
                            crumb: () => ({
                                name: t("educationNetworkHub"),
                                path: sitemap.aenProvider.educationNetworkHub.resources,
                                homeSlice: false,
                            }),
                        },
                    },
                ],
            },
        ],
    },

    /* NFPA Operators */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.nfpaOperators.dashboard,
                element: <OperatorDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.nfpaOperators.dashboard,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    /* NFPA Support */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.nfpaSupport.dashboard,
                element: <NfpaSupportDashboardPage />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.nfpaSupport.dashboard,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    /* Reviewer */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.reviewer.dashboard,
                element: <AenApplicationsInstructor />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.reviewer.dashboard,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
    {
        element: <PublicLayout />,
        children: [
            {
                path: sitemap.root,
                element: <HomePage />,
            },
            {
                path: sitemap.public.userLogin,
                element: <UserLoginPage />,
            },
        ],
    },
    {
        element: <AuthenticatedLayout />,
        children: [
            {
                path: sitemap.authenticated.profile,
                element: <ProfilePage />,
                handle: {
                    crumb: () => ({
                        name: t("myProfile"),
                        path: sitemap.authenticated.profile,
                    }),
                },
            },
            {
                path: sitemap.authenticated.support,
                element: <SupportPage />,
                handle: {
                    crumb: () => ({
                        name: t("support"),
                        path: sitemap.authenticated.support,
                    }),
                },
            },
        ],
    },
    {
        element: <ErrorLayout />,
        children: [
            {
                path: "/*",
                element: <NotFoundPage />,
            },
            {
                element: <NoAccessPage />,
                path: sitemap.public.noAccess,
            },
        ],
    },
    /* Learner Take A Training */

    /* Throwaway Routes for Navigation */
    {
        element: <AdminLayout />,
        children: [
            {
                path: sitemap.test.placeholder.achievements,
                element: <PlaceholderPage title={t("achievements")} />,
                handle: {
                    crumb: () => ({
                        name: t("achievements"),
                        path: sitemap.test.placeholder.achievements,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.announcements,
                element: <PlaceholderPage title={t("announcements")} />,
                handle: {
                    crumb: () => ({
                        name: t("announcements"),
                        path: sitemap.test.placeholder.announcements,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.community,
                element: <a href="https://community.nfpa.org/"></a>, // eslint-disable-line jsx-a11y/anchor-has-content
                handle: {
                    crumb: () => ({
                        name: t("community"),
                        path: sitemap.test.placeholder.community,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.organization,
                element: <PlaceholderPage title={t("organization")} />,
                handle: {
                    crumb: () => ({
                        name: t("organization"),
                        path: sitemap.test.placeholder.organization,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.profile,
                element: <PlaceholderPage title={t("profile")} />,
                handle: {
                    crumb: () => ({
                        name: t("profile"),
                        path: sitemap.test.placeholder.profile,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.reporting,
                element: <PlaceholderPage title={t("reports")} />,
                handle: {
                    crumb: () => ({
                        name: t("reports"),
                        path: sitemap.test.placeholder.reporting,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.test.placeholder.userManagement,
                element: <PlaceholderPage title={t("userManagement")} />,
                handle: {
                    crumb: () => ({
                        name: t("userManagement"),
                        path: sitemap.test.placeholder.userManagement,
                        homeSlice: false,
                    }),
                },
            },
            {
                path: sitemap.nfpaOperators.dashboard,
                element: <PlaceholderPage title={t("dashboard")} />,
                handle: {
                    crumb: () => ({
                        name: t("dashboard"),
                        path: sitemap.nfpaOperators.dashboard,
                        homeSlice: false,
                    }),
                },
            },
        ],
    },
];

// #endregion Routes

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { routes, routeObjects };

// #endregion Exports
