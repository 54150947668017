import React, { useMemo } from "react";
import { ContractEventEnrollmentManager } from "components/enrollments/contract-enrollment-manager/contract-event-enrollment-manager";
import { NumberUtils } from "utilities/number-utils";
import { useEvent } from "utilities/contexts/use-event-context";
import { useParams } from "react-router-dom";
import { EventEnrollmentManager } from "components/enrollments/event-enrollment-manager/event-enrollment-manager";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import "./manage-live-virtual-event-enrollment-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "manage-live-virtual-event-enrollment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventEnrollmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventEnrollmentPage: React.FC<ManageLiveVirtualEventEnrollmentPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventEnrollmentPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { id: eventIdParam } = useParams();
        const { record: event } = useEvent();
        const productId = useMemo(() => event?.productId ?? 0, [event?.productId]);
        const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
        const eventProductVersionId = useMemo(
            () => event?.productVersionId ?? 0,
            [event?.productVersionId]
        );

        return (
            <div className={CSS_CLASS_NAME}>
                {event.contract?.id != null && event.contract.id > 0 && (
                    <ContractEventEnrollmentManager
                        contractId={event.contract.id}
                        eventId={eventId}
                        productId={productId}
                        eventProductVersionId={eventProductVersionId!}
                    />
                )}
                {event.contract?.id == null && (
                    <EventEnrollmentManager
                        eventId={eventId}
                        productId={productId}
                        eventProductVersionId={eventProductVersionId!}
                    />
                )}
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventEnrollmentPage };

// #endregion Exports
