import { EnrollmentRecord } from "./enrollment-record";
import { EventEnrollmentSummary } from "models/interfaces/enrollments/event-enrollment-summary";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "../users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventEnrollmentSummary = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    status: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    user: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventEnrollmentSummaryRecord extends Record(defaultValues) implements EventEnrollmentSummary {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventEnrollmentSummary>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EventEnrollmentSummary properties, create a new EventEnrollmentSummaryRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventEnrollmentSummary>} values The values to overwrite on this instance.
     * @returns An EventEnrollmentSummaryRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventEnrollmentSummary>): EventEnrollmentSummaryRecord {
        return new EventEnrollmentSummaryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventEnrollmentSummaryRecord };

// #endregion Exports
