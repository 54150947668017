import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint: string = "users";
const resourceType: typeof InstructorApplicationRecord = InstructorApplicationRecord;
const resourceEndpoint: string = `${baseEndpoint}/:id/instructorapplications/active`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserInstructorApplicationPathParams {
    id: number;
}

export interface UserInstructorApplicationQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserInstructorApplicationService = {
    get: ServiceFactory.get<InstructorApplicationRecord, UserInstructorApplicationPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGet: ServiceHookFactory.useGet<
        InstructorApplicationRecord,
        UserInstructorApplicationPathParams,
        UserInstructorApplicationQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserInstructorApplicationService };

// #endregion Exports
