import axios from "axios";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import {
    ServiceFactory,
    ServiceHookFactory,
    useCancellablePromise,
} from "andculturecode-javascript-react";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "productversions";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const activateEndpoint: string = `${baseEndpoint}/:id/activate`;
const resourceType: typeof ProductVersionRecord = ProductVersionRecord;
const getLatestResourceEndpoint: string = `${baseEndpoint}/:id/latest`;
const getProductVersionZeroEndpoint: string = `${baseEndpoint}/:id/versionzero`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------
export interface PatchProductVersionServiceData {
    changeLogDescription: string;
    applyToInProgressLearners: boolean;
}

type PatchProductVersionService = (
    params: PatchProductVersionServiceParams,
    data: PatchProductVersionServiceData
) => Promise<ServiceResponse<ProductVersionRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

export enum PatchRoutes {
    Activate = "activate",
}

// #endregion enum

export interface ArchiveProductVersionServiceParams {
    id: number;
}

export interface GetProductVersionPathParams {
    id: number;
}

export interface GetProductVersionQueryParams {
    includeContent?: boolean;
    includeEvaluationTemplate?: boolean;
    includeProductScormPackage?: boolean;
    includeUnit?: boolean;
    includeEnrollmentCounts?: boolean;
}

export interface GetProductVersionZeroPathParams {
    id: number;
}

export interface GetProductVersionZeroQueryParams {}

export interface GetLatestProductVersionPathParams {
    id: number;
}
export interface GetLatestProductVersionQueryParams {
    includeContent?: boolean;
    includeScormPackage?: boolean;
    includeArchive?: boolean;
    includeUnit?: boolean;
}

export interface ListProductVersionsQueryParams {
    includeCreatedBy?: boolean;
    includeEnrollmentCounts?: boolean;
    includeLastModifiedBy?: boolean;
    includeProductScormPackage?: boolean;
    productId?: number;
    skip?: number;
    take?: number;
}

export interface PatchProductVersionServiceParams {
    id: number;
    patchRoute: PatchRoutes;
}

export interface PatchProductVersionServiceData {
    changeLogDescription: string;
    applyToInProgressLearners: boolean;
}

export interface UpdateProductVersionPathParams {
    id: number;
    changeLogDescription?: string;
}

export interface CountProductVersionsQueryParams {
    courseId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductVersionService = {
    /**
     * Create a Product
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update a Product
     */
    update: ServiceFactory.update<ProductVersionRecord, UpdateProductVersionPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        ProductVersionRecord,
        GetProductVersionPathParams,
        GetProductVersionQueryParams
    >(resourceType, resourceEndpoint),

    useGetLatest: ServiceHookFactory.useGet<
        ProductVersionRecord,
        GetLatestProductVersionPathParams,
        GetLatestProductVersionQueryParams
    >(resourceType, getLatestResourceEndpoint),

    useGetProductVersionZero: ServiceHookFactory.useGet<
        ProductVersionRecord,
        GetProductVersionZeroPathParams,
        GetProductVersionZeroQueryParams
    >(resourceType, getProductVersionZeroEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ProductVersionRecord, ListProductVersionsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for activate service patch call
     */
    useActivate: (): { activate: PatchProductVersionService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchProductVersionService = (
            params: PatchProductVersionServiceParams,
            data: PatchProductVersionServiceData
        ): Promise<ServiceResponse<ProductVersionRecord>> => {
            const endPointWithPatchRouteReplaced = activateEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, data)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: PatchProductVersionServiceParams,
            data: PatchProductVersionServiceData
        ): Promise<ServiceResponse<ProductVersionRecord>> {
            return cancellablePromise(servicePatch(params, data)) as Promise<
                ServiceResponse<ProductVersionRecord>
            >;
        }

        return { activate: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<ProductVersionRecord, UpdateProductVersionPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductVersionService };

// #endregion Exports
