import axios from "axios";
import { BulkContractEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-contract-enrollment-summary-record";
import { CreateBulkEnrollmentDto } from "models/interfaces/enrollments/create-bulk-enrollment-dto";
import { CreateEnrollmentDto } from "models/interfaces/enrollments/create-enrollment-dto";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "iltContracts/:contractId/enrollments";
const resourceType: typeof EnrollmentRecord = EnrollmentRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateIltContractEnrollmentService = (
    contractId: number,
    params: CreateEnrollmentDto
) => Promise<ServiceResponse<EnrollmentRecord>>;

type CreateBulkIltContractEnrollmentService = (
    contractId: number,
    params: CreateBulkEnrollmentDto
) => Promise<ServiceResponse<BulkContractEnrollmentSummaryRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const IltContractEnrollmentService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateIltContractEnrollmentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateIltContractEnrollmentService = (
            contractId: number,
            params: CreateEnrollmentDto
        ): Promise<ServiceResponse<EnrollmentRecord>> => {
            const endPointWithPathIdsReplaced = baseEndpoint.replace(
                ":contractId",
                contractId.toString()
            );

            return axios
                .post(endPointWithPathIdsReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            contractId: number,
            params: CreateEnrollmentDto
        ): Promise<ServiceResponse<EnrollmentRecord>> {
            return cancellablePromise(serviceCreate(contractId, params)) as Promise<
                ServiceResponse<EnrollmentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useBulkCreate: (): { create: CreateBulkIltContractEnrollmentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateBulkIltContractEnrollmentService = (
            contractId: number,
            params: CreateBulkEnrollmentDto
        ): Promise<ServiceResponse<BulkContractEnrollmentSummaryRecord>> => {
            const endPointWithPathIdsReplaced = baseEndpoint.replace(
                ":contractId",
                contractId.toString()
            );

            return axios
                .post(`${endPointWithPathIdsReplaced}/bulkUpload`, params)
                .then((r) => ServiceUtils.mapAxiosResponse(BulkContractEnrollmentSummaryRecord, r));
        };

        function create(
            contractId: number,
            params: CreateBulkEnrollmentDto
        ): Promise<ServiceResponse<BulkContractEnrollmentSummaryRecord>> {
            return cancellablePromise(serviceCreate(contractId, params)) as Promise<
                ServiceResponse<BulkContractEnrollmentSummaryRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { IltContractEnrollmentService };

// #endregion Exports
