import { ServiceHookFactory } from "andculturecode-javascript-react";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";

// -------------------------------------------------------------------------------------------------
// #region Get
// -------------------------------------------------------------------------------------------------

export interface GetInstructorProfilePathParams {
    id: number;
}

export interface GetInstructorProfileQueryParams {
    includeLanguages?: boolean;
    includeTrainingTypes?: boolean;
    includeTravelTypes?: boolean;
}

// #endregion Get

// -------------------------------------------------------------------------------------------------
// #region List
// -------------------------------------------------------------------------------------------------

export interface ListInstructorProfilesQueryParams {
    includeAvatarFile?: boolean;
    includeLanguages?: boolean;
    includeTrainingTypes?: boolean;
    includeTravelTypes?: boolean;
    includeResumeFile?: boolean;
    userId?: number;
}

// #endregion List

// -------------------------------------------------------------------------------------------------
// #region Update
// -------------------------------------------------------------------------------------------------

export interface UpdateInstructorProfilePathParams {
    id: number;
}

// #endregion Update

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "instructorprofiles";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType = InstructorProfileRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const InstructorProfileService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        InstructorProfileRecord,
        GetInstructorProfilePathParams,
        GetInstructorProfileQueryParams
    >(resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useList<InstructorProfileRecord, ListInstructorProfilesQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        InstructorProfileRecord,
        UpdateInstructorProfilePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { InstructorProfileService };

// #endregion Exports
