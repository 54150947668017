import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { EncodingType } from "models/enumerations/encoding/encoding-type";
import { FileRecord } from "models/view-models/files/file-record";
import { StorageConfigurationRecord } from "models/view-models/storage/storage-configuration-record";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { FileService } from "utilities/services/files/file-service";
import { StorageService } from "utilities/services/storage/storage-service";
import { StringUtils } from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

const uploadFile = async (file: File, storageContainer: StorageContainers): Promise<FileRecord> => {
    if (file == null || StringUtils.isEmpty(storageContainer)) {
        throw new Error();
    }

    const sanitizedFileName = sanitizeFileName(file.name);
    const uniqueFile = createFileWithUniqueName(file, sanitizedFileName);

    var uploadToBlobResult = await uploadFileToBlob(uniqueFile, storageContainer);

    if (!uploadToBlobResult) {
        throw new Error();
    }

    return await createFileRecord(
        uniqueFile.type,
        sanitizedFileName,
        uniqueFile.name,
        storageContainer
    );
};

const getOS = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Win") !== -1) return EncodingType.Windows1252;
    if (userAgent.indexOf("Mac") !== -1) return EncodingType.UTF8;
    return EncodingType.UTF8;
};

// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Helper Functions
// -----------------------------------------------------------------------------------------

const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
    const blobClient = containerClient.getBlockBlobClient(file.name);

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    await blobClient.uploadData(file, options);
};

const createFileWithUniqueName = (originalFile: File, filename?: string) => {
    const sanitizedFileName = filename ? filename : sanitizeFileName(originalFile.name);

    return new File([originalFile], Date.now() + "_" + sanitizedFileName, {
        type: originalFile.type,
    });
};

const sanitizeFileName = (fileName: string): string => fileName.replaceAll(/\s+/g, "_");

const createFileRecord = async (
    contentType: string,
    fileName: string,
    relativeProviderPath: string,
    storageContainer: StorageContainers
): Promise<FileRecord> => {
    const createFileResponse = await FileService.create(
        new FileRecord({
            contentType: contentType,
            fileName: fileName,
            relativeProviderPath: relativeProviderPath,
            storageContainer: storageContainer,
        })
    );

    const createFileResult = createFileResponse?.result;

    if (createFileResult?.resultObject == null || createFileResult.hasErrors()) {
        throw new Error(
            createFileResult?.errors?.map((err) => err?.message).join(", ") ??
                "Unknown error occurred."
        );
    }

    return createFileResult.resultObject;
};

const getConfiguration = async (
    storageContainer: StorageContainers
): Promise<StorageConfigurationRecord> => {
    var getConfigurationResponse = await StorageService.getConfiguration(
        { container: storageContainer },
        { readOnly: false }
    );

    var configurationResult = getConfigurationResponse?.result;

    if (configurationResult?.resultObject == null || configurationResult.hasErrors()) {
        throw new Error();
    }

    return configurationResult.resultObject;
};

const getBlobServiceUrl = async (storageContainer: StorageContainers): Promise<string> => {
    const { accountName, token } = await getConfiguration(storageContainer);
    return `https://${accountName}.blob.core.windows.net/?${token}`;
};

const uploadFileToBlob = async (
    file: File,
    storageContainer: StorageContainers
): Promise<boolean> => {
    try {
        const blobServiceUrl: string = await getBlobServiceUrl(storageContainer);
        const blobService = new BlobServiceClient(blobServiceUrl);

        // get Container - full public read access
        const containerClient: ContainerClient = blobService.getContainerClient(storageContainer);

        await createBlobInContainer(containerClient, file);

        return true;
    } catch {
        throw new Error();
    }
};

// #endregion Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const FileUtils = {
    fileUrl: (fileId: number) => `/api/v1/files/${fileId}`,
    getOS: getOS,
    uploadFile: uploadFile,
};

export { FileUtils };

// #endregion Exports
