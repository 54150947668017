import { Enrollment } from "models/interfaces/enrollments/enrollment";
import { EnrollmentUnitCourseRecord } from "./enrollment-unit-course-record";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import { EventRecord } from "models/view-models/events/event-record";
import { ProductRecord } from "../products/product-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UnitRecord } from "models/view-models/units/unit-record";
import { UserRecord } from "../users/user-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import { CollectionUtils } from "utilities/collection-utils";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";
import { EnrollmentStatus } from "models/enumerations/enrollments/enrollment-status";
import { EnrollmentExpirationChangeLogRecord } from "./enrollment-expiration-change-log-record";
import { RegistrationRecord } from "../registrations/registration-record";
import { EnrollmentProgressStatus } from "models/enumerations/enrollments/enrollment-progress-status";
import { DateUtils } from "utilities/date-utils";
import { CertificateRecord } from "../certificates/certificate-record";
import { BadgeRecord } from "../badges/badge-record";
import { ProductVersionRecord } from "../products/product-version-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Enrollment = {
    attendances: undefined,
    completedOn: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    badge: undefined,
    certificate: undefined,
    contractId: undefined,
    evaluation: undefined,
    enrollmentUnitCourses: undefined,
    enrollmentScormPackageAssessments: undefined,
    event: undefined,
    eventId: undefined,
    expirationDate: undefined,
    expirationChangeLogs: undefined,
    id: undefined,
    linkTrialExpiresOn: undefined,
    percentComplete: undefined,
    product: undefined,
    productVersion: undefined,
    productId: 0,
    registrations: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    user: undefined,
    userId: 0,
    withdrawnBy: undefined,
    withdrawnById: undefined,
    withdrawnOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EnrollmentRecord extends Record(defaultValues) implements Enrollment {
    // ---------------------------------------------------------------------------------------------
    // #region Constructor
    // ---------------------------------------------------------------------------------------------

    constructor(params?: Partial<Enrollment>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.attendances)) {
            params.attendances = RecordUtils.ensureRecords(
                params.attendances,
                EventDayAttendanceRecord
            );
        }

        if (CollectionUtils.hasValues(params.enrollmentUnitCourses)) {
            params.enrollmentUnitCourses = RecordUtils.ensureRecords(
                params.enrollmentUnitCourses,
                EnrollmentUnitCourseRecord
            );
        }

        if (CollectionUtils.hasValues(params.enrollmentScormPackageAssessments)) {
            params.enrollmentScormPackageAssessments = RecordUtils.ensureRecords(
                params.enrollmentScormPackageAssessments,
                EnrollmentScormPackageAssessmentRecord
            );
        }

        if (CollectionUtils.hasValues(params.expirationChangeLogs)) {
            params.expirationChangeLogs = RecordUtils.ensureRecords(
                params.expirationChangeLogs,
                EnrollmentExpirationChangeLogRecord
            );
        }

        if (CollectionUtils.hasValues(params.registrations)) {
            params.registrations = RecordUtils.ensureRecords(
                params.registrations,
                RegistrationRecord
            );
        }

        if (params.badge != null) {
            params.badge = RecordUtils.ensureRecord(params.badge, BadgeRecord);
        }

        if (params.productVersion != null) {
            params.productVersion = RecordUtils.ensureRecord(
                params.productVersion,
                ProductVersionRecord
            );
        }

        if (params.certificate != null) {
            params.certificate = RecordUtils.ensureRecord(params.certificate, CertificateRecord);
        }

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (params.evaluation != null) {
            params.evaluation = RecordUtils.ensureRecord(params.evaluation, EvaluationRecord);
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        if (params.withdrawnBy != null) {
            params.withdrawnBy = RecordUtils.ensureRecord(params.withdrawnBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    public get scormPackageAssessments(): ScormPackageAssessmentRecord[] {
        if (
            this.enrollmentScormPackageAssessments == null ||
            (this.product?.type !== TrainingType.InstructorLedTraining &&
                this.product?.type !== TrainingType.InstructorAssessment)
        ) {
            return [];
        }

        return this.enrollmentScormPackageAssessments.reduce(
            (
                scormPackages: ScormPackageAssessmentRecord[],
                enrollmentScormPackageAssessment: EnrollmentScormPackageAssessmentRecord
            ) => {
                const { scormPackageAssessment } = enrollmentScormPackageAssessment;

                if (scormPackageAssessment == null) {
                    return scormPackages;
                }

                const foundScormPackageIndex = scormPackages.findIndex(
                    (sp) => sp.id === scormPackageAssessment?.id
                );

                const scormPackageAssessmentWithEnrollmentInfo = scormPackageAssessment.with({
                    enrollmentScormPackageAssessment,
                });

                if (foundScormPackageIndex < 0) {
                    return [...scormPackages, scormPackageAssessmentWithEnrollmentInfo];
                }

                return CollectionUtils.replaceElementAt(
                    scormPackages,
                    foundScormPackageIndex,
                    scormPackageAssessmentWithEnrollmentInfo
                );
            },
            []
        );
    }

    /**
     * Returns an array of unit records based on EnrollmentUnitCourse records
     */
    public get units(): UnitRecord[] {
        if (
            this.enrollmentUnitCourses == null ||
            this.product?.type === TrainingType.InstructorLedTraining
        ) {
            return [];
        }

        return this.enrollmentUnitCourses
            .reduce((units: UnitRecord[], enrollmentUnitCourse: EnrollmentUnitCourseRecord) => {
                const { unitCourse } = enrollmentUnitCourse;

                if (unitCourse == null) {
                    return units;
                }

                const { course } = unitCourse;
                const foundUnitIndex = units.findIndex((unit) => unit.id === unitCourse?.unitId);

                const unit = (
                    foundUnitIndex < 0 ? unitCourse.unit : units[foundUnitIndex]
                )?.withUnitCourse(unitCourse.with({ course, enrollmentUnitCourse }));

                if (unit == null) {
                    return units;
                }

                if (foundUnitIndex < 0) {
                    return [...units, unit];
                }

                return CollectionUtils.replaceElementAt(units, foundUnitIndex, unit).sort(
                    CollectionUtils.sortBySortOrder
                );
            }, [])
            .sort(CollectionUtils.sortBySortOrder);
    }

    public getAttendanceSummary(): string {
        if (CollectionUtils.isEmpty(this.event?.eventDays)) {
            return "--";
        }

        const daysCount: number = this.event?.eventDays?.length ?? 0;
        const attendedCount: number =
            this.attendances?.filter(
                (attendance: EventDayAttendanceRecord): boolean => attendance.attended
            ).length ?? 0;

        return `${attendedCount} / ${daysCount}`;
    }

    /**
     * Returns whether all required items have been completed for an enrollment.
     */
    public isComplete(): boolean {
        return this.completedOn != null;
    }

    /**
     * Returns a calculated status for an enrollment.
     */
    public getStatus(): EnrollmentStatus {
        if (this.isComplete()) {
            return EnrollmentStatus.Complete;
        }

        if (this.withdrawnOn != null) {
            return EnrollmentStatus.Withdrawn;
        }

        if (this.eventId != null && this.eventId > 0 && this.event?.isCanceled()) {
            return EnrollmentStatus.Canceled;
        }

        if (this.expirationDate != null) {
            return EnrollmentStatus.Expired;
        }

        return EnrollmentStatus.Expired;
    }

    public isExpired(): boolean {
        return (
            this.expirationDate != null &&
            DateUtils.convertToMilliseconds(this.expirationDate.toString(), true) <
                DateUtils.convertToMilliseconds(new Date().toString(), true)
        );
    }

    public hasTrainingAccess(): boolean {
        const status = this.getStatus();
        return (
            status !== EnrollmentStatus.Canceled &&
            status !== EnrollmentStatus.Withdrawn &&
            !this.isExpired()
        );
    }

    /**
     * Returns a calculated progress status for an enrollment.
     */
    public getProgressStatus(): EnrollmentProgressStatus {
        if (this.isComplete()) {
            return EnrollmentProgressStatus.Complete;
        }

        if (this.isExpired()) {
            return EnrollmentProgressStatus.Incomplete;
        }

        if (this.registrations == null || this.registrations.length === 0) {
            return EnrollmentProgressStatus.NotStarted;
        }

        return EnrollmentProgressStatus.InProgress;
    }

    /**
     * Given a set of values for Enrollment properties, create a new EnrollmentRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Enrollment>} values The values to overwrite on this instance.
     * @returns A EnrollmentRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Enrollment>): EnrollmentRecord {
        return new EnrollmentRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentRecord };

// #endregion Exports
