import { ServiceHookFactory } from "utilities/services/service-hook-factory";
import { CatalogEnrollmentLogRecord } from "models/view-models/enrollments/catalog-enrollment-log-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "catalogEnrollmentLogs";
const resourceType: typeof CatalogEnrollmentLogRecord = CatalogEnrollmentLogRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListCatalogEnrollmentLogsQueryParams {
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CatalogEnrollmentLogService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        CatalogEnrollmentLogRecord,
        ListCatalogEnrollmentLogsQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CatalogEnrollmentLogService };

// #endregion Exports
