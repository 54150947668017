import React from "react";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardStyle,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useLocation } from "react-router-dom";
import { RoleType } from "models/enumerations/users/role-type";
import { useReportingContext } from "utilities/contexts/providers/reporting-context-provider";
import { t } from "utilities/localization/t";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import "./report-selection.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportSelectionProps {
    adminOnly: JSX.Element;
    roleType?: RoleType | null;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-selection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportSelection: React.FC<React.PropsWithChildren<ReportSelectionProps>> = (
    props: React.PropsWithChildren<ReportSelectionProps>
): JSX.Element => {
    const { adminOnly, roleType } = props;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { reports } = useReportingContext();

    const selectedReportKey = pathname.includes("/admin/reports/run-a-report/setup/")
        ? pathname.split("/").pop()
        : undefined;

    const reportRadioCards = reports.map(
        (report): RadioCard => ({
            icon: Icons.Reporting,
            text: "reportNameReport",
            translationData: { reportName: report.name },
            value: report.key,
        })
    );

    const handleReportTypeChange = (reportKey: string) => {
        const pathParams = { slug: reportKey };
        const path = RouteUtils.replacePathParams(sitemap.admin.reports.setup, pathParams);

        navigate(path);
    };

    const showAdminOnlySection =
        roleType === RoleType.NfpaAdministrator ||
        roleType === RoleType.NfpaOperator ||
        roleType === RoleType.NfpaSupport;

    if (showAdminOnlySection) {
        return (
            <>
                <h2>{props.title}</h2>
                {adminOnly}
            </>
        );
    }

    return (
        <div className={CSS_CLASS_NAME}>
            <h2>{t("runAReport")}</h2>
            <Paragraph size={ParagraphSize.Large}>{t("iWantToRunAn")}</Paragraph>
            <FormRadioCardList
                id="reportType"
                formFieldName="reportType"
                label={t("reportType")}
                labelScreenReaderOnly={true}
                iconSize={IconSizes.Base}
                onChange={handleReportTypeChange}
                radioCards={reportRadioCards}
                required={true}
                style={RadioCardStyle.Horizontal}
                value={selectedReportKey}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportSelection };

// #endregion Exports
