import React, { useMemo } from "react";
import { LearnerAssessmentList } from "components/assessments/learner-assessment-list/learner-assessment-list";
import { NumberUtils } from "utilities/number-utils";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import { TrainingType } from "models/enumerations/courses/training-type";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { useEvent } from "utilities/hooks/models/events/use-event";
import { Card, CardStyle } from "components/card/card";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import "./learner-take-a-training-assessments-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingAssessmentsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-take-a-training-event-assessments-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingAssessmentsPage: React.FC<LearnerTakeATrainingAssessmentsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerTakeATrainingAssessmentsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { id: eventIdParam, enrollmentId: enrollmentIdParam } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
        const enrollmentId: number = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeEvent: true,
            includeProduct: true,
            includeTrainingType: TrainingType.InstructorLedTraining,
        });

        const { scormPackageAssessments } = enrollment;

        const { event } = useEvent({
            eventId: enrollment.event?.id ?? 0,
            includeEventSessions: true,
        });

        const lastEventDate = useMemo(() => {
            const endDate = event?.endDate() ?? event?.eventDays?.slice(-1)[0]?.eventDate();
            return endDate == null ? undefined : new Date(endDate);
        }, [event]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Assessment} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("assessments")}
                    </Heading>
                </div>
                {enrollmentId > 0 && eventId > 0 && (
                    <>
                        {event?.lastDayHasStarted() ? (
                            <LearnerAssessmentList
                                enrollmentId={enrollmentId}
                                eventId={eventId}
                                productId={enrollment.productId}
                                scormPackageAssessments={scormPackageAssessments}
                            />
                        ) : (
                            <Card style={CardStyle.Empty}>
                                <Icon type={Icons.Lock} size={IconSizes.Large} />
                                <EmptyText spacing={EmptyTextSpacing.None}>
                                    {t("assessmentsWillBeAvailableToCompleteOnLastEventDate", {
                                        lastEventDate:
                                            lastEventDate == null
                                                ? t("theLastDayOfTheTraining").toLocaleLowerCase()
                                                : DateUtils.formatDateCustom(
                                                      lastEventDate.toString(),
                                                      t("shortDateFormat")
                                                  ),
                                    })}
                                </EmptyText>
                            </Card>
                        )}
                    </>
                )}
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingAssessmentsPage };

// #endregion Exports
