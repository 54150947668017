import React, { useMemo } from "react";
import { AboutThisTraining } from "components/training-summary/about-this-training/about-this-training";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { LearnerAssessmentList } from "components/assessments/learner-assessment-list/learner-assessment-list";
import { NumberUtils } from "utilities/number-utils";
import { SupportCard } from "components/support/support-card/support-card";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./learner-instructor-assessment-details-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerInstructorAssessmentDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-instructor-assessment-details-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerInstructorAssessmentDetailsPage: React.FC<LearnerInstructorAssessmentDetailsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningDetailsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);

        const { enrollmentId: enrollmentIdParam } = useParams();

        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeProduct: true,
            includeTrainingType: TrainingType.InstructorAssessment,
        });

        const { scormPackageAssessments } = enrollment;

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.EventDetails} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("aboutThisTraining")}
                    </Heading>
                </div>
                <AboutThisTraining
                    description={enrollment.product?.description ?? ""}
                    name={enrollment.product?.name ?? ""}
                    topics={enrollment.product?.topic ? [enrollment.product.topic] : []}
                />
                <div className={`${CSS_CLASS_NAME}__hr`}></div>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                        {t("assessments")}
                    </Heading>
                </div>

                <div className={`${CSS_CLASS_NAME}__syllabus`}>
                    <LearnerAssessmentList
                        enrollmentId={enrollmentId}
                        eventId={-1}
                        productId={enrollment.productId}
                        scormPackageAssessments={scormPackageAssessments}
                        showViewResults={false}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__hr`}></div>
                <div className={`${CSS_CLASS_NAME}__contact`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                        {t("contact")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__contact__cards`}>
                        <SupportCard />
                    </div>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerInstructorAssessmentDetailsPage };

// #endregion Exports
