import React, { useMemo, useState } from "react";
import { AddBulkEnrollmentModal } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-modal";
import { AddEnrollmentModal } from "components/enrollments/add-enrollment-modal/add-enrollment-modal";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { EnrollmentSummaryList } from "../enrollment-list/enrollment-summary-list";
import {
    EventCertificateService,
    EventCertificateDownloadPathParams,
} from "utilities/services/certificates/event-certificate-service";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Pager } from "components/pager/pager";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useILTContractEnrollments } from "utilities/hooks/models/enrollments/use-ilt-contract-enrollments";
import { EnrollmentReportDownloadButton } from "components/reports/components/report-download-button/enrollment-report-download-button";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { EventEnrollmentSummaryRecord } from "models/view-models/enrollments/event-enrollment-summary-record";
import { CollectionUtils } from "utilities/collection-utils";
import "./contract-event-enrollment-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ContractEventEnrollmentManagerProps {
    contractId: number;
    eventId: number;
    productId: number;
    eventProductVersionId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "contract-event-enrollment-manager";
const ITEMS_PER_PAGE = 6;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContractEventEnrollmentManager: React.FC<ContractEventEnrollmentManagerProps> = ({
    contractId,
    eventId,
    productId,
    eventProductVersionId,
}): JSX.Element => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showAddEnrollmentModal, setShowAddEnrollmentModal] = useState(false);
    const [showBulkEnrollmentModal, setShowBulkEnrollmentModal] = useState(false);
    const { contract } = useContract({ contractId, includeEvent: true });
    const { record: globalState } = useGlobalState();

    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );

    const isClientAdmin = userRole === RoleType.ClientAdmin;

    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            userRole === RoleType.ClientAdmin,
        [userRole]
    );

    const canDownloadCertificates = useHasAccess(AccessControlKeys.CanDownloadCertificates);
    const canDownloadEnrollmentReport = useHasAccess(AccessControlKeys.CanDownloadEnrollmentReport);

    const {
        enrollmentSummaries,
        rowCount: totalNumberOfEnrollments,
        createEnrollment,
        createBulkEnrollments,
        withdrawEnrollment,
        usedEnrollmentCount,
    } = useILTContractEnrollments({
        contractId: contractId,
        eventId: eventId,
        productId: productId,
        eventProductVersionId: eventProductVersionId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const disableCertificateDownloads: boolean = useMemo(() => {
        if (CollectionUtils.isEmpty(enrollmentSummaries)) {
            return true;
        }
        return !enrollmentSummaries.some(
            (enrollmentSummary: EventEnrollmentSummaryRecord): boolean =>
                enrollmentSummary.enrollment?.evaluation?.id != null &&
                enrollmentSummary.enrollment?.evaluation?.id > 0
        );
    }, [enrollmentSummaries]);

    const enrollmentSeatsAvailable: boolean = useMemo(() => {
        return contract.noEnrollmentLimit
            ? true
            : usedEnrollmentCount < (contract.enrollmentLimit ?? 0);
    }, [contract.enrollmentLimit, contract.noEnrollmentLimit, usedEnrollmentCount]);

    const enrollmentCountText = useMemo(() => {
        const numerator = usedEnrollmentCount;
        const denominator =
            !contract.noEnrollmentLimit && contract.enrollmentLimit != null
                ? contract.enrollmentLimit
                : undefined;

        return `${numerator}${denominator ? `/${denominator}` : ""}`;
    }, [contract.enrollmentLimit, contract.noEnrollmentLimit, usedEnrollmentCount]);

    const handleDownloadCertificateClick = async (id: number) => {
        const pathParams: EventCertificateDownloadPathParams = {
            id: eventId,
        };

        await EventCertificateService.downloadEventCertificates(
            pathParams,
            `event-${eventId}-certificates.zip`
        );
    };

    const eventIsOver = () => {
        const lastDayOfEvent = contract.event?.eventDays
            ?.sort(
                (a: EventDayRecord, b: EventDayRecord) =>
                    b.eventDate().valueOf() - a.eventDate().valueOf()
            )[0]
            .eventDate();
        return lastDayOfEvent ? new Date() > new Date(lastDayOfEvent) : true;
    };

    const disableForClientAdmin = eventIsOver() && isClientAdmin;

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header__title`}>
                    <Icon type={Icons.Enrollment} size={IconSizes.Large} />
                    <Heading size={HeadingSize.Small}>{t("enrollment")}</Heading>
                    <Badge style={BadgeStyle.Success}>
                        {t("totalEnrollmentCountText", {
                            enrollmentCountText: enrollmentCountText,
                        })}
                    </Badge>
                </div>
                <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                    {canDownloadCertificates && (
                        <ButtonIcon
                            disabled={disableCertificateDownloads}
                            iconType={Icons.Download}
                            text={t("downloadCertificates")}
                            onClick={() => handleDownloadCertificateClick(eventId)}
                        />
                    )}

                    {canDownloadEnrollmentReport && (
                        <EnrollmentReportDownloadButton eventId={eventId} />
                    )}

                    {
                        // if
                        canModifyEnrollments && (
                            <>
                                <ButtonIcon
                                    disabled={!enrollmentSeatsAvailable || disableForClientAdmin}
                                    iconType={Icons.Plus}
                                    onClick={() => setShowAddEnrollmentModal(true)}
                                    text={t("addNew")}
                                />
                                <ButtonIcon
                                    disabled={!enrollmentSeatsAvailable || disableForClientAdmin}
                                    iconType={Icons.Upload}
                                    onClick={() => setShowBulkEnrollmentModal(true)}
                                    text={t("importCSV")}
                                />
                            </>
                        )
                    }
                </div>
            </div>

            <Card cssClassName={`${CSS_CLASS_NAME}__enrollment-list`}>
                <AddEnrollmentModal
                    handleSaveEnrollment={createEnrollment}
                    open={showAddEnrollmentModal}
                    setOpen={setShowAddEnrollmentModal}
                />
                <AddBulkEnrollmentModal
                    handleSaveBulkEnrollment={createBulkEnrollments}
                    open={showBulkEnrollmentModal}
                    setOpen={setShowBulkEnrollmentModal}
                />
                <EnrollmentSummaryList
                    disableForClientAdmin={disableForClientAdmin}
                    enrollmentSummaries={enrollmentSummaries}
                    handleWithdrawEnrollment={withdrawEnrollment}
                />
                <Pager
                    currentPage={currentPage}
                    totalPages={totalNumberOfEnrollments / ITEMS_PER_PAGE}
                    onPageClick={onPageClick}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={totalNumberOfEnrollments}
                />
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractEventEnrollmentManager };

// #endregion Exports
