import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditEventNameModal } from "components/events/edit-event-name-modal/edit-event-name-modal";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { ButtonIcon, ButtonIconProps } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { DateUtils } from "utilities/date-utils";
import { EditEventLayoutFooter } from "layouts/admin/edit-event-layout/edit-event-layout-footer/edit-event-layout-footer";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    EnProviderEditEventProcessSteps,
    useEnProviderEditEventProcess,
} from "utilities/processes/events/use-en-provider-edit-event-process";
import { EventActivationModal } from "components/events/event-activation-modal/event-activation-modal";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { EventChangeSummaryModal } from "components/events/event-change-summary-modal/event-change-summary-modal";
import { EventService } from "utilities/services/events/event-service";
import { EventCancellationModal } from "components/events/event-cancellation-modal/event-cancellation-modal";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { Modal, ModalAction } from "components/modal/modal";
import {
    NfpaRoleEditEventProcessSteps,
    useNfpaRoleEditEventProcess,
} from "utilities/processes/events/use-nfpa-role-edit-event-process";
import { NumberUtils } from "utilities/number-utils";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { Outlet, useParams } from "react-router-dom";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProcessSidebar } from "components/process-sidebar/process-sidebar";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import {
    PublishStatus,
    PublishStatusDisplayNames,
    PublishStatusMap,
} from "models/enumerations/publish-status/publish-status";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import {
    EventActiveRecordContext,
    useEventActiveRecord,
} from "utilities/hooks/active-record/events/use-event-active-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EventTypeUserDisplayNames } from "models/enumerations/events/event-type";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { RoleType } from "models/enumerations/users/role-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./edit-event-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditEventLayoutProps {
    sidebar?: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-event-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditEventLayout: React.FC<EditEventLayoutProps> = (): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.event.list);
    const { id } = useParams();
    const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const event = useEventActiveRecord(eventId);
    const navigate = useNavigate();
    const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
    const [showCancellationModal, setShowCancellationModal] = useState<boolean>(false);
    const [showChangeSummaryModal, setShowChangeSummaryModal] = useState<boolean>(false);
    const [openEditEventModal, setOpenEditEventModal] = useState(false);
    const [showCancelEditModeModal, setShowCancelEditModeModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const { delete: deleteApi } = EventService.useDelete();
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const isAEN = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);
    const isInactiveAEN = isAEN && !event.provider?.isActive;
    const navigationRequests = useListenForNavigationRequests(EditEventLayout.name, [
        Breadcrumbs.name,
    ]);
    const resolveNavigationRequest = useResolveNavigationRequest();

    const nfpaRoleEditEventProcess = useNfpaRoleEditEventProcess(eventId, event);
    const enProviderEditEventProcess = useEnProviderEditEventProcess(eventId, event);
    const editEventProcess = currentUserIsInNfpaRole
        ? nfpaRoleEditEventProcess
        : enProviderEditEventProcess;
    const editEventProcessNavigator = useProcessNavigator<
        NfpaRoleEditEventProcessSteps | EnProviderEditEventProcessSteps
    >(editEventProcess);
    const numberOfTabs: [string, ProcessStep][] = Object.entries(editEventProcess);

    const calculatePercentageComplete = (): number => {
        let completeCount = 0;
        numberOfTabs.forEach(([_, step]: [string, ProcessStep]) => {
            if (step.isComplete()) {
                completeCount++;
            }
        });

        return Math.floor((completeCount / numberOfTabs.length) * 100);
    };

    const isDeletable = useMemo(
        () => event.status === PublishStatus.Draft || event.status === PublishStatus.Canceled,
        [event.status]
    );

    const onEditClick = (id: number) => {
        setOpenEditEventModal(true);
    };

    const onHistoryClick = useCallback(() => {
        const path = RouteUtils.replacePathParams(sitemap.admin.event.changeLog, {
            id: eventId,
        });

        navigate(path);
    }, [eventId, navigate]);

    const onDeleteClick = (id: number) => {
        setShowDeleteConfirmationModal(true);
    };

    const handleDelete = async () => {
        if (event.id == null) {
            ToastManager.error(t("thereWasAnIssueDeletingTheEvent"));
            return;
        }

        try {
            await deleteApi(event.id);
            ToastManager.success(t("eventDeletedSuccessfully"));
            navigate(sitemap.admin.event.list);
        } catch {
            ToastManager.error(t("thereWasAnIssueDeletingTheEvent"));
            return false;
        }
    };

    const handleActivationModalClose = useCallback((): void => setShowActivationModal(false), []);
    const confirmCancelEditMode = () => {
        if (Object.values(event.eventChangeLogTypeChanged).includes(true)) {
            setShowCancelEditModeModal(true);
        } else {
            handleCancelEditEvent();
        }
    };
    const handleCancelEditEvent = useCallback((): void => {
        event.discardChanges();
        setEditMode(false);
        setShowCancelEditModeModal(false);
    }, [event]);
    const handleCancellationModalClose = useCallback(
        (): void => setShowCancellationModal(false),
        []
    );
    const handleEditEventModalClose = useCallback((): void => setOpenEditEventModal(false), []);
    const handleSaveChangesClick = useCallback((): void => {
        event.save();
        setShowChangeSummaryModal(false);
        setEditMode(false);
    }, [event]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(EditEventLayout.name, navigationRequest.requestingComponent);
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(EditEventLayout.name, navigationRequest.requestingComponent);
    }, [navigationRequest, resolveNavigationRequest]);

    const statusBadge = useMemo(
        () => (
            <EnumStatusBadge
                displayNames={PublishStatusDisplayNames}
                statusMap={PublishStatusMap}
                value={event.status!}
            />
        ),
        [event.status]
    );

    const deleteConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowDeleteConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: async () => {
                await handleDelete();
                setShowDeleteConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const cancelEditModeConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: () => setShowCancelEditModeModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleCancelEditEvent,
            style: ButtonStyle.Destructive,
        },
    ];
    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    const [readOnly, setReadOnly] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        // if in edit mode,
        setReadOnly(
            editMode
                ? isInactiveAEN
                    ? true
                    : false
                : event.status === PublishStatus.Canceled || event.status === PublishStatus.Live
        );
    }, [event.status, editMode, isInactiveAEN]);

    const showFixedBanner = useMemo(
        () => event.status === PublishStatus.Canceled || event.status === PublishStatus.Live,
        [event.status]
    );

    const headerActionIcons: ButtonIconProps[] = useMemo(() => {
        const headerActionIcons: ButtonIconProps[] = [];

        headerActionIcons.push({
            ariaLabelledBy: t("editEvent"),
            disabled: readOnly,
            onClick: () => onEditClick(eventId),
            iconType: Icons.EditOpen,
        });

        headerActionIcons.push({
            ariaLabelledBy: t("deleteEvent"),
            disabled: !isDeletable,
            onClick: () => onDeleteClick(eventId),
            iconType: Icons.Trashcan,
        });

        headerActionIcons.push({
            ariaLabelledBy: t("viewTheChangeHistory"),
            disabled: event.status === PublishStatus.Draft,
            onClick: () => onHistoryClick(),
            iconType: Icons.ClockWithArrow,
        });
        return headerActionIcons;
    }, [event.status, eventId, isDeletable, onHistoryClick, readOnly]);

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (!editMode || !event.isDirty) {
            resolveNavigationRequest(EditEventLayout.name, navigationRequest.requestingComponent);
            navigationRequest.onNavigationApproved();
            return;
        }

        setNavigationRequest(navigationRequest);
    }, [editMode, event.isDirty, navigationRequests, resolveNavigationRequest]);

    const badges: TranslatedCopy[] = [];

    // Badges
    if (event?.instructorLedTrainingType != null) {
        badges.push(InstructorLedTrainingTypeDisplayNames[event.instructorLedTrainingType]);
    }

    if (currentUserIsInNfpaRole && event.type != null) {
        badges.push(EventTypeUserDisplayNames[event.type!]);
    }

    return (
        <EventActiveRecordContext.Provider value={event}>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__window`}>
                    <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                        <ProcessSidebar
                            badges={badges}
                            headerActionIcons={headerActionIcons}
                            completionPercentage={calculatePercentageComplete()}
                            title={event.product?.name ?? t("noProductAssigned")}
                            subTitle={event.name}
                            titleEmphasis={
                                event.productId == null ? ParagraphEmphasis.Italic : undefined
                            }
                            isActive={event.status === PublishStatus.Live}
                            statusBadge={statusBadge}
                            objectId={1}
                            process={editEventProcess}
                            processNavigator={editEventProcessNavigator}
                            showProgressChart={true}
                        />
                    </div>
                    <ReadOnlyContext.Provider value={{ readOnly, setReadOnly: () => setReadOnly }}>
                        <div
                            className={`${CSS_CLASS_NAME}__window__main ${
                                showFixedBanner ? "-has-fixed-banner" : ""
                            }`}>
                            {event.status === PublishStatus.Live &&
                                editMode === false &&
                                !isInactiveAEN && (
                                    <Banner style={BannerStyle.Success} format={BannerFormat.Fixed}>
                                        <div className="content-wrap">
                                            <div className="content">
                                                <div className="text">
                                                    <Paragraph
                                                        style={ParagraphStyle.Default}
                                                        size={ParagraphSize.Small}>
                                                        <strong>
                                                            {t("thisEventIsCurrentlyLive")}
                                                        </strong>
                                                    </Paragraph>
                                                </div>
                                                <div className="view">
                                                    <button
                                                        // disabled={isInactiveAEN}
                                                        type="button"
                                                        onClick={() => setEditMode(true)}>
                                                        <span>{t("editEvent")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Banner>
                                )}
                            {editMode === true && readOnly === false && (
                                <Banner
                                    style={BannerStyle.SuccessInverted}
                                    format={BannerFormat.Fixed}>
                                    <div className="content-wrap">
                                        <div className="content">
                                            <div className="text">
                                                <Paragraph
                                                    style={ParagraphStyle.Default}
                                                    size={ParagraphSize.Small}>
                                                    <strong>
                                                        {t("youAreNowEditingALiveEvent")}
                                                    </strong>
                                                </Paragraph>
                                            </div>
                                            <div className="view">
                                                <button
                                                    type="button"
                                                    onClick={confirmCancelEditMode}>
                                                    <span>{t("cancel")}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Banner>
                            )}
                            {event.status === PublishStatus.Canceled && (
                                <Banner style={BannerStyle.Error} format={BannerFormat.Fixed}>
                                    <div className="content-wrap">
                                        <Paragraph
                                            style={ParagraphStyle.Default}
                                            size={ParagraphSize.Small}>
                                            <strong>
                                                {t(
                                                    "thisEventWasCanceledCanceledOnByCanceledByNameCanceledById",
                                                    {
                                                        canceledOn: DateUtils.formatDateTime(
                                                            true,
                                                            event?.canceledOn?.toString(),
                                                            true
                                                        ),
                                                        canceledByName:
                                                            event.canceledBy?.getFirstAndLastName(),
                                                        canceledById: currentUserIsInNfpaRole
                                                            ? "#" + event.canceledById
                                                            : "",
                                                    }
                                                )}
                                            </strong>
                                        </Paragraph>
                                    </div>
                                </Banner>
                            )}
                            <SkipNavContent>
                                <div className={`${CSS_CLASS_NAME}__body`}>
                                    <div className={`${CSS_CLASS_NAME}__content`}>
                                        <Outlet />
                                    </div>
                                </div>
                                <EditEventNameModal
                                    event={event}
                                    onModalClose={handleEditEventModalClose}
                                    open={openEditEventModal}
                                />
                            </SkipNavContent>
                        </div>
                    </ReadOnlyContext.Provider>
                </div>
                {!isInactiveAEN && (
                    <EditEventLayoutFooter
                        cssClassName={`${CSS_CLASS_NAME}__footer`}
                        editPermitted={!readOnly}
                        processNavigator={editEventProcessNavigator}>
                        {!editMode && event.status === PublishStatus.Live && (
                            <ButtonIcon
                                ariaLabelledBy={t("cancelEvent")}
                                buttonStyle={ButtonStyle.Transparent}
                                iconType={Icons.StopScreenShare}
                                onClick={() => setShowCancellationModal(true)}
                                text={t("cancelEvent")}
                            />
                        )}
                        {!editMode &&
                            event.status !== PublishStatus.Canceled &&
                            event.status !== PublishStatus.Live && (
                                <ButtonIcon
                                    ariaLabelledBy={t("publishEvent")}
                                    buttonStyle={ButtonStyle.Inverted}
                                    disabled={calculatePercentageComplete() === 100 ? false : true}
                                    iconType={Icons.ScreenShare}
                                    onClick={() => setShowActivationModal(true)}
                                    text={t("publishEvent")}
                                />
                            )}
                        {editMode && (
                            <div className={`${CSS_CLASS_NAME}__footer-edit-buttons`}>
                                <ButtonIcon
                                    ariaLabelledBy={t("saveChanges")}
                                    buttonStyle={ButtonStyle.Inverted}
                                    cssClassName={`${CSS_CLASS_NAME}__footer-edit-buttons-save`}
                                    disabled={calculatePercentageComplete() === 100 ? false : true}
                                    iconType={Icons.PublishWithChanges}
                                    onClick={() => setShowChangeSummaryModal(true)}
                                    text={t("saveChanges")}
                                />
                            </div>
                        )}
                    </EditEventLayoutFooter>
                )}
            </div>
            <EventCancellationModal
                event={event}
                onModalClose={handleCancellationModalClose}
                open={showCancellationModal}
            />
            <EventActivationModal
                event={event}
                onModalClose={handleActivationModalClose}
                open={showActivationModal}
            />
            <Modal
                isOpen={showDeleteConfirmationModal}
                onModalClose={() => {}}
                actions={deleteConfirmationActions}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWantToDeleteThisEvent")}
            </Modal>
            <Modal
                cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                isOpen={showCancelEditModeModal}
                onModalClose={() => {}}
                actions={cancelEditModeConfirmationActions}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
            </Modal>
            <Modal
                cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                isOpen={showCancelEditOnNavigationModal}
                onModalClose={() => {}}
                actions={cancelEditModeOnNavigationConfirmationActions}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
            </Modal>
            <EventChangeSummaryModal
                editMode={editMode}
                event={event}
                open={showChangeSummaryModal}
                setOpen={setShowChangeSummaryModal}
                handleSaveChangesClick={handleSaveChangesClick}
            />
        </EventActiveRecordContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditEventLayout };

// #endregion Exports
