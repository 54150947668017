import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./product-activation-content-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationContentSectionProps {
    product: ProductRecord;
    productContents?: ProductContentRecord[];
    productVersion: ProductVersionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-content-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationContentSection: React.FC<ProductActivationContentSectionProps> = ({
    product,
    productContents,
    productVersion,
}): JSX.Element => {
    const isInstructorLed = product.type === TrainingType.InstructorLedTraining;

    return (
        <div className={`${CSS_CLASS_NAME}__section`}>
            {!isInstructorLed && (
                <>
                    <div className={`${CSS_CLASS_NAME}__contents__header`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("content")}
                        </Heading>
                        <ToggleLabel
                            checked={productVersion.hasNoContent}
                            disabled={true}
                            id="has-no-content"
                            label={t("thisProductHasNoContent")}
                        />
                    </div>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="order" aria-labelledby={t("order")}>
                                    {t("order")}
                                </th>
                                <th className="name" aria-labelledby={t("name")}>
                                    {t("name")}
                                </th>
                                <th className="description" aria-labelledby={t("description")}>
                                    {t("description")}
                                </th>
                                <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                    {t("fileName")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {productContents &&
                                productContents.map((productContent) => (
                                    <tr key={productContent.id}>
                                        <td className="order">{productContent.sortOrder}</td>
                                        <td className="name">{productContent.content?.name}</td>
                                        <td className="description">
                                            <Paragraph>
                                                {productContent.content?.description}
                                            </Paragraph>
                                        </td>
                                        <td className="file-name">
                                            {productContent.content?.file?.fileName}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                </>
            )}
            {isInstructorLed && (
                <>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("content")}
                    </Heading>
                    <Heading priority={HeadingPriority.H3}>
                        {t("contentForAdministatorAndInstructor")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__table-wrapper`}>
                        <DataTable cssClassName={`${CSS_CLASS_NAME}__instructor-provider`}>
                            <thead>
                                <tr>
                                    <th className="order" aria-labelledby={t("sortOrderColumn")}>
                                        {t("order")}
                                    </th>
                                    <th className="name" aria-labelledby={t("nameColumn")}>
                                        {t("name")}
                                    </th>
                                    <th
                                        className="description"
                                        aria-labelledby={t("descriptionColumn")}>
                                        {t("description")}
                                    </th>
                                    <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                        {t("fileName")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productContents &&
                                    productContents.map(
                                        (pc) =>
                                            pc.access ===
                                                ProductContentAccess.InstructorProvider && (
                                                <tr key={pc.id}>
                                                    <td className="order">{pc.sortOrder}</td>
                                                    <td className="name">{pc.content?.name}</td>
                                                    <td className="description">
                                                        <Paragraph>
                                                            {pc.content?.description}
                                                        </Paragraph>
                                                    </td>
                                                    <td className="file-name">
                                                        {pc.content?.file?.fileName}
                                                    </td>
                                                </tr>
                                            )
                                    )}
                            </tbody>
                        </DataTable>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__second-table`}>
                        <h3 className={`${CSS_CLASS_NAME}__table-heading`}>
                            {t("contentForParticipants")}
                        </h3>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__table-wrapper`}>
                        <DataTable cssClassName={CSS_CLASS_NAME}>
                            <thead>
                                <tr>
                                    <th className="order" aria-labelledby={t("sortOrderColumn")}>
                                        {t("order")}
                                    </th>
                                    <th className="name" aria-labelledby={t("nameColumn")}>
                                        {t("name")}
                                    </th>
                                    <th
                                        className="description"
                                        aria-labelledby={t("descriptionColumn")}>
                                        {t("description")}
                                    </th>
                                    <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                        {t("fileName")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productContents &&
                                    productContents.map(
                                        (pc) =>
                                            pc.access === ProductContentAccess.Everyone && (
                                                <tr key={pc.id}>
                                                    <td className="order">{pc.sortOrder}</td>
                                                    <td className="name">{pc.content?.name}</td>
                                                    <td className="description">
                                                        <Paragraph>
                                                            {pc.content?.description}
                                                        </Paragraph>
                                                    </td>
                                                    <td className="file-name">
                                                        {pc.content?.file?.fileName}
                                                    </td>
                                                </tr>
                                            )
                                    )}
                            </tbody>
                        </DataTable>
                    </div>
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationContentSection };

// #endregion Exports
