import { Record } from "immutable";
import { CatalogEnrollmentLog } from "models/interfaces/enrollments/catalog-enrollment-log";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CatalogEnrollmentLog = {
    erpId: undefined,
    productId: undefined,
    trainingSessionId: undefined,
    orderNumber: undefined,
    netSuiteUserId: undefined,
    userName: undefined,
    externalIdentifier: undefined,
    firstName: undefined,
    lastName: undefined,
    errorType: undefined,
    errorMessage: undefined,
    productName: undefined,
    productStatus: undefined,
    eventName: undefined,
    eventStatus: undefined,

    // audit
    createdById: undefined,
    createdOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CatalogEnrollmentLogRecord extends Record(defaultValues) implements CatalogEnrollmentLog {
    // ---------------------------------------------------------------------------------------------
    // #region Constructor
    // ---------------------------------------------------------------------------------------------

    constructor(params?: Partial<CatalogEnrollmentLog>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Given a set of values for CatalogEnrollmentLog properties, create a new CatalogEnrollmentLogRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<CatalogEnrollmentLog>} values The values to overwrite on this instance.
     * @returns A CatalogEnrollmentLogRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<CatalogEnrollmentLog>): CatalogEnrollmentLogRecord {
        return new CatalogEnrollmentLogRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CatalogEnrollmentLogRecord };

// #endregion Exports
